import React from 'react'
import clsx from 'clsx'
import { Tag, Typography } from '@/components'

interface ContentNavigationProps {
  currentView: string
  onChange: (view: string) => void
}

const ContentNavigation = ({
  currentView,
  onChange,
}: ContentNavigationProps) => {
  return (
    <div className="flex items-center gap-6">
      {/*<span*/}
      {/*  className={clsx(*/}
      {/*    'block relative text-[#77808D] pb-2 after:h-[3px] after:absolute after:w-0 after:bottom-0 left-0 after:transition-all after:bg-blue-80 shrink-0 cursor-pointer',*/}
      {/*    {*/}
      {/*      'text-black-100 after:w-full after:duration-300':*/}
      {/*        currentView === 'ai',*/}
      {/*    },*/}
      {/*  )}*/}
      {/*  onClick={() => {*/}
      {/*    onChange('ai')*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Typography*/}
      {/*    variant="regular"*/}
      {/*    weight="medium"*/}
      {/*    as="span"*/}
      {/*    className="flex items-center gap-1"*/}
      {/*  >*/}
      {/*    <Tag status="info" variant="light">*/}
      {/*      New*/}
      {/*    </Tag>*/}
      {/*    AI Generated*/}
      {/*  </Typography>*/}
      {/*</span>*/}
      <span
        className={clsx(
          'block relative text-[#77808D] pb-2 after:h-[3px] after:absolute after:w-0 after:bottom-0 left-0 after:transition-all after:bg-blue-80 shrink-0 cursor-pointer',
          {
            'text-black-100 after:w-full after:duration-300':
              currentView === 'own',
          },
        )}
        onClick={() => {
          onChange('own')
        }}
      >
        <Typography
          variant="regular"
          weight="medium"
          as="span"
          className="flex items-center gap-1"
        >
          Biuld Your Own
        </Typography>
      </span>
    </div>
  )
}

export default ContentNavigation
