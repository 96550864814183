import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Drawer,
  DrawerSize,
  LoadingSkeleton,
  Option,
  Select,
  Typography,
} from '@/components'
import {
  useGetDealerFileQuery,
  useUpdatePriceColumnMutation,
} from '@/api/services/inventory'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onPriceToggle } from '@/store/slices/pages/inventory.slice'
import Papa from 'papaparse'
import toast from 'react-hot-toast'

const PriceModal = ({ onSuccess }: { onSuccess: () => void }) => {
  const dispatch = useAppDispatch()
  const { showPrice } = useAppSelector(state => state.inventory)
  const [loading, setLoading] = useState(false)
  const [headers, setHeaders] = useState<Option[]>([])

  const [prices, setPrices] = useState<Record<string, string | undefined>>({
    touse_used_price_col: '',
    touse_new_price_col: '',
  })
  const [updatePriceColumn, { isLoading }] = useUpdatePriceColumnMutation()

  useEffect(() => {
    if (showPrice) {
      setPrices({
        touse_used_price_col: showPrice.touse_used_price_col,
        touse_new_price_col: showPrice.touse_new_price_col,
      })
    }
  }, [showPrice])

  const { data: { data: { url: fileUrl = '' } = {} } = {}, isFetching } =
    useGetDealerFileQuery(
      { vendor: showPrice?.vendor, dealer: showPrice?.dealer_id },
      {
        skip: !showPrice?.vendor || !showPrice?.dealer_id,
      },
    )

  const onClose = () => {
    dispatch(onPriceToggle(undefined))
    setHeaders([])
    setPrices({})
  }

  const onSelectChange = (key: string, value?: string) => {
    setPrices(prev => {
      return {
        ...prev,
        [key]: value,
      }
    })
  }

  useEffect(() => {
    const getFileHeaders = async (url: string) => {
      setLoading(true)
      const response = await fetch(url)
      const data = await response.text()
      Papa.parse(data, {
        complete: (result: { data: any[]; meta: any }) => {
          setLoading(false)
          const headerList = result.meta.fields
          const options = headerList.map((header: string) => ({
            _id: header?.toLowerCase(),
            name: header,
          }))
          setHeaders(options)
        },
        header: true,
      })
    }

    if (fileUrl && showPrice) {
      getFileHeaders(fileUrl)
    }
  }, [fileUrl, showPrice])

  const updatePriceColumnHandler = () => {
    if (showPrice) {
      updatePriceColumn({
        touse_used_price_col: prices.touse_used_price_col,
        touse_new_price_col: prices.touse_new_price_col,
        id: showPrice.id,
      })
        .unwrap()
        .then(res => {
          toast.success(res.message)
          onSuccess()
        })
        .catch(() => {
          toast.error('Failed to update price columns')
        })
        .finally(() => {
          onClose()
        })
    }
  }

  return (
    <Drawer
      title="Price Options"
      open={!!showPrice}
      onClose={() => {
        onClose()
      }}
      size={DrawerSize.MD}
    >
      <div className="flex-grow flex flex-col gap-6">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-1">
            <Typography
              variant="small"
              weight="medium"
              className="text-black-50"
            >
              Organization
            </Typography>
            <Typography variant="medium" weight="medium">
              {showPrice?.organization.name}
            </Typography>
          </div>
          {loading || isFetching ? (
            <LoadingSkeleton height={40} borderRadius={12} />
          ) : (
            <>
              <Alert
                type="warning"
                message="Only one column can be selected for each category."
              />
              <Select
                placeholder="Used vehicles price column"
                label="Select Price for used vehicles"
                onChange={val =>
                  onSelectChange('touse_used_price_col', val?._id)
                }
                value={headers.find(
                  el =>
                    el._id?.toLowerCase() ===
                    prices.touse_used_price_col?.toLowerCase(),
                )}
                options={headers}
              />{' '}
              <Select
                placeholder="New Vehicles Price Column"
                label="Select Price for new vehicles"
                onChange={val =>
                  onSelectChange('touse_new_price_col', val?._id)
                }
                value={headers.find(
                  el =>
                    el._id?.toLowerCase() ===
                    prices.touse_new_price_col?.toLowerCase(),
                )}
                options={headers}
              />
            </>
          )}
        </div>
        <div className="flex items-center gap-2 mt-auto justify-end">
          <Button status="secondary" size="large" onClick={onClose}>
            Cancel
          </Button>
          <Button
            status="primary"
            size="large"
            onClick={() => {
              updatePriceColumnHandler()
            }}
            disabled={isLoading}
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      </div>
    </Drawer>
  )
}

export default PriceModal
