import React, { useCallback, useState } from 'react'
import Modal from '@/components/Modal'
import { Button, Checkbox } from '@/components'
import {
  clearAll,
  onStepChange,
} from '@/store/slices/pages/successPortal/broadcasting.slice'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { useProcessBroadcastMutation } from '@/api/services/success-portal/broadcasting'
import toast from 'react-hot-toast'

interface ITermsModal {
  open: boolean
  onClose: () => void
}

const TermsModal = ({ open, onClose }: ITermsModal) => {
  const dispatch = useAppDispatch()
  const { broadcasting_id } = useAppSelector(state => state.broadcasting)
  const [process, { isLoading }] = useProcessBroadcastMutation()

  const onModalChange = useCallback(
    (val?: number) => {
      dispatch(onStepChange(val))
    },
    [dispatch],
  )

  const onSubmit = () => {
    process({ id: broadcasting_id })
      .unwrap()
      .then(res => {
        if (res) {
          onModalChange()
          onClose()
          toast.success(res.message)
          dispatch(clearAll())
          localStorage.removeItem('broadcasting_id')
        }
        toast.success('Broadcast scheduled successfully')
      })
      .catch(() => {
        toast.error('Failed to schedule broadcast')
      })
  }
  const [checked, setChecked] = useState(false)
  return (
    <Modal
      showBackdrop={false}
      open={open}
      onClose={onClose}
      title="Terms and conditions"
      footer={
        <div className="mt-auto w-full flex gap-2 justify-end">
          <Button
            status="secondary"
            size="large"
            onClick={() => {
              onClose()
              onModalChange(5)
            }}
          >
            Previous
          </Button>
          <Button
            status="primary"
            size="large"
            disabled={!checked || isLoading}
            onClick={onSubmit}
            loading={isLoading}
          >
            Schedule
          </Button>
        </div>
      }
    >
      <Checkbox
        checked={checked}
        onChange={setChecked}
        label="I accept that the client list I will be broadcasting too has given me authority to reach out to them through SMS for any promotional related communications."
      />
    </Modal>
  )
}

export default TermsModal
