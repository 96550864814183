import { createSlice } from '@reduxjs/toolkit'
import { IWorkflowFormState } from '@/types'

export const defaultState: IWorkflowFormState = {
  currentStep: 1,
  form: {},
  formErrors: {},
  isLoading: true,
  samples: [],
  status: undefined,
  shouldShow: {
    offsetField: true,
    messageField: true,
    combinatorField: true,
  },
  isRequired: {
    offsetField: true,
    messageField: true,
    combinatorField: true,
  },
}

const initialState: IWorkflowFormState = {
  ...defaultState,
}

const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    onFormChange: (state, action) => {
      state.form = { ...state.form, ...action.payload }
      const template = action.payload.template

      if (template) {
        if (template.isRequired) {
          state.isRequired = template.isRequired
        } else {
          state.isRequired = defaultState.isRequired
        }
        if (template.shouldShow) {
          state.shouldShow = template.shouldShow
        } else {
          state.shouldShow = defaultState.shouldShow
        }
      }
    },
    onStepChange: (state, action) => {
      state.currentStep = action.payload
    },
    onLoadingChange: (state, action) => {
      state.isLoading = action.payload
    },
    setFormErrors: (state, action) => {
      state.formErrors = action.payload
    },
    onSampleChange: (state, action) => {
      state.samples = action.payload
    },
    reset: state => {
      state.form = defaultState.form
      state.currentStep = defaultState.currentStep
      state.formErrors = defaultState.formErrors
      state.samples = defaultState.samples
    },
    onStatusChange: (state, action) => {
      state.status = action.payload
    },
  },
})

export const {
  onFormChange,
  reset,
  setFormErrors,
  onStepChange,
  onLoadingChange,
  onSampleChange,
  onStatusChange,
} = workflowSlice.actions
export default workflowSlice.reducer
