import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  DataTable,
  Drawer,
  DrawerSize,
  Option,
  Select,
  Toggle,
  Typography,
} from '@/components'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  onStateChange,
  onStepChange,
} from '@/store/slices/pages/successPortal/broadcasting.slice'
import { campaignTableColumns } from '@/pages/SuccessPortal/Broadcasting/config'
import { ICampaignTableFilter, ICampaignTypes } from '@/types'
import { MultiValue, SingleValue } from 'react-select'
import { useUpdateSegmentMutation } from '@/api/services/success-portal/broadcasting'
import { useOrganizationLocationsQuery } from '@/api/services/organization'
import {
  useGetWorkflowsQuery,
  useGetWorkflowUsersQuery,
} from '@/api/services/workflow'
import toast from 'react-hot-toast'

const fieldText = {
  workflow_id: 'workflow',
  location_id: 'location',
  assignees: 'users',
}

interface CampaignTableTopItemProps {
  type: keyof ICampaignTypes
  options: Option[]
  isMulti?: boolean
  checked: boolean
  onChange: (key: keyof ICampaignTypes, val: boolean) => void
  onSelectChange: (
    key: keyof ICampaignTypes,
    val?: MultiValue<Option<string>> | SingleValue<Option<string>>,
  ) => void
  value?: string | string[]
}

interface CampaignTableTopProps {
  state: ICampaignTableFilter
  setState: React.Dispatch<React.SetStateAction<ICampaignTableFilter>>
  workflows: Option[]
  locations: Option[]
  users: Option[]
  onTableFilterSelectChange: (
    key: keyof ICampaignTypes,
    value?: string | string[],
  ) => void
}

const CampaignTableTopItem = ({
  type,
  options,
  isMulti,
  value,
  onChange,
  onSelectChange,
  checked,
}: CampaignTableTopItemProps) => {
  const selectedValue = isMulti
    ? options.filter(el => value?.includes(el._id))
    : options.find(el => el._id === value)
  return (
    <td className="p-4">
      <div className="flex flex-col gap-3">
        <div className="flex items-center gap-1">
          <Typography variant="small" weight="medium">
            Assign same {fieldText[type]} to all
          </Typography>
          <Toggle checked={checked} onChange={val => onChange(type, val)} />
        </div>
        <Select
          disabled={!checked}
          isMulti={isMulti}
          onChange={val => onSelectChange(type, val)}
          value={selectedValue}
          options={options}
          placeholder={`Select ${fieldText[type]}`}
        />
      </div>
    </td>
  )
}

const CampaignTableTop = ({
  state,
  setState,
  users,
  workflows,
  locations,
  onTableFilterSelectChange,
}: CampaignTableTopProps) => {
  const onToggle = useCallback(
    (key: string, val: boolean) => {
      switch (key) {
        case 'workflow_id':
          setState(prev => ({ ...prev, sameWorkflow: val }))
          break
        case 'location_id':
          setState(prev => ({ ...prev, sameLocation: val }))
          break
        case 'assignees':
          setState(prev => ({ ...prev, sameUser: val }))
          break
        default:
          break
      }
    },
    [setState],
  )

  const onSelectChange = useCallback(
    (
      key: keyof ICampaignTypes,
      value?: MultiValue<Option<string>> | SingleValue<Option<string>>,
    ) => {
      if (key === 'assignees') {
        const val = value as MultiValue<Option<string>>
        setState(prev => {
          return { ...prev, [key]: val?.map(val => val._id) }
        })
        onTableFilterSelectChange(
          key,
          val?.map(val => val._id),
        )
      } else {
        const val = value as SingleValue<Option<string>>
        setState(prev => ({ ...prev, [key]: val?._id }))
        onTableFilterSelectChange(key, val?._id)
      }
    },
    [onTableFilterSelectChange, setState],
  )

  return (
    <tr>
      <td></td>
      <CampaignTableTopItem
        type="location_id"
        options={locations}
        checked={state.sameLocation}
        onChange={onToggle}
        onSelectChange={onSelectChange}
        value={state.location_id}
      />
      <CampaignTableTopItem
        type="assignees"
        options={users}
        checked={state.sameUser}
        onChange={onToggle}
        onSelectChange={onSelectChange}
        value={state.assignees}
        isMulti
      />
      <CampaignTableTopItem
        type="workflow_id"
        options={workflows}
        checked={state.sameWorkflow}
        onChange={onToggle}
        onSelectChange={onSelectChange}
        value={state.workflow_id}
      />
    </tr>
  )
}

const CampaignModal = () => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const { segments, organization } = useAppSelector(state => state.broadcasting)
  const [tableState, setTableState] = useState<ICampaignTableFilter>({
    sameWorkflow: false,
    sameLocation: false,
    sameUser: false,
    workflow_id: '',
    location_id: '',
    assignees: [],
  })

  const { data: { locations = [] } = {} } = useOrganizationLocationsQuery(
    {
      id: organization,
    },
    {
      skip: !organization,
    },
  )
  const { data: { data: workflows = [] } = {} } = useGetWorkflowsQuery(
    {
      id: tableState.location_id,
    },
    {
      skip: !tableState.location_id,
    },
  )

  const { data: { users = [] } = {} } = useGetWorkflowUsersQuery(
    {
      organization,
    },
    {
      skip: !organization,
    },
  )

  const workflowList = workflows.map(el => ({
    _id: el._id,
    name: el.name,
  }))

  const userList = useMemo(
    () =>
      users.map(user => ({
        _id: user._id,
        name: user.firstName + ' ' + user.lastName,
      })),
    [users],
  )

  const [data, setData] = useState(segments)
  const dispatch = useAppDispatch()
  const { uploadStep } = useAppSelector(state => state.broadcasting)
  const [updateSegment] = useUpdateSegmentMutation()

  useEffect(() => {
    setData(segments)
  }, [segments])

  const onModalChange = useCallback(
    (val?: number) => {
      dispatch(onStepChange(val))
    },
    [dispatch],
  )
  const onSelectChange = (
    key: string,
    index: number,
    value?: string | string[],
  ) => {
    setData(prev =>
      prev.map(el => {
        if (el.segment_number === index) {
          return {
            ...el,
            [key]: value,
          }
        }
        return el
      }),
    )
  }

  const onTableFilterSelectChange = useCallback(
    (key: keyof ICampaignTypes, value?: string | string[]) => {
      setData(prev =>
        prev.map(el => ({
          ...el,
          [key]: value,
        })),
      )
    },
    [],
  )

  const hasError = data.some(el => {
    return !el.location_id
  })

  const onSubmit = () => {
    setError(false)
    if (hasError) {
      setError(true)
      return
    } else {
      setLoading(true)
      Promise.all(
        data.map(el =>
          updateSegment({
            id: el.id,
            data: {
              location_id: el.location_id,
              workflow_id: el.workflow_id,
              assignees: el.assignees,
            },
          })
            .unwrap()
            .catch(() => {
              return null
            }),
        ),
      )
        .then(res => {
          if (res.some(el => !el)) {
            toast.error('Failed to update some segments')
            return
          } else {
            toast.success('Segments updated successfully')
            dispatch(
              onStateChange({
                segments: segments.map((item, index) => {
                  return res[index] ? { ...item, ...res[index] } : item
                }),
              }),
            )
          }
        })
        .finally(() => {
          setLoading(false)
          onModalChange(5)
        })
    }
  }

  return (
    <Drawer
      open={uploadStep === 4}
      onClose={onModalChange}
      title="Start your campaign 1/3"
      size={DrawerSize.XL}
    >
      <div className="flex flex-col gap-4 grow">
        <div>
          <DataTable
            tableTopRow={
              <CampaignTableTop
                state={tableState}
                setState={setTableState}
                locations={locations}
                users={userList}
                workflows={workflowList}
                onTableFilterSelectChange={onTableFilterSelectChange}
              />
            }
            className="!overflow-visible"
            data={data}
            columns={campaignTableColumns({
              sameWorkflow: tableState.sameWorkflow,
              sameUser: tableState.sameUser,
              sameLocation: tableState.sameLocation,
              workflows: workflowList,
              users: userList,
              locations,
              onChange: onSelectChange,
            })}
          />
        </div>
        <div className="mt-auto flex gap-2 justify-end">
          <Button
            status="primary"
            size="large"
            onClick={() => {
              // onModalChange(5)
              onSubmit()
            }}
            disabled={hasError}
            loading={loading}
          >
            Next
          </Button>
        </div>
      </div>
    </Drawer>
  )
}

export default CampaignModal
