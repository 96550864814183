import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, HighlightText, Typography } from '@/components'
import { onStateChange } from '@/store/slices/pages/successPortal/broadcasting.slice'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import clsx from 'clsx'
import { ReactComponent as CloseIcon } from '@/assets/icons/Close.svg'
import { ReactComponent as ErrorIcon } from '@/assets/icons/Error Natural.svg'
import { broadcastingTemplateFields } from '@/constants/broadcasting'
import { chunkArray } from '@/utils'
import { IInvalidDataCount } from '@/types'
import Tooltip from '@/components/Tooltip'

const INITIAL_CHUNKS = 2

const invalidDataKeys = ['phone_number']

const VerifyDataTable = ({
  invalidDataCount,
}: {
  invalidDataCount: IInvalidDataCount
}) => {
  const dispatch = useAppDispatch()

  const [visibleChunks, setVisibleChunks] = useState(INITIAL_CHUNKS)
  const observerRef = useRef<HTMLTableRowElement | null>(null)

  const {
    uploadedData,
    mapColumns,
    rows_per_segment,
    findForm: { findValue },
  } = useAppSelector(state => state.broadcasting)

  const chunkedData = useMemo(
    () => chunkArray(uploadedData, Number(rows_per_segment)),
    [rows_per_segment, uploadedData],
  )

  const visibleData = useMemo(
    () => chunkedData.slice(0, visibleChunks).flat(),
    [chunkedData, visibleChunks],
  )

  const onRemove = useCallback(
    (key: number) => {
      const newData = uploadedData.filter((_, index) => index !== key)
      dispatch(onStateChange({ uploadedData: newData }))
    },
    [uploadedData, dispatch],
  )

  useEffect(() => {
    if (!observerRef.current || visibleChunks >= chunkedData.length) return

    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setVisibleChunks(prev => Math.min(prev + 1, chunkedData.length))
        }
      },
      { root: null, rootMargin: '200px', threshold: 0.1 },
    )

    observer.observe(observerRef.current)

    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current)
    }
  }, [visibleChunks, chunkedData.length])

  return (
    <div className="w-full modal-table-height overflow-auto">
      <div className="overflow-x-auto w-full rounded-xl border">
        <table className="w-full rounded-xl">
          <thead className="rounded-t-xl overflow-hidden bg-bg-gray">
            <tr className="border border-gray-100">
              <th className="px-4 py-3 text-left whitespace-nowrap">
                <Typography
                  variant="small"
                  weight="medium"
                  className="text-black-100"
                  as="div"
                >
                  #
                </Typography>
              </th>
              {broadcastingTemplateFields.map(field => (
                <th
                  className="px-4 py-3 text-left whitespace-nowrap"
                  key={field._id}
                >
                  <Typography
                    variant="small"
                    weight="medium"
                    className="text-black-100 flex items-center gap-4"
                    as="div"
                  >
                    {field.name}
                    {invalidDataCount[field._id] > 0 && (
                      <Tooltip
                        content={`${invalidDataCount[field._id]} invalid elements`}
                      >
                        <ErrorIcon
                          className={clsx(
                            'w-5 h-5',
                            !invalidDataKeys.includes(field._id)
                              ? 'text-orange-400'
                              : 'text-red-100',
                          )}
                        />
                      </Tooltip>
                    )}
                  </Typography>
                </th>
              ))}
              <th className="px-4 py-3 text-left whitespace-nowrap">
                <Typography
                  variant="small"
                  weight="medium"
                  className="text-black-100 w-full flex justify-end"
                  as="div"
                >
                  Remove
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y rounded-b-xl bg-white-100 divide-bg-stroke">
            {visibleData.map((row, index) => (
              <tr key={index}>
                <td className="p-4">
                  <Typography
                    variant="small"
                    weight="normal"
                    className={clsx('flex w-full h-full', 'text-black-80')}
                    as="div"
                  >
                    {index + 1}
                  </Typography>
                </td>
                {mapColumns.map(({ key, column, templateField }) => {
                  const isMust = key === 'phone_number'
                  let emptyField = false
                  let invalidPhoneNumber = false
                  let phoneStr = ''
                  let tooltipContent = 'Empty Field'
                  if (!column || !row[column]) {
                    emptyField = true
                    if (isMust) {
                      tooltipContent = `${templateField} is required!`
                    }
                  } else {
                    if (key === 'phone_number') {
                      phoneStr = row[column].toString().replace(/\D/g, '')
                      if (!phoneStr.match(/^\d{10}$/)) {
                        tooltipContent = 'Invalid Phone Number'
                        invalidPhoneNumber = true
                      }
                    }
                  }

                  return (
                    <td className="p-4 " key={key}>
                      <Typography
                        variant="small"
                        weight="normal"
                        className={clsx(
                          'flex w-full h-full',
                          'text-black-80 flex items-center gap-2',
                        )}
                        as="div"
                      >
                        {(emptyField || invalidPhoneNumber) && (
                          <Tooltip content={tooltipContent}>
                            <ErrorIcon
                              className={clsx(
                                'w-5 h-5',
                                !isMust ? 'text-orange-400' : 'text-red-100',
                              )}
                            />
                          </Tooltip>
                        )}
                        <HighlightText
                          text={
                            key === 'phone_number'
                              ? phoneStr
                              : column
                                ? row?.[column]
                                : ''
                          }
                          search={findValue}
                          isActive={!column ? true : column === key}
                        />
                      </Typography>
                    </td>
                  )
                })}
                <td
                  className="p-4 flex justify-end"
                  onClick={() => onRemove(index)}
                >
                  <Button
                    status="text"
                    size="small"
                    onClick={() => onRemove(index)}
                  >
                    <CloseIcon />
                  </Button>
                </td>
              </tr>
            ))}
            <tr ref={observerRef}>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default VerifyDataTable
