import React, { useCallback, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  onStateChange,
  onStepChange,
} from '@/store/slices/pages/successPortal/broadcasting.slice'
import { Button, Drawer, DrawerSize } from '@/components'
import ContentNavigation from '@/pages/SuccessPortal/Broadcasting/ContentModal/Navigation'
import AIGenerated from '@/pages/SuccessPortal/Broadcasting/ContentModal/AIGenerated'
import BuildOwn from '@/pages/SuccessPortal/Broadcasting/ContentModal/BuildOwn'
import { useUpdateBroadcastMutation } from '@/api/services/success-portal/broadcasting'
import toast from 'react-hot-toast'

const BroadcastingContentModal = () => {
  const dispatch = useAppDispatch()
  const {
    uploadStep,
    broadcasting_id,
    message,
    exclude_archived,
    exclude_dnc,
  } = useAppSelector(state => state.broadcasting)
  const [updateBroadcast, { isLoading }] = useUpdateBroadcastMutation()

  const [currentView, setCurrentView] = useState('own')

  const onModalChange = useCallback(
    (val?: number) => {
      dispatch(onStepChange(val))
    },
    [dispatch],
  )

  const submit = useCallback(() => {
    if (!message) {
      dispatch(onStateChange({ errors: { message: 'Message is required' } }))
      return
    }
    updateBroadcast({
      id: broadcasting_id,
      data: { message, exclude_archived, exclude_dnc },
    })
      .unwrap()
      .then(res => {
        toast.success('Broadcast updated successfully')
        if (res) {
          onModalChange(6)
          dispatch(onStateChange(res))
        }
      })
      .catch(() => {
        toast.error('Failed to update broadcast')
      })
  }, [
    broadcasting_id,
    dispatch,
    exclude_archived,
    exclude_dnc,
    message,
    onModalChange,
    updateBroadcast,
  ])

  return (
    <Drawer
      open={uploadStep === 5}
      onClose={onModalChange}
      title="Build Your Content 2/3"
      size={DrawerSize.XL}
    >
      <div className="flex flex-col gap-4 grow">
        <div className="flex flex-col gap-3">
          <ContentNavigation
            currentView={currentView}
            onChange={setCurrentView}
          />
          {currentView === 'ai' ? <AIGenerated /> : <BuildOwn />}
        </div>
        <div className="mt-auto flex gap-2 justify-end ">
          <Button
            status="secondary"
            size="large"
            onClick={() => onModalChange(4)}
          >
            Previous
          </Button>
          <Button
            status="primary"
            size="large"
            loading={isLoading}
            onClick={() => {
              submit()
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </Drawer>
  )
}

export default BroadcastingContentModal
