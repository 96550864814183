import React from 'react'
import {
  Button,
  buttonStyles,
  Drawer,
  DrawerSize,
  Typography,
} from '@/components'
import { ISalesRow } from '@/types'
import { salesRowTableColumns } from '@/pages/SuccessPortal/SalesDashboard/config'
import Table from '@/components/Table'
import clsx from 'clsx'
import { CSVLink } from 'react-csv'

const SalesListView = ({
  open,
  onClose,
  items,
  isDark,
  section,
  fileName,
}: {
  open: boolean
  onClose: () => void
  items: ISalesRow[] | null
  isDark?: boolean
  section: string
  fileName: string
}) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={`${section} - Sales`}
      size={DrawerSize.XL}
      theme={isDark ? 'dark' : 'light'}
    >
      <div className="flex flex-col gap-6 w-full">
        <div className="flex items-center justify-between">
          <Typography
            variant="medium"
            weight="medium"
            className={clsx({
              'text-white-100': isDark,
            })}
          >
            Sales: {items?.length}
          </Typography>
          <CSVLink data={items || []} filename={fileName}>
            {isDark ? (
              <button
                className={clsx(
                  buttonStyles.base,
                  buttonStyles.large,
                  'bg-[#5B66EA] rounded-xl',
                )}
              >
                Download CSV
              </button>
            ) : (
              <Button status="secondary" size="small">
                Download CSV
              </Button>
            )}
          </CSVLink>
        </div>
        <Table
          theme={isDark ? 'dark' : 'light'}
          data={items || []}
          // @ts-ignore
          columns={salesRowTableColumns(isDark ? 'dark' : 'light')}
        />
      </div>
    </Drawer>
  )
}

export default SalesListView
