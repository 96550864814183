import React, { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  clearFilter,
  onFilterChange,
} from '@/store/slices/pages/inventory.slice'
import { Button, Input, Option, Select } from '@/components'
import { ReactComponent as SearchIcon } from '@/assets/icons/Search.svg'
import { inventoryStatusOptions, vendors } from '@/constants/inventory'

const InventoryFilter = () => {
  const dispatch = useAppDispatch()

  const {
    filter: { status, organization, vendor },
  } = useAppSelector(state => state.inventory)

  const onSelectChange = useCallback(
    (field: string, value: Option | null) => {
      dispatch(
        onFilterChange({
          [field]: (!Array.isArray(value) && value?._id) || '',
          page: 1,
        }),
      )
    },
    [dispatch],
  )

  const reset = useCallback(() => {
    dispatch(clearFilter())
  }, [dispatch])

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(onFilterChange({ organization: e.target.value, page: 1 }))
    },
    [dispatch],
  )

  const options = useMemo(() => {
    return [{ _id: '', name: 'All' }, ...inventoryStatusOptions]
  }, [])

  const vendorOptions = useMemo(() => {
    return [{ _id: '', name: 'All' }, ...vendors]
  }, [])

  return (
    <div className="flex items-end">
      <div className="grid grid-cols-6 gap-3 w-full">
        <Select
          label="Status"
          placeholder="Status"
          options={options}
          value={options.find(loc => loc._id === status) || null}
          onChange={val => onSelectChange('status', val)}
        />
        <Select
          label="Vendor"
          placeholder="Vendor"
          options={vendorOptions}
          value={vendorOptions.find(loc => loc._id === vendor) || null}
          onChange={val => onSelectChange('vendor', val)}
        />
        <Input
          label="Organization/Dealer"
          IconLeft={SearchIcon}
          placeholder="Search"
          value={organization}
          onChange={handleInputChange}
        />
      </div>
      <Button className="ml-auto" status="text" size="small" onClick={reset}>
        Clear All
      </Button>
    </div>
  )
}

export default InventoryFilter
