import React from 'react'
import PageHeading from '@/sections/PageHeading'
import { Button, DataTable } from '@/components'
import Pagination from '@/components/Pagination'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  onFilterChange,
  onFormToggle,
} from '@/store/slices/pages/inventory.slice'
import InventoryFilter from '@/pages/Inventory/Filter'
import InventoryStats from '@/pages/Inventory/Stats'
import { inventoryTableColumns } from '@/pages/Inventory/config'
import { useGetInventoriesQuery } from '@/api/services/inventory'
import InventoryForm from 'src/pages/Inventory/TableActions/Form'
import InventoryTableActions from '@/pages/Inventory/TableActions'
import ImportJobsModal from '@/pages/Inventory/TableActions/ImportJobs'
import FileList from '@/pages/Inventory/TableActions/FileList'
import AuditTrailModal from '@/pages/Inventory/TableActions/AuditTrail'
import { IInventory } from '@/types/inventory'
import InventorySearch from '@/sections/InventorySearch'
import InventoryTableToggle from '@/pages/Inventory/TableToggle'

import { ReactComponent as PlusIcon } from '@/assets/icons/Plus.svg'
import PriceModal from '@/pages/Inventory/TableActions/Price'

const renderSubComponent = (span: number) => (row: IInventory) => {
  return (
    <tr>
      <td colSpan={span} className="py-2 px-5 bg-gray-80">
        <InventorySearch
          vendor={row.vendor}
          organization={row.organization._id}
        />
      </td>
    </tr>
  )
}

const Inventory = () => {
  const dispatch = useAppDispatch()
  const { filter } = useAppSelector(state => state.inventory)
  const {
    data: { data: inventories = [], pageMeta: { totalItems = 0 } = {} } = {},
    isFetching: loading,
    refetch,
  } = useGetInventoriesQuery(filter)

  const onPageChange = (page: number) => {
    dispatch(
      onFilterChange({
        page,
      }),
    )
  }

  return (
    <div className="w-full flex flex-col gap-6">
      <PageHeading
        title="Inventory"
        extra={
          <Button
            size="small"
            status="primary"
            IconLeft={PlusIcon}
            onClick={() => {
              dispatch(onFormToggle(true))
            }}
          >
            Add
          </Button>
        }
      />

      <InventoryStats totalItems={totalItems} loading={loading} />

      <InventoryFilter />
      <DataTable
        className="!overflow-visible"
        loading={loading}
        // @ts-ignore
        columns={inventoryTableColumns.map(col => {
          if (col.id === 'id') {
            return {
              ...col,
              cell: ({ row }) => {
                const onExpand = () => {
                  row.toggleExpanded()
                }
                return (
                  <InventoryTableActions
                    item={row.original as IInventory}
                    onExpand={onExpand}
                    onSuccess={refetch}
                  />
                )
              },
            }
          }
          if (col.id === 'status') {
            return {
              ...col,
              cell: info => {
                return (
                  <InventoryTableToggle
                    inventory={info.row.original as IInventory}
                    refetch={refetch}
                  />
                )
              },
            }
          }
          return col
        })}
        data={inventories}
        renderSubComponent={renderSubComponent(inventoryTableColumns.length)}
      />
      <Pagination
        currentPage={filter.page}
        totalCount={totalItems}
        onPageChange={onPageChange}
        pageSize={filter.pageSize}
      />
      <InventoryForm onSuccess={refetch} />
      <ImportJobsModal inventories={inventories} />
      <FileList />
      <AuditTrailModal />
      <PriceModal onSuccess={refetch} />
    </div>
  )
}

export default Inventory
