import { createApi } from '@reduxjs/toolkit/query/react'
import { endpoints } from '@/api/constants'
import {
  IOrganizationWorkflowResponse,
  IResponse,
  ISample,
  ISamplesPayload,
  IUser,
  IWorkflow,
  IWorkflowListItem,
  IWorkflowLocation,
  IWorkflowState,
  IWorkflowTemplateResponse,
} from '@/types'
import { createBaseQuery, generatePath, generateQueryParams } from '@/utils'

export const workflowApi = createApi({
  reducerPath: 'workflowApi',
  tagTypes: ['Workflow', 'SingleWorkflow'],
  baseQuery: createBaseQuery(),
  endpoints: builder => ({
    getWorkflows: builder.query<IResponse<IWorkflowListItem[]>, { id: string }>(
      {
        query: ({ id }) => {
          return {
            url: generatePath(endpoints.getWorkflow, { id }),
          }
        },
      },
    ),
    getSingleWorkflow: builder.query<IWorkflow, { id?: string }>({
      query: ({ id }) => {
        return {
          url: generatePath(endpoints.workflow, { id }),
        }
      },
      providesTags: ['SingleWorkflow'],
    }),
    getWorkflowUsers: builder.query<
      { users: IUser[] },
      { organization?: string }
    >({
      query: ({ organization }) => {
        return {
          url: generatePath(endpoints.workflowUsers, { organization }),
        }
      },
    }),
    getOrganizationWorkflows: builder.query<
      IOrganizationWorkflowResponse,
      { id?: string; active?: boolean }
    >({
      query: ({ id, active }) => {
        return {
          url:
            generatePath(endpoints.organizationWorkflowAll, { id }) +
            (active !== undefined ? generateQueryParams({ active }) : ''),
        }
      },
    }),
    getSamples: builder.query<ISample[], ISamplesPayload>({
      query: ({ organization, crm, combinator }) => {
        return {
          url: generatePath(endpoints.getSampleResults, { organization }),
          method: 'POST',
          body: { crm, combinator },
        }
      },
    }),
    getOrganizationWorkflowLocations: builder.query<
      { locations: IWorkflowLocation[] },
      { id: string }
    >({
      query: query => {
        return {
          url: generatePath(endpoints.organizationWorkflowLocations, query),
        }
      },
    }),
    getOrganizationWorkflowList: builder.query<
      IOrganizationWorkflowResponse,
      { id: string }
    >({
      query: query => {
        return {
          url: generatePath(endpoints.organizationWorkflow, query),
        }
      },
      providesTags: ['Workflow'],
    }),
    getWorkflowTemplates: builder.query<
      IWorkflowTemplateResponse,
      { id?: string }
    >({
      query: query => {
        return {
          url: generatePath(endpoints.workflowTemplates, query),
        }
      },
    }),
    toggleWorkflow: builder.mutation<
      IWorkflow,
      { locationId: string; workflowId: string }
    >({
      query: query => {
        return {
          url: generatePath(endpoints.toggleWorkflow, query),
          method: 'POST',
        }
      },
    }),
    deleteWorkflow: builder.mutation<
      string,
      { locationId: string; workflowId: string }
    >({
      query: query => {
        return {
          url: generatePath(endpoints.deleteWorkflow, query),
          method: 'DELETE',
          responseHandler: response => response.text(),
        }
      },
      invalidatesTags: ['Workflow'],
    }),
    editWorkflow: builder.mutation<
      IWorkflow,
      { locationId: string; workflowId: string } & IWorkflowState
    >({
      query: ({ locationId, workflowId, ...rest }) => {
        return {
          url: generatePath(endpoints.editWorkflow, {
            locationId,
            workflowId,
          }),
          method: 'POST',
          body: rest,
        }
      },
      invalidatesTags: ['Workflow'],
    }),
    addWorkflow: builder.mutation<
      IWorkflow,
      { locationId: string; workflowId: string } & IWorkflowState
    >({
      query: ({ locationId, workflowId, ...rest }) => {
        return {
          url: generatePath(endpoints.createWorkflow, {
            locationId,
            workflowId,
          }),
          method: 'POST',
          body: rest,
        }
      },
      invalidatesTags: ['Workflow'],
    }),
  }),
})

export const {
  useGetWorkflowUsersQuery,
  useGetOrganizationWorkflowsQuery,
  useGetWorkflowsQuery,
  useGetOrganizationWorkflowLocationsQuery,
  useGetOrganizationWorkflowListQuery,
  useDeleteWorkflowMutation,
  useToggleWorkflowMutation,
  useGetWorkflowTemplatesQuery,
  useGetSingleWorkflowQuery,
  useGetSamplesQuery,
  useAddWorkflowMutation,
  useEditWorkflowMutation,
} = workflowApi
