import React, { useCallback, useEffect, useState } from 'react'
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

import {
  ADMIN_MAIN_MENU,
  AUTH_TOKEN_KEY,
  AUTOMATIONS,
  DASHBOARD,
  SETTINGS,
  SIGN_IN,
} from '@/constants'
import { Logo, Typography } from '@/components'

import Select, { Option } from '@/components/Select'

import { ReactComponent as BellIcon } from '@/assets/icons/Bell.svg'
import { ReactComponent as SettingsIcon } from '@/assets/icons/Settings.svg'
import { ReactComponent as LogoutIcon } from '@/assets/icons/Logout.svg'
import WithBadge from '@/sections/WithBadge'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { removeStorageValue } from '@/utils'
import { toggleNotifications } from '@/store/slices/notification.slice'
import { useGetOrganizationsQuery } from '@/api/services/organization-list'
import clsx from 'clsx'
import { SingleValue } from 'react-select'
import { clear } from '@/store/slices/auth.slice'
import ActionButton from '@/components/ActionButton'

const Header = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const { data: organizations = [] } = useGetOrganizationsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })
  const { notifications } = useAppSelector(state => state.notifications)
  const [org, setOrg] = useState<SingleValue<Option>>(null)

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      const selectedOrg = organizations.find(org => org._id === id)
      if (selectedOrg) {
        setOrg(selectedOrg)
      }
    } else {
      setOrg(null)
    }
  }, [id, organizations, location])

  const onNotificationOpen = useCallback(() => {
    dispatch(toggleNotifications(true))
  }, [dispatch])

  return (
    <div className="px-8 py-1 w-full sticky top-0 bg-[#080224] z-10">
      <div className="h-16 flex items-center gap-10">
        <Link to={DASHBOARD} className="w-36">
          <Logo type="large" />
        </Link>
        <div className="flex items-center">
          {ADMIN_MAIN_MENU.map(({ key, title, path, subMenus, showInMenu }) => {
            if (!showInMenu) return null
            const isParentActive = subMenus?.find(
              menu => menu.path === location.pathname,
            )
            return (
              <NavLink
                key={key}
                to={path}
                className={({ isActive }) =>
                  clsx(
                    'py-2 px-3 text-white-100 text-opacity-80 hover:text-opacity-100 rounded-md transition-all duration-300',
                    {
                      'bg-blue-100 !text-white-100': isActive || isParentActive,
                    },
                  )
                }
                end
              >
                <Typography variant="regular" weight="medium">
                  {title}
                </Typography>
              </NavLink>
            )
          })}
        </div>
        <div className="flex flex-1 ml-auto justify-end gap-2">
          <Select
            placeholder="Select Organization"
            className="max-w-72"
            isSearchable
            options={[...organizations]}
            value={org}
            onChange={val => {
              setOrg(val)
              navigate(`/organizations/${val?._id}${AUTOMATIONS}`)
            }}
            variant="header"
          />
          <ActionButton
            Icon={SettingsIcon}
            as="NavLink"
            to={SETTINGS}
            theme="dark"
          />
          <WithBadge badge={notifications.length ? 'alert' : undefined}>
            <ActionButton
              theme="dark"
              Icon={BellIcon}
              onClick={() => {
                if (notifications.length) {
                  onNotificationOpen()
                }
              }}
            />
          </WithBadge>
          <div
            className="text-red-100 flex gap-2 px-4 py-2 items-center cursor-pointer rounded-lg border border-red-100 border-opacity-40 hover:bg-red-100 hover:bg-opacity-20 active:bg-red-100 active:bg-opacity-30 transition-all duration-300"
            onClick={() => {
              dispatch(clear())
              removeStorageValue(AUTH_TOKEN_KEY)
              navigate(SIGN_IN)
            }}
          >
            <LogoutIcon />
            <Typography variant="small" weight="medium">
              Logout
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
