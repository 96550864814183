import { ColumnDef } from '@tanstack/react-table'
import { IAuditTrail } from '@/types'
import { generateEventReadableText } from '@/utils'
import OrganizationLink from '@/sections/OrganizationLink'
import moment from 'moment-timezone'
import React from 'react'

export const auditTrailColumns: ColumnDef<IAuditTrail, any>[] = [
  {
    accessorKey: 'organization',
    header: () => <span>Organization</span>,
    cell: ({ getValue }) => {
      const organization = getValue()
      return <OrganizationLink id={organization._id} name={organization.name} />
    },
  },
  {
    accessorKey: 'type',
    header: () => <span>Change</span>,
    cell: info => {
      const event = info.row.original
      return generateEventReadableText(event)
    },
  },
  {
    accessorKey: 'createdAt',
    header: () => <span className="w-full flex justify-end">Date</span>,
    cell: ({ getValue }) => {
      return (
        <span className="w-full flex justify-end">
          {moment(getValue()).format('MM/DD/YYYY')}
        </span>
      )
    },
  },
]
