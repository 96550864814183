import { endpoints } from '@/api/constants'
import apiService from '@/api'
import {
  IGoogleLoginCredentials,
  ILoginCredentials,
  TUserToken,
} from '@/types/auth'
import {
  ICheckAuthResponse,
  IUserSettingsResponse,
  UserWithSettingsResponse,
} from '@/types'

export const login = async (data: ILoginCredentials) => {
  return await apiService.post<TUserToken>(endpoints.auth, data)
}

export const googleLogin = async (data: IGoogleLoginCredentials) => {
  return await apiService.post<TUserToken>(endpoints.googleAuth, data)
}

export const getUserWithSettings =
  async (): Promise<UserWithSettingsResponse> => {
    const [userResponse, settingsResponse] = await Promise.all([
      apiService.post<ICheckAuthResponse>(endpoints.checkAuthStatus),
      apiService.get<IUserSettingsResponse>(endpoints.userSettings),
    ])

    return { user: userResponse, settings: settingsResponse }
  }
