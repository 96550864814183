import { ReactComponent as DashboardIcon } from '@/assets/icons/Dashboard.svg'
import { ReactComponent as MonitoringIcon } from '@/assets/icons/System monitoring.svg'
import { ReactComponent as TaskIcon } from '@/assets/icons/Task.svg'
import { ReactComponent as SuperhumanIcon } from '@/assets/icons/Superhuman.svg'
import { ReactComponent as A2PIcon } from '@/assets/icons/A2P.svg'
import { ReactComponent as SettingsIcon } from '@/assets/icons/Settings.svg'
import { ReactComponent as LogoutIcon } from '@/assets/icons/Logout.svg'
import { permissionType, TMenu } from '@/types'
import { LockClosedIcon } from '@heroicons/react/24/outline'
import { Tag } from '@/components'
export const EXTERNAL = '/external'

export const SIGN_IN = '/sign-in'
export const DASHBOARD = '/'
export const ERROR_TRACKER = '/error-tracker'
export const AUTOMATIONS = '/automations'
export const LEADS = '/leads'
export const APPOINTMENTS = '/appointments'
export const AI_BOT = '/ai-bot'
export const ORGANIZATION_SUMMARY = '/organization-summary'
export const INVENTORY = '/inventory'
export const TASKS = '/tasks'
export const SUPERHUMAN = '/superhuman'
export const ORGANIZATION = '/organizations/:id'
export const REGISTRATION = 'registration'
export const A2P_REGISTRATION = '/registration'
export const A2P_PENDING = '/pending'
export const LOCKED_LOCATIONS = '/locked-locations'
export const SETTINGS = '/settings'
export const REPAIR_ORDERS = '/repair-orders'
export const SERVICE_APPOINTMENTS = '/service-appointments'
export const FORM = '/:automation'
export const V2_DASHBOARD = '/v2' + ORGANIZATION
export const WORKFLOW = `${V2_DASHBOARD}/:workFlowId`
export const ACCESS_CONTROL = `/access-control`
export const SUCCESS_PORTAL = `/success-portal`
export const ONBOARDING = `${SUCCESS_PORTAL}/onboarding`
export const A2P = `${SUCCESS_PORTAL}/a2p`
export const METRICS_INTELLIGENCE = `${SUCCESS_PORTAL}/metrics-intelligence`
export const BROADCASTING = `${SUCCESS_PORTAL}/broadcasting`
export const DOCUMENTATION = `${SUCCESS_PORTAL}/documentation`
export const SALES = `${SUCCESS_PORTAL}/sales`
export const EXTERNAL_SALES = `${EXTERNAL}/sales`
export const AUDIT_TRAIL = `${EXTERNAL}/audit-trail`
export const SUCCESS_PORTAL_ORGANIZATION_SUMMARY = `${SUCCESS_PORTAL}${ORGANIZATION_SUMMARY}`
export const ADMIN_MAIN_MENU: TMenu[] = [
  {
    path: DASHBOARD,
    title: 'Dashboard',
    Icon: DashboardIcon,
    key: 'dashboard',
    access: [permissionType.AUTOMATIONS_READ],
    showInMenu: true,
    subMenus: [
      // {
      //   path: DASHBOARD,
      //   title: 'Analytics',
      //   key: 'analytics',
      //   access: [permissionType.AUTOMATIONS_READ],
      // },
      {
        path: DASHBOARD,
        title: 'Automations',
        key: 'automations',
        access: [permissionType.AUTOMATIONS_READ],
      },
      {
        path: ERROR_TRACKER,
        title: 'Error Tracker',
        key: 'error-tracker',
        access: [permissionType.AUTOMATIONS_READ],
      },
      // {
      //   path: AUTOMATIONS,
      //   title: 'Automations',
      //   key: 'automations',
      //   access: [permissionType.AUTOMATIONS_READ],
      // },
      {
        path: LEADS,
        title: 'Leads',
        key: 'leads',
        access: [permissionType.AUTOMATIONS_READ],
      },
      {
        path: APPOINTMENTS,
        title: 'Appointments',
        key: 'appointments',
        access: [permissionType.AUTOMATIONS_READ],
      },
      {
        path: AI_BOT,
        title: 'AI Bot Customers',
        key: 'ai_bot_customers',
        access: [permissionType.AUTOMATIONS_READ],
      },
    ],
  },
  {
    path: SUPERHUMAN,
    title: 'Superhuman',
    Icon: SuperhumanIcon,
    key: 'superhuman',
    access: [permissionType.SUPERHUMAN_READ],
    showInMenu: true,
  },
  {
    Icon: TaskIcon,
    access: [permissionType.INVENTORY_ADMIN],
    showInMenu: true,
    path: INVENTORY,
    title: 'Inventory',
    key: 'inventory',
  },
  {
    path: ACCESS_CONTROL,
    title: 'Access Control',
    Icon: MonitoringIcon,
    key: 'access-control',
    access: [permissionType.ADMIN_READ, permissionType.ADMIN_WRITE],
    showInMenu: true,
  },

  {
    path: ORGANIZATION,
    title: 'Organization',
    key: 'organization',
    subMenus: [
      {
        path: `${ORGANIZATION}${AUTOMATIONS}`,
        title: 'Automations',
        key: 'organization/automations',
      },
      {
        path: `${ORGANIZATION}${TASKS}`,
        title: 'Tasks',
        key: 'organization/tasks',
      },
      {
        path: `${ORGANIZATION}${APPOINTMENTS}`,
        title: 'Appointments',
        key: 'organization/appointments',
      },
      {
        path: `${ORGANIZATION}${LEADS}`,
        title: 'Leads',
        key: 'organization/leads',
      },
      {
        path: `${ORGANIZATION}${AI_BOT}`,
        title: 'AI Bot Customers',
        key: 'organization/ai_bot_customers',
      },
      {
        path: `${ORGANIZATION}${REPAIR_ORDERS}`,
        title: 'Repair Orders',
        key: 'organization/repair-orders',
      },
      {
        path: `${ORGANIZATION}${SERVICE_APPOINTMENTS}`,
        title: 'Service Appointments',
        key: 'organization/service-appointments',
      },
      {
        path: `${ORGANIZATION}${AUTOMATIONS}/create`,
        title: 'Add New Automation',
        key: 'organization/automations/create',
        isClickable: true,
      },
      {
        path: `${ORGANIZATION}${SETTINGS}`,
        title: 'Settings',
        key: 'organization/settings',
      },
    ],
  },
  {
    path: SALES,
    title: 'Success Portal',
    Icon: A2PIcon,
    key: 'success-portal',
    access: [permissionType.ONBOARDING_READ, permissionType.ONBOARDING_WRITE],
    showInMenu: true,
    subMenus: [
      {
        path: SALES,
        title: 'Sales Dashboard',
        key: 'sales-dashboard',
        access: [
          permissionType.ONBOARDING_READ,
          permissionType.ONBOARDING_WRITE,
        ],
      },
      // {
      //   path: METRICS_INTELLIGENCE,
      //   title: 'Metrics Intelligence',
      //   key: 'metrics-intelligence',
      //   access: [
      //     permissionType.ONBOARDING_READ,
      //     permissionType.ONBOARDING_WRITE,
      //   ],
      // },
      // {
      //   path: ONBOARDING,
      //   title: 'Onboarding',
      //   key: 'onboarding',
      //   access: [
      //     permissionType.ONBOARDING_READ,
      //     permissionType.ONBOARDING_WRITE,
      //   ],
      // },

      {
        path: A2P,
        title: 'A2P',
        key: 'a2p',
        access: [
          permissionType.ONBOARDING_READ,
          permissionType.ONBOARDING_WRITE,
        ],
      },
      {
        path: BROADCASTING,
        title: 'Broadcast Splitter',
        key: 'broadcasting',
        titleExtra: (
          <Tag status="default" size="small">
            BETA
          </Tag>
        ),
        access: [
          permissionType.ONBOARDING_READ,
          permissionType.ONBOARDING_WRITE,
        ],
      },
      {
        path: AUDIT_TRAIL,
        title: 'Audit Trail',
        key: 'audit-trail',
        access: [],
      },
      // {
      //   path: SUCCESS_PORTAL_ORGANIZATION_SUMMARY,
      //   title: 'Organization Summary',
      //   key: 'success-portal/organization-summary',
      //   access: [
      //     permissionType.ONBOARDING_READ,
      //     permissionType.ONBOARDING_WRITE,
      //   ],
      // },
      // {
      //   path: DOCUMENTATION,
      //   title: 'Documentation',
      //   key: 'documentation',
      //   access: [
      //     permissionType.ONBOARDING_READ,
      //     permissionType.ONBOARDING_WRITE,
      //   ],
      // },
    ],
  },
]

export const ADMIN_BOTTOM_MENU: TMenu[] = [
  {
    path: SETTINGS,
    title: 'Settings',
    Icon: SettingsIcon,
    key: 'settings',
  },
  {
    path: ACCESS_CONTROL,
    title: 'Access Control',
    Icon: LockClosedIcon,
    key: 'access-control',
  },
  {
    path: SIGN_IN,
    title: 'Log Out',
    Icon: LogoutIcon,
    key: 'log-out',
  },
]
