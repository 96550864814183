import React, { useCallback, useMemo, useRef } from 'react'
import moment from 'moment'

import { Button, Select, TextArea, Input, Typography, Tag } from '@/components'

import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { unitOptions } from '@/constants/automations'
import { joinArray } from '@/utils'
import { onFormChange } from '@/store/slices/workflow.slice'
import { useGetWorkflowUsersQuery } from '@/api/services/workflow'
import { useParams } from 'react-router-dom'
import QueryBuilder from '@/pages/V2Dashboard/WorkflowForm/Steps/SelectOptions/QueryBuilder'

import '../../../styles.scss'

export const Combinators = ({ onSave }: { onSave: () => void }) => {
  const dispatch = useAppDispatch()
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const { id } = useParams()
  const { form, samples, shouldShow, isRequired } = useAppSelector(
    state => state.workflow,
  )
  const { data: { users = [] } = {} } = useGetWorkflowUsersQuery({
    organization: id,
  })

  const onUpdatePayload = useCallback(
    (field: string, value: any) => {
      dispatch(onFormChange({ [field]: value }))
    },
    [dispatch],
  )

  const onAddMessage = useCallback(
    (field: string) => () => {
      const currentMessage = form?.message || ''
      const cursorPosition = textareaRef?.current?.selectionStart
      const newMessage = `${currentMessage.slice(
        0,
        cursorPosition,
      )}{{${field}}}${currentMessage.slice(cursorPosition)}`
      // @ts-ignore
      dispatch(onFormChange({ message: newMessage }))
    },
    [dispatch, form?.message],
  )

  const onSetActions = useCallback(
    (user: string) => () => {
      let assignMessage = (
        form?.actions?.assignMessage ? [...form.actions.assignMessage] : []
      ) as string[]
      const isSelected = assignMessage?.includes(user)
      if (isSelected) {
        assignMessage = assignMessage.filter(u => u !== user)
      } else {
        assignMessage.push(user)
      }
      onUpdatePayload('actions', { ...form.actions, assignMessage })
    },
    [onUpdatePayload, form.actions],
  )

  const disabled = useMemo(
    () =>
      (!form.message && isRequired.messageField) ||
      (!form.combinator && isRequired.combinatorField) ||
      (!form.sendAfter && isRequired.offsetField),
    [
      form.message,
      form.combinator,
      form.sendAfter,
      isRequired.messageField,
      isRequired.combinatorField,
      isRequired.offsetField,
    ],
  )

  const dynamicFields = useMemo(() => {
    const fields = (form?.template?.dynamicFields || []).map(({ key }) => key)
    return [...fields, 'location_name', 'organization_name']
  }, [form?.template?.dynamicFields])

  return (
    <>
      <Typography variant="H3" weight="semibold">
        Let's build the workflow.
      </Typography>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-4">
        <div className="w-full flex flex-col gap-6">
          {(shouldShow?.combinatorField || shouldShow?.offsetField) && (
            <div>
              {shouldShow?.combinatorField && <QueryBuilder />}
              {shouldShow?.offsetField && (
                <div className="flex items-end gap-2 mt-3">
                  <Input
                    className="w-full"
                    label="Send after"
                    value={form?.sendAfter?.value}
                    onChange={e =>
                      onUpdatePayload('sendAfter', {
                        value: +e.target.value,
                        unit: form?.sendAfter?.unit ?? '',
                      })
                    }
                  />
                  <Select
                    className="w-full"
                    options={unitOptions}
                    placeholder="Select time unit"
                    value={unitOptions.find(
                      _v => _v._id === form?.sendAfter?.unit,
                    )}
                    onChange={option =>
                      onUpdatePayload('sendAfter', {
                        unit: option?._id,
                        value: form?.sendAfter?.value ?? 0,
                      })
                    }
                  />
                </div>
              )}
            </div>
          )}
          {shouldShow?.messageField && (
            <div>
              <div>
                <Typography
                  variant="regular"
                  weight="semibold"
                  className="mb-1"
                >
                  Message script
                </Typography>
                <Typography variant="small" className="text-black-80">
                  Supported dynamic fields are listed below. You can use them in
                  your message script by wrapping them in curly braces.
                  <b>(E.g. {'{{customer_name}}'})</b>
                </Typography>
                <TextArea
                  ref={textareaRef}
                  rows={6}
                  className="mt-2 block"
                  value={form?.message}
                  onChange={e => onUpdatePayload('message', e.target.value)}
                />
              </div>
              <Typography variant="small" weight="medium" className="my-2">
                Available dynamic fields:
              </Typography>
              <div className="flex flex-wrap gap-2">
                {dynamicFields.map(key => (
                  <Tag
                    variant="light"
                    status="info"
                    key={key}
                    onClick={onAddMessage(key)}
                  >
                    {key}
                  </Tag>
                ))}
              </div>
            </div>
          )}
          <div>
            <Typography variant="H3" weight="semibold">
              Assign users
            </Typography>
            <Typography variant="regular" className="mt-2 text-black-80">
              Users selected from the list will be assigned to any message
              triggered by this workflow.
            </Typography>
            <div className="flex flex-col items-center gap-2 mt-4 flex-wrap">
              {users.map(user => {
                const isSelected = form?.actions?.assignMessage?.includes?.(
                  user._id,
                )
                return (
                  <Button
                    key={user._id}
                    size="small"
                    status={isSelected ? 'primary' : 'secondary'}
                    onClick={onSetActions(user._id)}
                    className="w-full"
                  >
                    {joinArray(' ', user.firstName, user.lastName)}
                  </Button>
                )
              })}
            </div>
          </div>
        </div>
        <div>
          <div className="w-full">
            <Typography
              variant="regular"
              weight="medium"
              className="text-black-80 mb-4"
            >
              Sample results
            </Typography>
            {!samples.length && (
              <div className="w-full flex mx-auto justify-center py-2.5 px-4 bg-blue-40 rounded-lg text-white-100">
                Adjust your filters to see sample results.
              </div>
            )}
            {samples?.map?.((result, index) => {
              if (!result) return null
              return (
                <div
                  key={index}
                  className="mb-2 shadow rounded border px-5 py-3 w-1/2"
                >
                  <h6 className="font-semibold text-md">
                    {joinArray(
                      ' ',
                      result.customer.firstName,
                      result.customer.lastName,
                    )}
                  </h6>
                  <h6 className="font-medium text-sm mb-2 text-muted">
                    {joinArray(
                      ' ',
                      result.vehicle.modelYear,
                      result.vehicle.make,
                      result.vehicle.model,
                      `(${result.vehicle.vin})`,
                    )}
                  </h6>
                  <p className="text-sm">
                    {joinArray(
                      ' ',
                      'Service advisor:',
                      result.serviceAdvisor.firstName,
                      result.serviceAdvisor.lastName,
                    )}
                  </p>
                  <p className="text-sm">
                    {joinArray(
                      ' ',
                      'Imported on',
                      moment(result.createdAt).format('MM/DD/YYYY'),
                    )}
                  </p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="text-center mt-6">
        <Button
          size="large"
          status="primary"
          onClick={onSave}
          disabled={disabled}
        >
          Confirm and save workflow
        </Button>
      </div>
    </>
  )
}
