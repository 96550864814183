import { IPageMeta, IResponse, ISuccessResponse } from '@/types'
import {
  IImportJob,
  IInventory,
  IInventoryEvent,
  IInventoryFile,
  IInventoryFilter,
  IInventoryFormPayload,
  IInventoryType,
  InventoryStatus,
  InventoryType,
  IUnionInventory,
  IUnusedFeedResponseType,
} from '@/types/inventory'

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINT, AUTH_TOKEN_KEY } from '@/constants'
import { endpoints } from '@/api/constants'
import { IPaginatedRes } from '@/types'
import { generatePath, generateQueryParams, getStorageValue } from '@/utils'

export const inventoryApi = createApi({
  reducerPath: 'inventoryApi',
  tagTypes: ['Inventory', 'OrganizationFileUrl'],
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    prepareHeaders: headers => {
      const token = getStorageValue(AUTH_TOKEN_KEY)
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: builder => ({
    getInventories: builder.query<
      IPaginatedRes<IInventory>,
      Partial<IInventoryFilter>
    >({
      query: query => {
        const params = generateQueryParams(query)
        return {
          url: endpoints.inventoryAdmin + params,
        }
      },
    }),
    getUnusedFeeds: builder.query<
      IResponse<IUnusedFeedResponseType>,
      undefined
    >({
      query: () => {
        return {
          url: endpoints.unusedFeeds,
        }
      },
      transformResponse: (response: IResponse<IUnusedFeedResponseType>) => {
        if (response.data && typeof response.data === 'object') {
          response.data = Object.keys(response.data)
            .sort()
            .reduce((sortedObj, key) => {
              sortedObj[key as InventoryType] =
                response.data[key as InventoryType]
              return sortedObj
            }, {} as IUnusedFeedResponseType)
        }
        return response
      },
    }),
    getDealerFile: builder.query<
      IResponse<{ url: string }>,
      { dealer?: string; vendor?: InventoryType } | null
    >({
      query: query => {
        return {
          url: generatePath(endpoints.inventoryDealerFile, query || {}),
        }
      },
    }),
    deleteInventory: builder.mutation<
      ISuccessResponse,
      { inventoryId: string }
    >({
      query: query => {
        return {
          url: generatePath(endpoints.inventoryDelete, query),
          method: 'POST',
        }
      },
    }),
    createInventory: builder.mutation<ISuccessResponse, IInventoryFormPayload>({
      query: query => {
        return {
          url: endpoints.inventoryAdminCreate,
          method: 'POST',
          body: query,
        }
      },
    }),
    updateInventory: builder.mutation<ISuccessResponse, IInventoryFormPayload>({
      query: ({ id, ...query }) => {
        return {
          url: generatePath(endpoints.inventoryAdminUpdate, { id }),
          method: 'POST',
          body: query,
        }
      },
    }),
    syncInventory: builder.query<ISuccessResponse, { organizationId: string }>({
      query: ({ organizationId }) => {
        return {
          url: generatePath(endpoints.inventorySync, { organizationId }),
        }
      },
    }),
    getImportJobs: builder.query<IResponse<IImportJob[]>, { id?: string }>({
      query: ({ id }) => {
        return {
          url: generatePath(endpoints.inventoryImportJobs, { id }),
        }
      },
    }),
    getOrganizationFiles: builder.query<
      IResponse<IInventoryFile[]>,
      { organizationId?: string; dealer?: string }
    >({
      query: ({ organizationId, dealer }) => {
        return {
          url: generatePath(endpoints.inventoryOrganizationFiles, {
            organizationId,
            dealer,
          }),
        }
      },
    }),
    getOrganizationFileUrl: builder.query<
      IResponse<{ url: string }>,
      { fileName: string; vendor: string }
    >({
      query: ({ fileName, vendor }) => {
        return {
          url: generatePath(endpoints.inventoryOrganizationFileUrl, {
            fileName,
            vendor,
          }),
        }
      },
      providesTags: ['OrganizationFileUrl'],
    }),
    getInventoryEvents: builder.query<
      IPaginatedRes<IInventoryEvent>,
      { id?: string } & IPageMeta
    >({
      query: query => {
        const params = generateQueryParams(query)
        return {
          url: endpoints.inventoryEvent + params,
        }
      },
    }),
    inventorySearch: builder.query<
      IPaginatedRes<IUnionInventory>,
      {
        vendor: InventoryType
        organization: string
        query: string
      } & IPageMeta
    >({
      query: query => {
        const params = generateQueryParams(query)
        return {
          url: endpoints.superhumanInventorySearch + params,
        }
      },
    }),
    toggleStatus: builder.mutation<
      ISuccessResponse,
      { status: InventoryStatus; id: string }
    >({
      query: ({ id, status }) => {
        return {
          url: generatePath(endpoints.inventoryAdminToggle, { id }),
          method: 'POST',
          body: { status },
        }
      },
    }),
    updatePriceColumn: builder.mutation<
      ISuccessResponse,
      {
        touse_new_price_col?: string
        touse_used_price_col?: string
        id: string
      }
    >({
      query: ({ id, touse_new_price_col, touse_used_price_col }) => {
        return {
          url: generatePath(endpoints.inventoryAdminPriceColumn, { id }),
          method: 'POST',
          body: { touse_used_price_col, touse_new_price_col },
        }
      },
    }),
  }),
})

export const {
  useGetInventoriesQuery,
  useGetUnusedFeedsQuery,
  useGetDealerFileQuery,
  useDeleteInventoryMutation,
  useCreateInventoryMutation,
  useUpdateInventoryMutation,
  useLazySyncInventoryQuery,
  useGetImportJobsQuery,
  useLazyGetOrganizationFileUrlQuery,
  useGetOrganizationFilesQuery,
  useGetInventoryEventsQuery,
  useLazyInventorySearchQuery,
  useToggleStatusMutation,
  useUpdatePriceColumnMutation,
} = inventoryApi
