import { createApi } from '@reduxjs/toolkit/query/react'

import { endpoints } from '@/api/constants'

import { createBaseQuery } from '@/utils'
import { IUser } from '@/types'

export const usersApi = createApi({
  reducerPath: 'usersApi',
  tagTypes: ['Users'],
  baseQuery: createBaseQuery(),
  endpoints: builder => ({
    getActiveUsers: builder.query<IUser[], undefined>({
      query: () => {
        return {
          url: endpoints.activeUserList,
        }
      },
    }),
  }),
})

export const { useGetActiveUsersQuery } = usersApi
