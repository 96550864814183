import React, { useEffect, useMemo, useState } from 'react'
import PageHeading from '@/sections/PageHeading'
import ErrorTrackerFilter from '@/pages/ErrorTracker/Filter'
import Tab from '@/components/Tab'
import { Checkbox, DataTable, Typography } from '@/components'
import CustomLineChart from '@/sections/LineChart'
import Card from '@/components/Card'
import { errorTrackerColumns } from '@/pages/ErrorTracker/config'
import { ReactComponent as InfoIcon } from '@/assets/icons/Info.svg'
import Tooltip from '@/components/Tooltip'
import {
  useGetErrorCodeListQuery,
  useGetErrorCodeStatsQuery,
} from '@/api/services/error-codes'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import Pagination from '@/components/Pagination'
import { onFilterChange } from '@/store/slices/pages/error-tracker.slice'
import { errorCodes, errorCodeColors } from '@/constants'
import { IErrorTrackerWithStats, TErrorCodes } from '@/types'
import TaskList from '@/pages/Analytics/TaskList'
import ErrorCodeTable from '@/pages/Analytics/ErrorCodeTable'
import { generateDateRange } from '@/utils'

const mergeDataWithDateRange = (
  dateRange: string[],
  data: IErrorTrackerWithStats[],
) => {
  return dateRange.map(date => {
    const dayData = data.find(item => item.date === date)
    if (dayData) {
      return dayData
    } else {
      return { date, 1001: 0 }
    }
  })
}

const ErrorTracker = () => {
  const dispatch = useAppDispatch()
  const {
    filter: { organization, crm, startDate, endDate, page, includedErrorCodes },
  } = useAppSelector(state => state.errorTracker)
  const [componentType, setComponentType] = useState('graph')

  const { data } = useGetErrorCodeStatsQuery({
    organization,
    crm,
    startDate,
    endDate,
    includedErrorCodes,
  })
  const {
    data: { data: errorCodeList = [], pageMeta: { totalItems = 0 } = {} } = {},
    isFetching: loading,
  } = useGetErrorCodeListQuery({
    page,
    pageSize: 10,
    organization,
    crm,
    startDate,
    endDate,
    includedErrorCodes,
  })

  const generatedData = useMemo(() => {
    if (data) {
      const dateRanges = generateDateRange(startDate, endDate)
      return mergeDataWithDateRange(dateRanges, data)
    }
    return []
  }, [data, endDate, startDate])

  const onPageChange = (page: number) => {
    dispatch(
      onFilterChange({
        page,
      }),
    )
  }

  const errorCodeKeys = Object.keys(errorCodes)

  const isAllSelected = includedErrorCodes.length === errorCodeKeys.length

  return (
    <div className="w-full flex flex-col gap-6 grow">
      <PageHeading title="Error Tracker" />
      <div className="flex flex-col gap-4 grow">
        <ErrorTrackerFilter />
        <Tab
          items={[
            { _id: 'graph', name: 'Graph' },
            { _id: 'list', name: 'List' },
            { _id: 'count', name: 'Count' },
          ]}
          active={componentType}
          onChange={setComponentType}
        />
        <div className="grid grid-cols-12 grow gap-6">
          <div className="flex flex-col gap-4 py-6 col-span-2">
            <Checkbox
              label="Select All"
              checked={isAllSelected}
              onChange={() => {
                dispatch(
                  onFilterChange({
                    includedErrorCodes: isAllSelected ? [] : errorCodeKeys,
                    page,
                  }),
                )
              }}
            />
            {errorCodeKeys.map(code => (
              <div className="flex items-center gap-2" key={code}>
                <Checkbox
                  label={`#${code}`}
                  checked={includedErrorCodes.includes(code)}
                  onChange={() => {
                    dispatch(
                      onFilterChange({
                        includedErrorCodes: includedErrorCodes.includes(code)
                          ? includedErrorCodes.filter(c => c !== code)
                          : [...includedErrorCodes, code],
                        page,
                      }),
                    )
                  }}
                />
                <Tooltip content={errorCodes[code as TErrorCodes]}>
                  <InfoIcon className="text-black-50" />
                </Tooltip>
              </div>
            ))}
          </div>
          {componentType === 'list' && (
            <div className="w-full flex flex-col gap-6 col-span-8">
              <DataTable
                columns={errorTrackerColumns}
                data={errorCodeList}
                loading={loading}
              />
              <Pagination
                currentPage={page}
                totalCount={totalItems}
                onPageChange={onPageChange}
              />
            </div>
          )}
          {componentType === 'graph' && (
            <Card className="grow col-span-8 flex flex-col gap-4">
              <CustomLineChart
                data={generatedData as IErrorTrackerWithStats[]}
              />
              <div className="flex gap-8 justify-center">
                {includedErrorCodes.map(code => (
                  <Typography
                    variant="small"
                    key={code}
                    className="flex items-center gap-2 text-black-80"
                    as="div"
                  >
                    <div
                      className="w-2 h-2 rounded-full"
                      style={{
                        backgroundColor: errorCodeColors[code as TErrorCodes],
                      }}
                    />
                    <span>#{code}</span>
                  </Typography>
                ))}
              </div>
            </Card>
          )}
          {componentType === 'count' && (
            <div className="w-full flex flex-col gap-6 col-span-8">
              <ErrorCodeTable />
            </div>
          )}
          <div className="col-span-2 h-full">
            <TaskList />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorTracker
