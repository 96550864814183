import React, { useCallback, useEffect, useState } from 'react'
import TextArea from '@/components/TextArea'
import { Button, Input, Tag, Typography } from '@/components'

import DeliverabilityScoreCard from '@/pages/SuccessPortal/Broadcasting/ContentModal/DeliverabilityScore'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onStateChange } from '@/store/slices/pages/successPortal/broadcasting.slice'
import Papa from 'papaparse'

interface MessageSectionProps {
  isAI?: boolean
}

const requiredTags = ['first_name', 'last_name']
const notRequiredTags = ['vehicle_year', 'vehicle_make', 'vehicle_model']

const tags = [...requiredTags, ...notRequiredTags]
const additionalTags = ['organization_name', 'location_name']

const MessageSection = ({ isAI }: MessageSectionProps) => {
  const dispatch = useAppDispatch()
  const { message, errors, segments, mapColumns } = useAppSelector(
    state => state.broadcasting,
  )
  const [idea, setIdea] = useState('')
  const [generatedIdea, setGeneratedIdea] = useState('')

  const [availableTags, setAvailableTags] = useState<Record<string, boolean>>(
    mapColumns
      .filter(el => el.column)
      .filter(el => tags.includes(el.key))
      .reduce(
        (acc, tag) => {
          acc[tag.key] = true
          return acc
        },
        {} as Record<string, boolean>,
      ),
  )

  const textareaRef = React.useRef<HTMLTextAreaElement>(null)
  const onTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(onStateChange({ message: e.target.value, errors: {} }))
    const height = e.target.scrollHeight
    e.target.style.height = height + 'px'
  }

  useEffect(() => {
    if (isAI) {
      const generatedMessage = generatedIdea || ''
      dispatch(onStateChange({ message: generatedMessage }))
    }
  }, [dispatch, generatedIdea, isAI])

  useEffect(() => {
    const searchTags = Object.keys(availableTags).filter(tag =>
      notRequiredTags.includes(tag),
    )
    const tagAvailable = searchTags.reduce(
      (acc, tag) => {
        acc[tag] = true
        return acc
      },
      {} as Record<string, boolean>,
    )
    const needToSearch = () => {
      return Object.keys(tagAvailable).filter(tag => tagAvailable[tag])
    }

    let currentIndex = 0
    const fetchFileAndCheckTags = async () => {
      while (needToSearch().length && currentIndex < segments.length) {
        const fileFields = mapColumns.filter(
          col => col.column && needToSearch().includes(col.key),
        )
        const response = await fetch(segments[currentIndex].signed_url)
        const data = await response.text()
        currentIndex++
        Papa.parse(data, {
          complete: (result: { data: any[] }) => {
            const data = result.data.splice(-1)
            fileFields.forEach(field => {
              const exists = data.every(row => {
                return field.column && row[field.column]
              })
              if (!exists) {
                tagAvailable[field.key] = false
              }
            })
          },
          header: true,
        })
      }
    }
    fetchFileAndCheckTags().then(() => {
      setAvailableTags(prev => ({ ...prev, ...tagAvailable }))
    })
  }, [mapColumns, segments])

  const tagList = Object.keys(availableTags).filter(tag => availableTags[tag])

  const onAddMessage = useCallback(
    (field: string) => () => {
      const currentMessage = message || ''
      const cursorPosition = textareaRef?.current?.selectionStart
      const newMessage = `${currentMessage.slice(
        0,
        cursorPosition,
      )}{{${field}}}${currentMessage.slice(cursorPosition)}`
      dispatch(onStateChange({ message: newMessage }))
    },
    [dispatch, message],
  )

  return (
    <>
      <div className="flex flex-col gap-3">
        <Typography variant="regular" weight="semibold">
          Choose the dynamic content to be applied to the text:
        </Typography>
        <div className="flex flex-wrap gap-2">
          {[...tagList, ...additionalTags].map(tag => {
            const status = message.includes(`{{${tag}}}`)
              ? 'default'
              : 'transparent'
            return (
              <Tag
                key={tag}
                status={status}
                onClick={onAddMessage(tag)}
              >{`{{${tag}}}`}</Tag>
            )
          })}
        </div>
      </div>
      {isAI && (
        <div className="flex gap-2 w-full">
          <div className="flex flex-col gap-3 w-full">
            <Input
              value={idea}
              onChange={e => setIdea(e.target.value)}
              placeholder="Write your idea..."
            />
            {generatedIdea && (
              <Typography variant="small" className="text-black-50">
                If the broadcast message contains more than 160 symbols, it will
                be sent in several SMS messages to the customers
              </Typography>
            )}
          </div>
          <Button
            status="primary"
            size="small"
            onClick={() =>
              setGeneratedIdea(
                "Hello {{customer_last_name}}, seeking a sleek ride? Check out our latest cars at {{organization_name}} dealership. We'd love to assist you. Want to book a slot this week? Reply 'YES' for appointments.\n",
              )
            }
          >
            Generate
          </Button>
        </div>
      )}
      {(!isAI || (isAI && generatedIdea)) && (
        <div className="flex flex-col gap-2 relative">
          <div className="relative">
            <TextArea
              ref={textareaRef}
              wrapperClass="min-h-32"
              placeholder="Type your message here..."
              value={message}
              onChange={onTextareaChange}
              style={{ minHeight: 144 }}
              error={errors.message}
            />
          </div>
          <div className="flex justify-between">
            <Typography variant="small" className="text-black-50">
              {message.length}/160
            </Typography>
          </div>
          {message.length > 160 && (
            <DeliverabilityScoreCard onClose={() => console.log('asdasd')} />
          )}
        </div>
      )}
    </>
  )
}

export default MessageSection
