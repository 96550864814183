import React, { useCallback, useEffect } from 'react'
import Papa from 'papaparse'
import * as XLSX from 'xlsx'

import { Button, Drawer, DrawerSize, Input, Typography } from '@/components'
import { useDropzone } from 'react-dropzone'
import { ReactComponent as UploadIcon } from '@/assets/icons/Upload.svg'
import { ReactComponent as XIcon } from '@/assets/icons/Close.svg'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  onStateChange,
  onStepChange,
} from '@/store/slices/pages/successPortal/broadcasting.slice'
import clsx from 'clsx'
import moment from 'moment-timezone'
import { DATE_FORMAT_FULL } from '@/constants'

const UploaderModal = () => {
  const dispatch = useAppDispatch()
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
  const { uploadStep, uploadedData, name, rows_per_segment, file } =
    useAppSelector(state => state.broadcasting)

  const { getRootProps, fileRejections, acceptedFiles } = useDropzone({
    accept: {
      'text/csv': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/vnd.ms-excel': [],
    },
  })

  const onDataChange = useCallback(
    (data: any[]) => {
      dispatch(onStateChange({ uploadedData: data }))
    },
    [dispatch],
  )

  const handleFile = useCallback(
    (file: File) => {
      const fileExtension = file.name.split('.').pop()?.toLowerCase()

      if (fileExtension === 'csv') {
        Papa.parse(file, {
          complete: (result: { data: any[] }) => {
            onDataChange(result.data)
          },
          header: true,
        })
      } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
        const reader = new FileReader()
        reader.onload = () => {
          const data = reader.result
          const workbook = XLSX.read(data, { type: 'binary' })
          const sheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[sheetName]
          const jsonData = XLSX.utils.sheet_to_json(worksheet)
          onDataChange(jsonData)
        }
        reader.readAsBinaryString(file)
      }
      setErrors(prev => {
        const errorList = { ...prev }
        delete errorList['file']
        return errorList
      })
      dispatch(onStateChange({ file }))
    },
    [dispatch, onDataChange],
  )

  const handlePaste = (e: React.ClipboardEvent) => {
    const clipboardData =
      e.clipboardData.getData('text/html') ||
      e.clipboardData.getData('text/plain')
    if (!clipboardData) return
    if (clipboardData.startsWith('<table')) {
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = clipboardData

      const table = tempDiv.querySelector('table')
      if (table) {
        const worksheet = XLSX.utils.table_to_sheet(table)
        const jsonData = XLSX.utils.sheet_to_json(worksheet)
        onDataChange(jsonData)
      }
    } else {
      Papa.parse(clipboardData, {
        complete: (result: { data: any[] }) => {
          onDataChange(result.data)
        },
        header: true,
      })
    }
    setErrors(prev => {
      const errorList = { ...prev }
      delete errorList['file']
      return errorList
    })
  }

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      handleFile(file)
    }
  }, [acceptedFiles, dispatch, handleFile])

  const onModalChange = useCallback(
    (val?: number) => {
      dispatch(onStepChange(val))
    },
    [dispatch],
  )

  const onNameChange = useCallback(
    (value: string) => {
      dispatch(onStateChange({ name: value }))
      setErrors(prev => {
        const errorList = { ...prev }
        delete errorList['name']
        return errorList
      })
    },
    [dispatch],
  )

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(Number(e.target.value)) && e.target.value) return
    setErrors(prev => {
      const errorList = { ...prev }
      delete errorList['rows_per_segment']
      return errorList
    })
    dispatch(onStateChange({ rows_per_segment: e.target.value }))
  }

  const onNext = () => {
    const errorList: { [key: string]: string } = {}
    if (!name) {
      errorList['name'] = 'Broadcast name is required'
    }
    if (!rows_per_segment) {
      errorList['rows_per_segment'] = 'Rows per segment is required'
    }
    if (Number(rows_per_segment) > 1000) {
      errorList['rows_per_segment'] =
        'Rows per segment can not be more that 1000'
    }
    if (!uploadedData.length) {
      errorList['file'] = 'File is required'
    }
    if (Object.values(errorList).length) {
      setErrors(errorList)
      return
    } else {
      onModalChange(1)
    }
  }

  return (
    <Drawer
      open={uploadStep === 0}
      onClose={onModalChange}
      title="Load File"
      size={DrawerSize.XL}
    >
      <div
        className="flex flex-col gap-4 items-center grow"
        onPaste={handlePaste}
      >
        <div className="flex flex-col gap-2 w-full">
          <Input
            label="Broadcast Name"
            value={name}
            onChange={e => onNameChange(e.target.value)}
            error={errors.name}
            placeholder="Enter broadcast name"
          />
          {errors.name && (
            <Typography variant="small" className="text-red-100">
              {errors.name}
            </Typography>
          )}
        </div>
        <div className="flex flex-col gap-2 w-full">
          <Input
            label="Rows per segment"
            type="number"
            value={rows_per_segment}
            onChange={onInputChange}
            error={errors.rows_per_segment}
            placeholder="Max 1000 rows"
          />
          {errors.rows_per_segment && (
            <Typography variant="small" className="text-red-100">
              {errors.rows_per_segment}
            </Typography>
          )}
        </div>

        {file ? (
          <div className="flex justify-between items-center gap-3 p-4 rounded-2xl border border-bg-stroke shadow-modal w-full mt-6">
            <div className="flex flex-col gap-1 w-full">
              <Typography variant="regular" weight="medium">
                {file.name}
              </Typography>
              <Typography variant="small" className="text-black-50">
                Last modified:{' '}
                {moment(file.lastModified).format(DATE_FORMAT_FULL)}
              </Typography>
            </div>
            <div className="flex items-center gap-5">
              <Button
                status="secondary"
                size="small"
                onClick={() => {
                  const url = file ? URL.createObjectURL(file) : ''
                  window.open(url, '_blank')
                }}
              >
                Download
              </Button>
              <XIcon
                className="cursor-pointer"
                onClick={() => {
                  dispatch(onStateChange({ file: undefined, uploadedData: [] }))
                }}
              />
            </div>
          </div>
        ) : uploadedData.length > 0 ? (
          <Typography variant="small" className="text-black-50 text-center">
            You have pasted {uploadedData.length} rows of data
          </Typography>
        ) : (
          <>
            <label
              {...getRootProps({ className: 'dropzone' })}
              className={clsx(
                'w-full py-20 border border-dashed bg-bg-gray cursor-pointer mt-2 flex flex-col items-center rounded-xl px-6',
                errors.file ? 'border-red-100' : 'border-bg-stroke',
              )}
            >
              <div className="flex flex-col gap-4 items-center ">
                <UploadIcon className="mx-auto text-[#ADAFB4]" />
                <div className="flex flex-col gap-1">
                  <Typography variant="regular" weight="medium">
                    Drop files here or click to upload
                  </Typography>
                  <Typography
                    variant="small"
                    className="text-black-50 text-center"
                  >
                    CSV, XLSX or XLS files accepted
                  </Typography>
                </div>
                {fileRejections.length > 0 && (
                  <div className="py-2">
                    <Typography variant="small" className="text-red-500">
                      Please select a valid file, then try again.
                    </Typography>
                  </div>
                )}
              </div>
            </label>
            <Typography
              variant="regular"
              weight="semibold"
              className="text-blue-100 cursor-pointer"
            >
              Or paste here table data
            </Typography>
          </>
        )}

        <Button
          status="primary"
          size="large"
          className="mt-auto ml-auto"
          onClick={onNext}
        >
          Next
        </Button>
      </div>
    </Drawer>
  )
}

export default UploaderModal
