import {
  IBaseListItem,
  IOrganizationSettingsResponse,
  IResponse,
  ISingOrganizationType,
  IUser,
  permissionType,
} from '@/types'
import { endpoints } from '@/api/constants'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINT, AUTH_TOKEN_KEY } from '@/constants'
import { generatePath, getStorageValue } from '@/utils'

export const organizationApi = createApi({
  reducerPath: 'organizationApi',
  tagTypes: ['Organizations'],
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    prepareHeaders: headers => {
      const token = getStorageValue(AUTH_TOKEN_KEY)
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: builder => ({
    getSingleOrganizations: builder.query<
      ISingOrganizationType,
      { id?: string }
    >({
      query: query => {
        return {
          url: endpoints.singleOrganization,
          method: 'POST',
          body: {
            id: query.id,
          },
        }
      },
    }),
    getOrganizationSettings: builder.query<
      IResponse<IOrganizationSettingsResponse>,
      { organization: string }
    >({
      query: ({ organization }) => {
        return {
          url: generatePath(endpoints.organizationSettings, { organization }),
        }
      },
    }),
    getUsersByPermission: builder.query<
      { users: IUser[] },
      { permissions: permissionType[]; organization: string }
    >({
      query: ({ permissions, organization }) => {
        return {
          url: generatePath(endpoints.usersByPermissions, { organization }),
          method: 'POST',
          body: {
            permissions,
          },
        }
      },
    }),
    assignUsers: builder.mutation<
      IResponse<IOrganizationSettingsResponse>,
      { assignedUsers: string[]; organization: string }
    >({
      query: ({ assignedUsers, organization }) => {
        return {
          url: generatePath(endpoints.organizationAssignUsers, {
            organization,
          }),
          method: 'POST',
          body: {
            assignedUsers,
          },
        }
      },
    }),
    checkV2Access: builder.query<{ hasAccess: boolean }, { id?: string }>({
      query: ({ id }) => {
        return {
          url: generatePath(endpoints.hasAccess, {
            id,
          }),
        }
      },
    }),
    organizationLocations: builder.query<
      { locations: IBaseListItem[] },
      { id: string }
    >({
      query: ({ id }) => {
        return {
          url: generatePath(endpoints.organizationLocations, {
            organization: id,
          }),
        }
      },
    }),
  }),
})

export const {
  useGetSingleOrganizationsQuery,
  useGetUsersByPermissionQuery,
  useGetOrganizationSettingsQuery,
  useAssignUsersMutation,
  useCheckV2AccessQuery,
  useOrganizationLocationsQuery,
} = organizationApi
