import React from 'react'
import { useGetEventImageQuery } from '@/api/services/organization/organization-automation'
import { LoadingSkeleton, Typography } from '@/components'
import { rem } from '@/utils'

const ImageView = ({ s3Path }: { s3Path?: string }) => {
  const { data, isFetching } = useGetEventImageQuery(
    { s3Path },
    { skip: !s3Path },
  )
  return (
    <div className="py-4 flex flex-col gap-3">
      <Typography variant="regular" weight="medium">
        Screenshot of the Browser instance at the time of failure
      </Typography>
      {isFetching ? (
        <LoadingSkeleton height={rem(400)} width="75%" />
      ) : (
        <img alt="" className="w-3/4" src={`data:image/png;base64, ${data}`} />
      )}
    </div>
  )
}

export default ImageView
