import React, { useCallback, useEffect } from 'react'

import { ReactComponent as DocumentationIcon } from '@/assets/icons/Documentation.svg'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { Button, Select, Toggle, Typography } from '@/components'
import {
  clearAll,
  onStateChange,
  onStepChange,
} from '@/store/slices/pages/successPortal/broadcasting.slice'
import UploaderModal from '@/pages/SuccessPortal/Broadcasting/FileUpload/UploaderModal'
import MapColumnsModal from '@/pages/SuccessPortal/Broadcasting/FileUpload/MapColumnsModal'
import VerifyDataModal from '@/pages/SuccessPortal/Broadcasting/FileUpload/VerifyDataModal'
import CampaignModal from 'src/pages/SuccessPortal/Broadcasting/CampaignModal'
import BroadcastingContentModal from '@/pages/SuccessPortal/Broadcasting/ContentModal'
import BroadcastingSchedulerModal from '@/pages/SuccessPortal/Broadcasting/SchedulerModal'
import { useGetOrganizationsQuery } from '@/api/services/organization-list'
import UnfinishedChangesModal from '@/pages/SuccessPortal/Broadcasting/FileUpload/UnfinishedChangesModal'
import { ReactComponent as XIcon } from '@/assets/icons/Close.svg'
import moment from 'moment-timezone'
import { DATE_FORMAT_FULL } from '@/constants'

const Broadcasting = () => {
  const dispatch = useAppDispatch()
  const { exclude_archived, exclude_dnc, organization, broadcasting_id, file } =
    useAppSelector(state => state.broadcasting)
  const { data: organizations = [] } = useGetOrganizationsQuery(undefined)
  const broadcastId = localStorage.getItem('broadcasting_id')

  const [showUnfinishedChanges, setShowUnfinishedChanges] =
    React.useState(false)

  useEffect(() => {
    if (broadcastId && !broadcasting_id) {
      setTimeout(() => setShowUnfinishedChanges(true), 3000)
    }
  }, [broadcastId, broadcasting_id])

  const onModalChange = useCallback(
    (val?: number) => {
      dispatch(onStepChange(val))
    },
    [dispatch],
  )

  const onToggle = useCallback(
    (target: string) => (value: boolean) => {
      dispatch(onStateChange({ [target]: value }))
    },
    [dispatch],
  )

  const onSelectChange = useCallback(
    (value?: string) => {
      dispatch(onStateChange({ organization: value }))
    },
    [dispatch],
  )

  return (
    <div className="pt-2 w-full max-w-[912px] mx-auto flex flex-col gap-6">
      <Typography variant="H3" weight="semibold">
        Upload CSV or Excel File
      </Typography>
      {!file ? (
        !broadcasting_id && (
          <div
            className="pt-[52px] pb-[73px] px-10 rounded-xl border border-dashed border-bg-stroke bg-bg-gray flex flex-col items-center cursor-pointer"
            onClick={() => dispatch(onStepChange(0))}
          >
            <DocumentationIcon />
            <Typography variant="regular" weight="medium" className="mt-4 mb-1">
              Click here to upload your
            </Typography>
            <Typography variant="small" className="text-black-50">
              CSV or Excel file
            </Typography>
          </div>
        )
      ) : (
        <div className="flex justify-between items-center gap-3 p-4 rounded-2xl border border-bg-stroke shadow-modal">
          <div className="flex flex-col gap-1 w-full">
            <Typography variant="regular" weight="medium">
              {file.name}
            </Typography>
            <Typography variant="small" className="text-black-50">
              Last modified:{' '}
              {moment(file.lastModified).format(DATE_FORMAT_FULL)}
            </Typography>
          </div>
          <div className="flex items-center gap-5">
            <Button
              status="secondary"
              size="small"
              onClick={() => {
                const url = file ? URL.createObjectURL(file) : ''
                window.open(url, '_blank')
              }}
            >
              Download
            </Button>
            <XIcon
              className="cursor-pointer"
              onClick={() => {
                dispatch(onStateChange({ file: undefined, uploadedData: [] }))
              }}
            />
          </div>
        </div>
      )}
      <Select
        placeholder="Select Organization"
        onChange={val => onSelectChange(val?._id)}
        value={organizations.find(el => el._id === organization)}
        options={organizations}
      />
      <div className="flex flex-col gap-1">
        <div className="py-2 flex items-center justify-between gap-6">
          <Typography variant="regular" weight="medium">
            Exclude customers in the "Archived" folder from broadcast
          </Typography>
          <Toggle
            checked={exclude_archived}
            onChange={onToggle('exclude_archived')}
          />
        </div>
        <div className="py-2 flex items-center justify-between gap-6">
          <Typography variant="regular" weight="medium">
            Exclude DNC numbers from the broadcast list
          </Typography>
          <Toggle checked={exclude_dnc} onChange={onToggle('exclude_dnc')} />
        </div>
      </div>
      <div className="flex gap-2 mt-5">
        <Button
          status="secondary"
          size="large"
          onClick={() => {
            dispatch(clearAll())
            localStorage.removeItem('broadcasting_id')
          }}
        >
          Cancel
        </Button>
        <Button
          status="primary"
          size="large"
          onClick={() => onModalChange(4)}
          disabled={!organization}
        >
          Apply
        </Button>
      </div>
      <UploaderModal />
      <MapColumnsModal />
      <VerifyDataModal />
      <CampaignModal />
      <BroadcastingContentModal />
      <BroadcastingSchedulerModal />
      <UnfinishedChangesModal
        open={showUnfinishedChanges}
        onClose={() => setShowUnfinishedChanges(false)}
      />
    </div>
  )
}

export default Broadcasting
