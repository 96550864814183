import React from 'react'
import { Drawer, DrawerSize } from '@/components'
import { JSONTree } from 'react-json-tree'

interface StatusModalProps {
  open: boolean
  onClose: () => void
  data: object
}
const StatusModal = ({ open, onClose, data }: StatusModalProps) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title="Status Information"
      size={DrawerSize.MD}
    >
      <div className="p-2 rounde rounded-2xl bg-[#002b36]">
        <JSONTree
          data={data}
          hideRoot
          shouldExpandNodeInitially={(keyPath, data, level) => {
            return level < 2
          }}
        />
      </div>
    </Drawer>
  )
}

export default StatusModal
