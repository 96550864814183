import { EventActionType, IDateFilterType, IPageMeta } from '@/types'
import { AutomationStatus } from '@/types/automation'
import { Option } from '@/components'

export * from './navigation'
export * from './error-codes'

export const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT
export const GOOGLE_AUTH_CLIENT_ID = import.meta.env.VITE_GOOGLE_AUTH_CLIENT_ID
export const INTERNAL_API_ENDPOINT = import.meta.env.VITE_INTERNAL_API_ENDPOINT
export const SUPPORT_TOOLING_API_BASE_URL = import.meta.env
  .VITE_SUPPORT_TOOLING_API_BASE_URL
export const AUTH_TOKEN_KEY = '@authToken'

export const DATE_FORMAT = 'MM/DD/YYYY, h:mm A'
export const DATE_FORMAT_FULL = 'dddd, MMM Do YYYY, HH:mm A'

export const defaultPageMeta: IPageMeta = {
  page: 1,
  pageSize: 10,
}

export const automationStatusOptions = Object.values(AutomationStatus).map(
  status => ({
    _id: status,
    name: status.charAt(0).toUpperCase() + status.slice(1),
  }),
)

export const automationTypeOptions = [
  { _id: 'automation', name: 'Automation Tasks' },
  { _id: 'appointment', name: 'Appointment Tasks' },
  { _id: 'lead', name: 'Lead Tasks' },
  { _id: 'aibot', name: 'AI Bot Tasks' },
  { _id: 'workflow', name: 'Workflows' },
]

export const dateFilterOptions: Option<IDateFilterType>[] = [
  { _id: 'today', name: 'Today' },
  { _id: 'yesterday', name: 'Yesterday' },
  { _id: 'monthToDate', name: 'Month to day' },
  { _id: 'lastMonth', name: 'Last Month' },
  { _id: 'custom', name: 'Custom date' },
]

export const auditTrailActionOptions: Option<EventActionType>[] = [
  { _id: 'automations.create', name: 'Create Automation' },
  { _id: 'automations.edit', name: 'Edit Automation' },
  { _id: 'automations.delete', name: 'Delete Automation' },
  { _id: 'automations.toggle', name: 'Toggle Automation' },
  { _id: 'automations.retry', name: 'Retry Automation' },
  { _id: 'credentials.create', name: 'Create Credential' },
  { _id: 'credentials.edit', name: 'Edit Credential' },
  { _id: 'credentials.delete', name: 'Delete Credential' },
]
