import React, { useEffect, useRef, useState } from 'react'
import { Transition } from '@headlessui/react'
import { Drawer, Input, Tag, Typography } from '@/components'
import { defaultPageMeta } from '@/constants'
import { useLazyInventorySearchQuery } from '@/api/services/inventory'
import { ReactComponent as SearchIcon } from '@/assets/icons/Search.svg'
import { ReactComponent as LoadingIcon } from '@/assets/icons/Loader.svg'
import {
  IInventoryType,
  InventoryType,
  IUnionInventory,
  VendorInventory,
} from '@/types/inventory'

import noImage from '@/assets/images/image-not-available.png'
import { checkImage, generateInventoryItem } from '@/utils'
import Pagination from '@/components/Pagination'
import useOutsideClick from '@/hooks/useOutsideClick'
import InventoryDetails from '@/sections/InventorySearch/InventoryDetails'

const InventoryItem = ({
  item,
  ...rest
}: {
  item: IUnionInventory
  onClick: () => void
}) => {
  const [realImage, setRealImage] = useState<string | undefined>(
    noImage as string,
  )
  const image = item?.imagelist?.split(',')?.[0] || noImage

  checkImage(image || '').then(res => {
    setRealImage((res ? image : noImage) as string)
  })

  return (
    <div
      className="flex flex-col gap-1 p-2 cursor-pointer hover:bg-gray-80"
      {...rest}
    >
      <div className="flex justify-between gap-2">
        <img
          className="max-w-16 w-full shrink-0"
          src={realImage}
          alt={item.model}
        />
        <div className="flex items-start flex-wrap gap-2 h-full">
          <Typography variant="small">
            {item.make} {item.model},{' '}
            <span className="font-semibold">{item.year}</span>
          </Typography>
          <Typography variant="small" className="text-black-50">
            {item.vin}
          </Typography>
        </div>
        {item?.type && (
          <div className="shrink-0">
            <Tag
              status={
                item.type === 'New' || item.type === 'new'
                  ? 'success'
                  : 'warning'
              }
            >
              {item.type}
            </Tag>
          </div>
        )}
      </div>
    </div>
  )
}

const InventorySearch = ({
  vendor,
  organization,
}: {
  vendor: InventoryType
  organization: string
}) => {
  const [selectedItem, setSelectedItem] = React.useState<
    IUnionInventory | undefined
  >()
  const [pageMeta, setPageMeta] = React.useState(defaultPageMeta)
  const [query, setQuery] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const [
    search,
    {
      data: { data: items = [], pageMeta: { totalItems = 0 } = {} } = {},
      isFetching: loading,
    },
  ] = useLazyInventorySearchQuery()

  useEffect(() => {
    if (query) {
      search({ vendor, organization, query, ...pageMeta })
    }
  }, [organization, pageMeta, query, search, vendor])
  const handleCloseDropdown = () => {
    setOpen(false)
  }

  useOutsideClick(dropdownRef, handleCloseDropdown)

  const onItemClick = (item: IUnionInventory) => {
    const data = generateInventoryItem({ vendor, item } as VendorInventory)
    setSelectedItem(data)
  }

  return (
    <div ref={dropdownRef} className="relative z-10">
      <div className="relative" onClick={() => setOpen(prev => !prev)}>
        <Input
          value={query}
          onChange={e => setQuery(e.target.value)}
          IconLeft={SearchIcon}
        />
        {loading && query && (
          <div className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5">
            <LoadingIcon className="animate-spin text-blue-80" />
          </div>
        )}
      </div>
      <Transition show={open}>
        <div
          className="transition translate-y-full duration-300 ease-in-out data-[closed]:opacity-0 absolute -bottom-2 border border-gray-100 w-full bg-white-100 shadow-lg py-4 px-1 rounded-xl"
          onClick={e => {
            e.stopPropagation()
            setOpen(false)
          }}
        >
          {!items?.length && !loading ? (
            <div className="text-center">
              {query === '' ? 'Search for a vehicle' : 'No results found'}
            </div>
          ) : (
            <div className="flex flex-col gap-2 divide-y divide-gray-100">
              {items?.map(item => (
                <InventoryItem
                  key={item.id}
                  item={item}
                  onClick={() => {
                    onItemClick(item)
                  }}
                />
              ))}
            </div>
          )}
          <div>
            <Pagination
              currentPage={pageMeta.page}
              totalCount={totalItems}
              onPageChange={val => setPageMeta({ ...pageMeta, page: val })}
            />
          </div>
        </div>
      </Transition>
      <Drawer
        open={!!selectedItem}
        onClose={() => setSelectedItem(undefined)}
        title={`${selectedItem?.make} ${selectedItem?.model}, ${selectedItem?.year}`}
      >
        <InventoryDetails item={selectedItem} />
      </Drawer>
    </div>
  )
}

export default InventorySearch
