import React, { useEffect } from 'react'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'

import { useHookForm } from '@/hooks/useHookForm'
import { loginFormSchema } from '@/utils/validation'
import { Button, Input, Typography } from '@/components'
import { SubmitHandler } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { loginUser, loginUserByGoogle } from '@/store/slices/auth.slice'
import { useNavigate } from 'react-router-dom'
import { AUTH_TOKEN_KEY, DASHBOARD } from '@/constants'
import { setStorageValue } from '@/utils'
import * as yup from 'yup'
import Form from '@/components/Form'

import { ReactComponent as LogoDark } from '@/assets/icons/Logo Dark.svg'

export type TLoginFormSchema = yup.InferType<typeof loginFormSchema>
export const SignInPage: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { loading, token, error } = useAppSelector(state => state.auth)

  const {
    handler: {
      getValues,
      handleSubmit,
      register,
      trigger,
      formState: { errors },
    },
  } = useHookForm<{ email: string; password: string }>(loginFormSchema)
  const onSubmit: SubmitHandler<TLoginFormSchema> = async data => {
    dispatch(loginUser(data))
  }

  const responseMessage = async (response: CredentialResponse) => {
    if (!response.credential) return
    dispatch(
      loginUserByGoogle({
        token: response.credential,
      }),
    )
  }

  const errorMessage = () => {
    console.log('error')
  }

  const disabled = !getValues('email') || !getValues('password')

  useEffect(() => {
    if (token && !loading) {
      setStorageValue(AUTH_TOKEN_KEY, token)
      setTimeout(() => {
        navigate(DASHBOARD)
      }, 1000)
    }
  }, [loading, navigate, token])

  return (
    <div className="flex justify-center items-center w-full min-h-screen flex-1 relative">
      <div className="w-full px-10 py-8 absolute top-0">
        <LogoDark className="mx-auto" />
      </div>
      <div className="w-full max-w-sm px-2.5">
        <Typography variant="H3" weight="medium" className="text-center mb-8">
          Login to Automations
        </Typography>

        <div className="mt-8">
          {error && <p className="text-red-100 mb-4">{error}</p>}
          <div>
            <Form
              className="flex flex-col gap-5"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Input
                label="Email"
                placeholder="Please enter your username or email"
                type="email"
                error={errors.email?.message}
                {...register('email', {
                  onChange: () => trigger('email'),
                })}
              />

              <Input
                label="Password"
                placeholder="Password"
                type="password"
                error={errors.password?.message}
                {...register('password', {
                  onChange: () => trigger('password'),
                })}
              />
              <Button
                size="large"
                status="primary"
                disabled={disabled || loading}
                type="submit"
                className="active w-full mt-1 !rounded-[22px]"
              >
                Log in
              </Button>
              <div className="flex items-center gap-2 my-3">
                <span className="h-px bg-[#EAECF0] w-full" />
                <Typography variant="small" weight="medium">
                  OR
                </Typography>
                <span className="h-px bg-[#EAECF0] w-full" />
              </div>
              <GoogleLogin
                onSuccess={responseMessage}
                onError={errorMessage}
                width={360}
                shape="pill"
                logo_alignment="center"
                containerProps={{
                  className: 'w-full',
                }}
              />
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
