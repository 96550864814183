import React from 'react'
import { Tag, Typography } from '@/components'
import MessageSection from '@/pages/SuccessPortal/Broadcasting/ContentModal/MessageSection'

const AIGenerated = () => {
  return (
    <div className="flex flex-col gap-6 max-w-xl w-full">
      <Typography variant="small" className="text-black-50" weight="medium">
        Instantly create your campaign with Al.
      </Typography>
      <div className="flex flex-col gap-3">
        <Typography variant="regular" weight="semibold">
          Automatically used by Al:
        </Typography>
        <div className="flex flex-wrap gap-2">
          <Tag status="transparent">{`{{organization_name}}`}</Tag>
        </div>
      </div>

      <MessageSection isAI />
    </div>
  )
}

export default AIGenerated
