import { Maybe } from 'yup'
const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'

export const renderUserFullName = (firstName?: string, lastName?: string) => {
  if (!firstName && !lastName) {
    return ''
  }
  return `${firstName} ${lastName}`
}

export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const truncateString = (str: string, num: number = 30) => {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

export const joinArray = <T = Maybe<string>>(
  joinWith = ',',
  ...elements: T[]
): string => elements.filter(el => !!el).join(joinWith)

export const makeId = (length: number = 10) => {
  let result = ''
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}
