import React, { useCallback, useMemo } from 'react'
import { Button, DataTable, Drawer, DrawerSize } from '@/components'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  dataHeaders,
  onMapColumnsChange,
  onStepChange,
} from '@/store/slices/pages/successPortal/broadcasting.slice'
import { mapColumnTableColumns } from '@/pages/SuccessPortal/Broadcasting/config'

const MapColumnsModal = () => {
  const dispatch = useAppDispatch()
  const { uploadStep, uploadedData, mapColumns } = useAppSelector(
    state => state.broadcasting,
  )

  const columnsInFile = useAppSelector(dataHeaders)

  const options = useMemo(() => {
    return columnsInFile.map(el => ({
      _id: el,
      name: el,
    }))
  }, [columnsInFile])

  const onModalChange = useCallback(
    (val?: number) => {
      dispatch(onStepChange(val))
    },
    [dispatch],
  )
  const onSelect = (key: string, value?: string) => {
    dispatch(onMapColumnsChange({ key, value }))
  }

  const onNext = useCallback(() => {
    onModalChange(2)
  }, [onModalChange])

  return (
    <Drawer
      open={uploadStep === 1}
      onClose={onModalChange}
      title="Map Columns"
      size={DrawerSize.XL}
    >
      <div className="flex grow">
        <div className="w-3/4">
          <DataTable
            columns={mapColumnTableColumns(options, onSelect)}
            data={mapColumns}
            className="!overflow-visible"
          />
        </div>
        <div className="mt-auto flex gap-2 justify-end w-1/4">
          <Button
            status="secondary"
            size="large"
            onClick={() => onModalChange(0)}
          >
            Previous
          </Button>
          <Button status="primary" size="large" onClick={onNext}>
            Next
          </Button>
        </div>
      </div>
    </Drawer>
  )
}

export default MapColumnsModal
