import React from 'react'
import Modal from '@/components/Modal'
import { Button, Typography } from '@/components'
import { ReactComponent as ErrorIcon } from '@/assets/icons/Error.svg'

interface IReplaceModal {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  invalidDataCount?: number
}

const WarningModal = ({
  open,
  onClose,
  onConfirm,
  invalidDataCount,
}: IReplaceModal) => {
  return (
    <Modal
      showBackdrop={false}
      open={open}
      onClose={onClose}
      title={
        <div className="flex items-center gap-3">
          <ErrorIcon className="w-6 h-6 text-yellow-100" />
          Invalid Data
        </div>
      }
      footer={
        <div className="flex gap-2 justify-end w-full">
          <Button
            status="secondary"
            size="small"
            onClick={() => {
              onClose()
            }}
          >
            Go back
          </Button>
          <Button
            status="primary"
            size="small"
            onClick={() => {
              onConfirm()
            }}
          >
            Confirm upload
          </Button>
        </div>
      }
    >
      <div className="flex flex-col gap-4 text-center">
        <Typography variant="regular" weight="medium">
          <span className="font-semibold">{invalidDataCount}</span> invalid data
          row(s) found.
        </Typography>
        <div>
          <Typography variant="regular" weight="medium">
            All invalid data will be removed from the file.
          </Typography>
          <Typography variant="regular" weight="medium">
            Do you want to proceed with the import?
          </Typography>
        </div>
      </div>
    </Modal>
  )
}

export default WarningModal
