import React, { useCallback, useState } from 'react'
import Modal from '@/components/Modal'
import { Button, Checkbox, Input, Select } from '@/components'
import { broadcastingTemplateFields } from '@/constants/broadcasting'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onStateChange } from '@/store/slices/pages/successPortal/broadcasting.slice'

interface IReplaceModal {
  open: boolean
  onClose: () => void
}

const ReplaceModal = ({ open, onClose }: IReplaceModal) => {
  const dispatch = useAppDispatch()
  const [selectedColumn, setSelectedColumn] = useState<string | undefined>('')
  const [findValue, setFindValue] = useState<string>('')
  const [replaceValue, setReplaceValue] = useState<string>('')
  const [caseSensitive, setCaseSensitive] = useState<boolean>(false)
  const [exactMatch, setExactMatch] = useState<boolean>(false)
  const { uploadedData, mapColumns } = useAppSelector(
    state => state.broadcasting,
  )

  const replaceText = useCallback(
    (text: string) => {
      if (!text) return text

      let regexFlags = 'g'
      if (!caseSensitive) regexFlags += 'i'

      const regex = exactMatch
        ? new RegExp(`\\b${findValue}\\b`, regexFlags)
        : new RegExp(findValue, regexFlags)

      return text.replace(regex, replaceValue)
    },
    [caseSensitive, exactMatch, findValue, replaceValue],
  )

  const onReplace = useCallback(() => {
    if (!findValue) {
      return
    }
    const col = mapColumns.find(el => el.key === selectedColumn)?.column

    const updatedData = uploadedData.map(row => {
      const newRow = { ...row }
      if (col) {
        if (newRow[col]) {
          newRow[col] = replaceText(newRow[col])
        }
      } else {
        Object.keys(newRow).forEach(col => {
          if (typeof newRow[col] === 'string') {
            newRow[col] = replaceText(newRow[col])
          }
        })
      }

      return newRow
    })
    dispatch(onStateChange({ uploadedData: updatedData }))

    onClose()
  }, [
    findValue,
    mapColumns,
    uploadedData,
    dispatch,
    onClose,
    selectedColumn,
    replaceText,
  ])

  const onFind = useCallback(() => {
    if (!findValue) {
      return
    }
    dispatch(onStateChange({ findForm: { column: selectedColumn, findValue } }))
    onClose()
  }, [dispatch, findValue, onClose, selectedColumn])

  return (
    <Modal
      showBackdrop={false}
      open={open}
      onClose={onClose}
      title="Find and Replace"
      footer={
        <div className="flex gap-2 justify-end w-full">
          <Button status="secondary" size="small" onClick={onFind}>
            Find
          </Button>
          <Button
            status="primary"
            size="small"
            onClick={() => {
              onReplace()
            }}
          >
            Replace All
          </Button>
        </div>
      }
    >
      <div className="flex flex-col gap-6">
        <Select
          label="Column Name"
          placeholder="All Columns"
          onChange={val => setSelectedColumn(val?._id)}
          value={broadcastingTemplateFields.find(
            el => el._id === selectedColumn,
          )}
          options={broadcastingTemplateFields}
        />
        <Input
          label="Find"
          value={findValue}
          onChange={e => setFindValue(e.target.value)}
        />
        <div className="my-1 flex gap-3">
          <Checkbox
            label="Case Sensitive"
            checked={caseSensitive}
            onChange={val => setCaseSensitive(val)}
          />
          <Checkbox
            label="Exact Match"
            checked={exactMatch}
            onChange={val => setExactMatch(val)}
          />
        </div>
        <Input
          label="Replace With"
          value={replaceValue}
          onChange={e => setReplaceValue(e.target.value)}
        />
      </div>
    </Modal>
  )
}

export default ReplaceModal
