import React, { useLayoutEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { Button, Typography } from '@/components'

import styles from '../styles.module.scss'
import { renderUserFullName } from '@/utils'
import clsx from 'clsx'
import moment from 'moment-timezone'
import TextArea from '@/components/TextArea'
import {
  cancelJobRequest,
  onMessageChange,
  submitJobRequest,
} from '@/store/slices/superhuman.slice'
import ConfirmModal from '@/sections/ConfirmModal'
import { ISuperhumanJobPayload } from '@/types'

const SuperhumanJobConversation = () => {
  const [approve, setApprove] = React.useState(false)
  const [discard, setDiscard] = React.useState(false)
  const scrollRef = React.createRef<HTMLUListElement>()
  const { job, message, usersToTag, tagMessage, appointment } = useAppSelector(
    state => state.superhuman,
  )
  const dispatch = useAppDispatch()

  useLayoutEffect(() => {
    const width = window.innerWidth
    const section = document.getElementById('info-section')
    if (section && width >= 768) {
      section.scrollIntoView({ behavior: 'smooth' })
      scrollRef.current?.lastElementChild?.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [])

  const cancelJob = () => {
    if (job?.jobId) {
      dispatch(cancelJobRequest({ id: job.jobId }))
        .unwrap()
        .finally(() => {
          setDiscard(false)
        })
    }
  }

  const approveJob = () => {
    if (job?.jobId) {
      const data: Partial<ISuperhumanJobPayload> = {
        id: job.jobId,
        users_to_tag: usersToTag,
        message,
        appointment_time: appointment,
      }
      if (tagMessage) {
        data.tagging_message = tagMessage
      }
      dispatch(submitJobRequest(data))
        .unwrap()
        .finally(() => {
          setApprove(false)
        })
    }
  }

  return (
    <>
      <div
        className={clsx(styles.sectionHeading, 'flex justify-between gap-2')}
      >
        <div>
          {job?.customer && (
            <Typography variant="H3">
              {renderUserFullName(
                job?.customer?.firstName,
                job?.customer?.lastName,
              )}
            </Typography>
          )}
          <Typography variant="small" className="text-black-50 mt-2">
            {job?.location.name}
          </Typography>
        </div>
        <div className="flex gap-2 items-center">
          <Button
            status="secondary"
            size="small"
            onClick={() => setDiscard(true)}
          >
            Discard
          </Button>
          <Button
            status="primary"
            size="small"
            onClick={() => setApprove(true)}
          >
            Approve
          </Button>
        </div>
      </div>

      <div className={styles.sectionContent}>
        <ul className="list-unstyled" ref={scrollRef}>
          {job?.messages
            ?.filter(message => message.body)
            ?.map(message => {
              return (
                <li key={message._id}>
                  {message?.type === 'received' ? (
                    <div className="flex">
                      <div
                        className="media-body bg-white-100 border border-dark p-3"
                        style={{
                          maxWidth: '80%',
                          borderRadius: '1rem',
                          margin: '10px',
                        }}
                      >
                        <h5 className="mt-0 mb-1">{message?.from}</h5>
                        {message?.body}
                      </div>
                    </div>
                  ) : (
                    <div className="flex justify-end">
                      <div
                        className="media-body text-end bg-blue-80 text-white-100 border border-dark p-3"
                        style={{
                          maxWidth: '80%',
                          borderRadius: '1rem',
                          margin: '10px',
                        }}
                      >
                        <h6 className="mt-0 mb-1">{message?.from}</h6>
                        {message?.body}
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      fontSize: '13px',
                      color: 'gray',
                      textAlign: message.type === 'received' ? 'left' : 'right',
                      marginTop: -5,
                      marginRight: 15,
                      marginLeft: 15,
                      marginBottom: 10,
                    }}
                  >
                    {moment(message?.createdAt)
                      .tz(job?.timezone)
                      .format('DD/MM/YYYY hh:mm A')}{' '}
                    {message?.phoneNumber ? (
                      <span> - {message?.phoneNumber}</span>
                    ) : null}
                  </div>
                </li>
              )
            })}
        </ul>
      </div>
      <div className="py-4 px-6 border-t border-gray-100 sticky bottom-0 bg-gray-80">
        <TextArea
          label="AI Genrated Message"
          placeholder="Type your message here"
          value={message}
          onChange={e => dispatch(onMessageChange(e.target.value))}
        />
      </div>
      <ConfirmModal
        title="Discard Job"
        onClose={() => setDiscard(false)}
        open={discard}
        isLoading={false}
        content="You are going to discard job, are you sure?"
        onConfirm={cancelJob}
      />
      <ConfirmModal
        title="Approve Job"
        onClose={() => setApprove(false)}
        open={approve}
        isLoading={false}
        content="Are you sure to approve job?"
        onConfirm={approveJob}
      />
    </>
  )
}

export default SuperhumanJobConversation
