import { AutomationStatus, TagStatus } from '@/types'
import { Option } from '@/components'
import { RQBJsonLogic } from 'react-querybuilder'
import { generateTimeOptions, getOrdinalDay } from '@/utils'

export const statuses: Record<
  AutomationStatus,
  {
    color: TagStatus
    text: string
  }
> = {
  [AutomationStatus.COMPLETED]: {
    color: 'success',
    text: 'Completed',
  },
  [AutomationStatus.FAILED]: {
    color: 'error',
    text: 'Failed',
  },
  [AutomationStatus.PENDING]: {
    color: 'warning',
    text: 'Pending',
  },
}

export const soldOptions: Option[] = [
  ...new Array(500).fill(0).map((_, i) => ({
    _id: i.toString(),
    name: `${i}`,
  })),
  ...new Array(500).fill(0).map((_, idx) => ({
    _id: (-idx - 1).toString(),
    name: `${idx + 1} (in the future)`,
  })),
]

export const options: Option[] = [
  {
    name: 'days',
    _id: 'days',
  },
  {
    name: 'months',
    _id: 'months',
  },
]

export const frequencyDefaultState = {
  days: [1],
  weekdays: [],
  increment: '',
  time: {
    hour: '',
    minute: '',
    isoTimezone: undefined,
  },
}

export const defaultWeekdays = {
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: false,
  Friday: false,
  Saturday: false,
  Sunday: false,
}

export const hourOptions: Option[] = Array(...new Array(24)).map((_, i) => ({
  _id: (i + 1).toString(),
  name: `${i + 1}`,
  disabled: false,
}))

export const minuteOptions: Option[] = Array(...new Array(12)).map((_, i) => ({
  _id: (i * 5).toString(),
  name: `${i * 5 < 10 ? '0' + i * 5 : i * 5}`,
  disabled: false,
}))

export const reviewInviteUrlTag = '{{review_invite_url}}'

export const defaultCombinator: RQBJsonLogic = {
  and: [{ '==': [{}, ''] }],
}

export const initialQuery = { combinator: 'and', rules: [] }
export const dynamicFields = [
  'customer_first_name',
  'customer_last_name',
  'vehicle_year',
  'vehicle_make',
  'vehicle_model',
  'vehicle_vin',
  'vehicle_odometer',
  'service_advisor_id',
  'service_advisor_first_name',
  'service_advisor_last_name',
  'price_amount',
  'opcode',
  'opcode_description',
  'technician_id',
  'technician_first_name',
  'technician_last_name',
  'repair_order_id',
  'location_name',
  'organization_name',
]

export const unitOptions = [
  { _id: 'minutes', name: 'Minutes' },
  { _id: 'hours', name: 'Hours' },
  { _id: 'days', name: 'Days' },
  { _id: 'weeks', name: 'Weeks' },
  { _id: 'months', name: 'Months' },
]

export const timeOptions = generateTimeOptions()

export const generateMonthDayOptions = () => {
  const options = []
  for (let i = 1; i <= 30; i++) {
    options.push({
      _id: i.toString(),
      name: getOrdinalDay(i),
    })
  }
  return options
}
console.log(generateMonthDayOptions())
