import React, { useEffect } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { PageLoadingWrapper } from '@/components'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { getUser } from '@/store/slices/user.slice'
import { AUTH_TOKEN_KEY, SIGN_IN } from '@/constants'
import { getStorageValue, removeStorageValue } from '@/utils'
import { clear } from '@/store/slices/auth.slice'

const AuthWrapper = () => {
  const navigate = useNavigate()
  const { isLoading, isLoggedIn } = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()
  const token = getStorageValue(AUTH_TOKEN_KEY)

  useEffect(() => {
    if (token) {
      dispatch(getUser())
    } else {
      dispatch(clear())
      removeStorageValue(AUTH_TOKEN_KEY)
      navigate(SIGN_IN)
    }
  }, [dispatch, token])

  if (isLoading) {
    return <PageLoadingWrapper isLoading />
  }

  if (!isLoading && !isLoggedIn) {
    return <Navigate to={SIGN_IN} />
  }

  return <Outlet />
}

export default AuthWrapper
