import { IUnionInventory, VendorInventory } from '@/types/inventory'
import { IAuditTrail } from '@/types'
import moment from 'moment-timezone'

const stripUnit = (value: number): number => {
  if (!isNaN(value) && Number.isNaN(value)) {
    return value / (value * 0 + 1)
  }
  return value
}

export const rem = (value: number | string, fontSize = 16): string => {
  if (isNaN(+value)) return value.toString()
  return `${stripUnit(+value) / stripUnit(fontSize)}rem`
}

export const generateInventoryItem = ({
  vendor,
  item,
}: VendorInventory): IUnionInventory => {
  let data = {}
  if (vendor === 'homenet') {
    const {
      'dealer name': dealername,
      'dealer address': dealeraddress,
      'dealer city': dealercity,
      'dealer state': dealerstate,
      'dealer zip': dealerzip,
      'dealer email': dealeremail,
      'dealer phone': dealerphone,
      wheelbase_code: wheelbasecode,
      engine_description: enginedescription,
      engine_aspiration_type: engineaspirationtype,
      fuel_type: fueltype,
      transmission_speed: transmissionspeed,
      transmission_description: transmissiondescription,
      ext_color_generic: extcolorgeneric,
      ...rest
    } = item
    data = {
      ...rest,
      dealername,
      dealeraddress,
      dealercity,
      dealerstate,
      dealerzip,
      dealeremail,
      dealerphone,
      wheelbasecode,
      enginedescription,
      engineaspirationtype,
      fueltype,
      transmissionspeed,
      transmissiondescription,
      extcolorgeneric,
    }
    return <IUnionInventory>data
  }
  if (vendor === 'sm360') {
    const { passengercount: passengercapacity, ...rest } = item
    data = {
      ...rest,
      passengercapacity,
    }
    return <IUnionInventory>data
  } else {
    data = item
  }

  return <IUnionInventory>data
}

export const generateEventReadableText = (event: IAuditTrail): string => {
  const user = event.user
  const name = event.automation?.name || event.credential?.location.name
  const time = moment(event.createdAt).format('HH:mm A')
  const [target, action] = event.type.split('.')
  const actionTarget = target === 'automations' ? 'automation' : 'credential'
  const text = `${user?.firstName} {{action}} ${name} ${actionTarget} at ${time}`
  const toggleText = event.payload.active ? 'on' : 'off'

  switch (action) {
    case 'create':
      return text.replace('{{action}}', 'created')
    case 'edit':
      return text.replace('{{action}}', 'updated')
    case 'delete':
      return text.replace('{{action}}', 'deleted')
    case 'toggle':
      return text.replace('{{action}}', `turned ${toggleText}`)
    case 'retry':
      return text.replace('{{action}}', 'retried')
    default:
      return 'performed an action'
  }
}
