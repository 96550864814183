import React from 'react'
import Modal from '@/components/Modal'
import { Button, Typography } from '@/components'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { useLazyGetBroadcastingByIdQuery } from '@/api/services/success-portal/broadcasting'
import { onStateChange } from '@/store/slices/pages/successPortal/broadcasting.slice'
import { mapColumns } from '@/constants/broadcasting'
import toast from 'react-hot-toast'

interface IReplaceModal {
  open: boolean
  onClose: () => void
}

const UnfinishedChangesModal = ({ open, onClose }: IReplaceModal) => {
  const dispatch = useAppDispatch()
  const { broadcasting_id } = useAppSelector(state => state.broadcasting)

  const [getBroadcasting, { isFetching }] = useLazyGetBroadcastingByIdQuery()
  const broadcastId = localStorage.getItem('broadcasting_id')

  const onConfirm = () => {
    if (broadcastId && !broadcasting_id) {
      getBroadcasting({ id: broadcastId })
        .unwrap()
        .then(res => {
          if (res) {
            const columns = JSON.parse(res.column_mapping)
            const response = {
              ...res,
              broadcasting_id: broadcastId,
              mapColumns: mapColumns.map(column => {
                return {
                  ...column,
                  column: columns[column.key] || '',
                }
              }),
            }
            dispatch(onStateChange(response))
            onClose()
          }
        })
        .catch(() => {
          toast.error('Failed to get broadcasting')
        })
    }
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Confirmation"
      footer={
        <div className="mt-auto w-full flex gap-2 justify-end">
          <Button
            status="secondary"
            size="large"
            onClick={() => {
              onClose()
              localStorage.removeItem('broadcasting_id')
            }}
          >
            Cancel
          </Button>
          <Button
            status="primary"
            size="large"
            onClick={() => onConfirm()}
            loading={isFetching}
          >
            Continue
          </Button>
        </div>
      }
    >
      <div className="flex flex-col gap-3 items-center">
        <Typography variant="medium" weight="medium">
          You have unfinished changes.
        </Typography>
        <Typography variant="medium" weight="medium">
          Would you like to continue?
        </Typography>
      </div>
    </Modal>
  )
}

export default UnfinishedChangesModal
