import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { TIcon } from '@/types'
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/16/solid'
import { Typography } from '@/components'

export type IAlertType = 'success' | 'error' | 'warning' | 'info'

export interface IAlertProps {
  type: IAlertType
  message: string | ReactNode
}

const alertTypeClasses: Record<IAlertType, string> = {
  success: 'bg-green-50 text-green-100 border-green-100',
  error: 'bg-red-50 text-red-100 border-red-100',
  warning: 'bg-yellow-50 text-amber-600 border-yellow-100',
  info: 'bg-blue-20 text-blue-100 border-blue-100',
}

const alertTypeIcons: Record<IAlertType, TIcon> = {
  success: CheckCircleIcon,
  error: XCircleIcon,
  warning: ExclamationTriangleIcon,
  info: InformationCircleIcon,
}

export const Alert = ({ type = 'info', message }: IAlertProps) => {
  const Icon = alertTypeIcons[type]
  return (
    <div
      className={clsx(
        'border-l-4 p-4 w-full rounded-e-md flex items-center gap-4',
        alertTypeClasses[type],
      )}
    >
      <Icon className="size-6 shrink-0" />
      <Typography variant="regular" weight="medium">
        {message}
      </Typography>
    </div>
  )
}
