import { createSlice } from '@reduxjs/toolkit'

import { IAuditTrailState } from '@/types'
import { defaultPageMeta } from '@/constants'
import moment from 'moment-timezone'

const initialState: IAuditTrailState = {
  filter: {
    ...defaultPageMeta,
    organizationIds: [],
    userIds: [],
    actions: [],
    startDate: moment().subtract(7, 'd').toString(),
    endDate: moment().endOf('day').toString(),
  },
}

const auditTrailSlice = createSlice({
  name: 'broadcastSlice',
  initialState,
  reducers: {
    onFilterChange: (state, action) => {
      state.filter = { ...state.filter, ...action.payload }
    },
  },
})

export const { onFilterChange } = auditTrailSlice.actions
export default auditTrailSlice.reducer
