import React from 'react'
import { IInventory, InventoryStatus } from '@/types/inventory'
import { Toggle } from '@/components'
import toast from 'react-hot-toast'
import { useToggleStatusMutation } from '@/api/services/inventory'

const InventoryTableToggle = ({
  inventory,
  refetch,
}: {
  inventory: IInventory
  refetch: () => void
}) => {
  const [toggle] = useToggleStatusMutation()
  const [checked, setChecked] = React.useState(inventory.status === 'active')

  return (
    <Toggle
      checked={checked}
      onChange={() => {
        setChecked(!checked)
        toggle({
          id: inventory.id,
          status:
            inventory.status === 'active'
              ? InventoryStatus.INACTIVE
              : InventoryStatus.ACTIVE,
        }).then(() => {
          toast.success('Inventory status updated successfully')
          refetch()
        })
      }}
    />
  )
}

export default InventoryTableToggle
