import { Option } from '@/components'
import { TBroadcastTemplateField } from '@/types'

export const broadcastingTemplateFields: Option<TBroadcastTemplateField>[] = [
  { name: 'Phone Number', _id: 'phone_number' },
  { name: 'First Name', _id: 'first_name' },
  { name: 'Last Name', _id: 'last_name' },
  { name: 'Email', _id: 'email' },
  { name: 'Model', _id: 'vehicle_model' },
  { name: 'Year', _id: 'vehicle_year' },
  { name: 'Make', _id: 'vehicle_make' },
]

export const mapColumns = broadcastingTemplateFields.map(el => ({
  templateField: el.name,
  key: el._id,
  column: '',
}))

export const broadcastingDefaultForm = {
  phone_number: undefined,
  first_name: '',
  last_name: '',
  email: '',
  vehicle_make: '',
  vehicle_model: '',
  vehicle_year: undefined,
}
