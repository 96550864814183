import React from 'react'
import { IRawPayload } from '@/types'
import { Button, Drawer, DrawerSize, Typography } from '@/components'
import { JSONTree } from 'react-json-tree'

interface RawPayloadModalProps {
  data?: IRawPayload
}
const RawPayloadModal = ({ data }: RawPayloadModalProps) => {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <div>
      <Button status="primary" size="small" onClick={() => setIsOpen(true)}>
        View Payload
      </Button>
      <Drawer
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="Payload"
        size={DrawerSize.MD}
      >
        <div>
          {data ? (
            <div className="p-2 rounde rounded-2xl bg-[#002b36]">
              <JSONTree
                data={data}
                hideRoot
                shouldExpandNodeInitially={(keyPath, data, level) => {
                  return level < 2
                }}
              />
            </div>
          ) : (
            <Typography variant="H3">No data available</Typography>
          )}
        </div>
      </Drawer>
    </div>
  )
}

export default RawPayloadModal
