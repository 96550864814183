import React from 'react'

interface HighlightTextProps {
  text: string
  search: string
  isActive: boolean
}

export const HighlightText = ({
  text,
  search,
  isActive,
}: HighlightTextProps) => {
  if (!text) return null
  if (!search || !isActive) return <span>{text}</span>

  const regex = new RegExp(`(${search})`, 'gi')
  const parts = text.split(regex)

  return (
    <span className="flex">
      {parts.map((part, index) =>
        regex.test(part) ? (
          <span key={index} className="bg-yellow-300">
            {part}
          </span>
        ) : (
          part
        ),
      )}
    </span>
  )
}
