import React, { useCallback, useRef } from 'react'
import {
  Input,
  Option,
  Radio,
  Select,
  Tag,
  TextArea,
  Toggle,
  Typography,
} from '@/components'
import { IRule, TTag } from '@/types'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  defaultState,
  onFormChange,
} from '@/store/slices/pages/organization/organization-automations.slice'
import { useGetSingleOrganizationsQuery } from '@/api/services/organization'
import { useParams } from 'react-router-dom'
import { useGetWorkflowUsersQuery } from '@/api/services/workflow'
import {
  options,
  reviewInviteUrlTag,
  soldOptions,
} from '@/constants/automations'
import { useGetLocationCredentialsQuery } from '@/api/services/location'
import WorkflowSelect from '@/pages/Organization/AutomationForm/Rules/WorkflowSelect'
import DealerSocketUUID from '@/pages/Organization/AutomationForm/Rules/DealerSocketUUID'
import Activix from '@/pages/Organization/AutomationForm/Rules/Activix'

const AutomationFormRules = ({ rule }: { rule?: IRule }) => {
  const {
    form: {
      locationId,
      ruleId,
      assignedUsers,
      meta,
      isReviewInvite,
      message,
      name,
    },
    formErrors: errors,
  } = useAppSelector(state => state.organizationAutomations)
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const { data: organization } = useGetSingleOrganizationsQuery({ id })
  const { data: { users = [] } = {} } = useGetWorkflowUsersQuery({
    organization: id,
  })
  const { data: { pbsDealStatusCache = [] } = {} } =
    useGetLocationCredentialsQuery(
      { id: locationId },
      {
        skip: !locationId,
      },
    )
  const locations = organization?.locations || []

  const locationCredentials = organization?.credentials?.find(
    _v => _v.location === locationId,
  )
  const sortedRules = (
    organization?.rules ? [...organization.rules] : []
  )?.sort((a, b) => a.name.localeCompare(b.name))

  const filteredRules = sortedRules
    ?.filter(_v => _v.crm === locationCredentials?.crm)
    .map(_v => ({
      _id: _v._id,
      name: _v.viewName,
    }))

  const onChange = (key: string) => (value: any) => {
    const isReset = key === 'locationId'
    if (key === 'isReviewInvite') {
      dispatch(
        onFormChange({
          message: value ? reviewInviteUrlTag : '',
          isReviewInvite: value,
        }),
      )
      return
    }
    dispatch(
      onFormChange(
        isReset ? { ...defaultState, [key]: value } : { [key]: value },
      ),
    )
  }

  const onChangeMeta = (field: string) => (value: any) => {
    const fields = { ...meta }
    if (!value && fields[field]) {
      delete fields[field]
    }
    dispatch(onFormChange({ meta: { ...fields, [field]: value } }))
  }

  const onAddMessage = useCallback(
    (field: string) => () => {
      const currentMessage = message || ''
      const cursorPosition = textareaRef?.current?.selectionStart
      const newMessage = `${currentMessage.slice(
        0,
        cursorPosition,
      )}{{${field}}}${currentMessage.slice(cursorPosition)}`
      // @ts-ignore
      dispatch(onFormChange({ message: newMessage }))
    },
    [dispatch, message],
  )

  const inputLabel =
    rule?.type === 'automation'
      ? 'Broadcast Name'
      : rule?.type === 'workflow'
        ? 'Workflow Name'
        : undefined

  const usersList = users.map(user => ({
    _id: user._id,
    name: `${user.firstName} ${user.lastName}`,
  }))

  const eleadOptions =
    locationCredentials?.meta?.statuses?.map(option => ({
      _id: option.trim(),
      name: option.trim(),
    })) || []

  const pbsDealStatusCacheOptions =
    pbsDealStatusCache.map(option => ({
      _id: option,
      name: option,
    })) || []

  const getSelectValue = (options: string[], fieldName: string) => {
    const data = options?.find(el => el === meta?.[fieldName])
    return data ? { _id: data, name: `${data}` } : undefined
  }

  const multiSelectValue = (options: Option[], fieldName: string) => {
    return options.filter(option => {
      let values = []
      if (typeof meta[fieldName] === 'string' && meta[fieldName]) {
        const decodedString = decodeURIComponent(meta[fieldName])

        values = JSON.parse(decodedString).map((val: string) => val.trim())
      } else {
        values = meta[fieldName]
      }
      return values?.includes(option._id)
    })
  }

  const multiselectOptions = (options: string[]) => {
    return options.map(option => ({
      _id: option,
      name: option,
    }))
  }

  return (
    <div className="lg:w-1/2 flex flex-col gap-3">
      <Select
        placeholder="Select"
        label="Select a location"
        value={locations?.find(_v => _v._id === locationId)}
        onChange={option => onChange('locationId')(option?._id)}
        options={
          locations?.map(_v => ({
            _id: _v._id,
            name: _v.name,
          })) || []
        }
      />

      {!!locationCredentials && (
        <Select
          placeholder="Select"
          value={filteredRules?.find(_v => _v._id === ruleId)}
          className="mt-2 block w-full"
          label={`Select a report type (CRM: ${locationCredentials.crm})`}
          onChange={option => onChange('ruleId')(option?._id)}
          options={filteredRules || []}
        />
      )}

      {rule?.type === 'automation' && (
        <React.Fragment>
          <Select
            isSearchable
            placeholder="Select users"
            isMulti
            label="Assign users to the automated campaign's conversations."
            options={usersList}
            onChange={val => {
              onChange('assignedUsers')(val.map(_v => _v._id))
            }}
            value={usersList.filter(option => {
              let values: string[] | undefined = []
              if (typeof assignedUsers === 'string') {
                const decodedString = decodeURIComponent(assignedUsers)

                values = JSON.parse(decodedString)
              } else {
                values = assignedUsers
              }
              return values?.includes(option._id)
            })}
          />
        </React.Fragment>
      )}

      {rule?.customFields?.map((field, idx) => (
        <React.Fragment key={idx}>
          {field?.tag === TTag.TEXTAREA && (
            <TextArea
              rows={3}
              label={field?.viewName}
              aria-label="With textarea"
              onChange={e => onChangeMeta(field.name)(e.target.value)}
              // @ts-ignore
              value={meta?.[field.name]}
            />
          )}
          {field?.tag === TTag.INPUT && (
            <Input
              type={field.type}
              label={field?.viewName}
              className="block w-full mt-2"
              onChange={e => onChangeMeta(field.name)(e.target.value)}
              // @ts-ignore
              value={meta?.[field.name]}
            />
          )}
          {field?.tag === TTag.DEALERSOCKET_UUID && (
            <DealerSocketUUID
              label={field?.viewName}
              onChange={onChangeMeta(field.name)}
              // @ts-ignore
              value={meta?.[field.name]}
            />
          )}
          {field?.tag === TTag.WORKFLOW_SELECT && (
            <WorkflowSelect
              label={field?.viewName}
              onChange={onChangeMeta(field.name)}
              locationId={locationId}
              // @ts-ignore
              value={meta?.[field.name]}
            />
          )}
          {field?.tag === TTag.INPUT_NOT_REQUIRED && (
            <Input
              type={field.type}
              className="mt-2 block"
              label={field?.viewName}
              onChange={e => onChangeMeta(field.name)(e.target.value)}
              // @ts-ignore
              value={meta?.[field.name]}
            />
          )}

          {field?.tag === TTag.SELECT && (
            <>
              <Select
                label={field?.viewName}
                options={(field?.options || []).map(option => ({
                  _id: option,
                  name: `${option}`,
                }))}
                className="mt-2 block w-full"
                value={getSelectValue(field?.options || [], field.name)}
                onChange={_v => onChangeMeta(field.name)(_v?._id)}
              />
              {(field?.name === 'vehicule_make' ||
                field?.name === 'make_name') && (
                <div>
                  <Typography
                    className="text-black-80 mb-1"
                    variant="small"
                    weight="medium"
                  >
                    New/Used:
                  </Typography>
                  <Radio
                    className="flex gap-6"
                    options={[
                      {
                        label: 'Either',
                        value: 'E',
                      },
                      {
                        label: 'New',
                        value: 'N',
                      },
                      {
                        label: 'Used',
                        value: 'U',
                      },
                    ]}
                    value={meta?.is_used || 'E'}
                    onChange={onChangeMeta('is_used')}
                  />
                </div>
              )}
            </>
          )}
          {field?.tag === TTag.SELECT_V2 && (
            <Select
              label={field?.viewName}
              options={(field?.options || []).map(option => ({
                name: option?.name,
                _id: option?.value,
              }))}
              className="w-full block"
              value={field?.options?.find(
                el => el.value === meta?.[field.name],
              )}
              onChange={_v => onChangeMeta(field.name)(_v?._id)}
            />
          )}
          {field?.tag === TTag.START_END_SOLD_ELEAD && (
            <React.Fragment>
              <h6 className="text-sm font-medium my-2">{field?.viewName}</h6>
              <div className="flex gap-5 w-full">
                <div className="flex items-end gap-2">
                  <Select
                    label="Start offset"
                    options={soldOptions}
                    className="w-1/2 block"
                    value={soldOptions.find(
                      el => el._id === meta?.[field.name],
                    )}
                    onChange={_v => onChangeMeta(field.name)(_v?._id)}
                  />
                  <Select
                    options={options}
                    className="w-1/2 block"
                    value={options.find(el => el._id === meta?.[field.name])}
                    onChange={_v => onChangeMeta(field.name)(_v?._id)}
                  />
                </div>
                <div className="flex items-end gap-2">
                  <Select
                    label="End offset"
                    options={soldOptions}
                    className="w-1/2 block"
                    value={soldOptions.find(
                      el => el._id === meta?.[field.name],
                    )}
                    onChange={_v => onChangeMeta(field.name)(_v?._id)}
                  />
                  <Select
                    options={options}
                    className="w-1/2 block"
                    value={options.find(el => el._id === meta?.[field.name])}
                    onChange={_v => onChangeMeta(field.name)(_v?._id)}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
          {field?.tag === TTag.MULTIPLE_SELECT && (
            <React.Fragment>
              <h6 className="text-sm font-medium my-2">{field?.viewName}</h6>
              <Select
                isMulti
                options={multiselectOptions(field?.options || [])}
                onChange={selectedList => {
                  const list = selectedList
                    .map(option => option._id)
                    .filter(Boolean)
                  if (list.length) {
                    onChangeMeta(field.name)(encodeURI(JSON.stringify(list)))
                  } else {
                    onChangeMeta(field.name)('')
                  }
                }}
                value={multiSelectValue(
                  multiselectOptions(field?.options || []),
                  field.name,
                )}
                placeholder="Select..."
              />
            </React.Fragment>
          )}
          {field?.tag === TTag.ELEAD_OPTIONS && (
            <React.Fragment>
              <h6 className="text-sm font-medium my-2">{field?.viewName}</h6>
              <Select
                isMulti
                options={eleadOptions}
                onChange={selectedList => {
                  const list = selectedList
                    .map(option => option._id)
                    .filter(Boolean)
                  if (list.length) {
                    onChangeMeta(field.name)(encodeURI(JSON.stringify(list)))
                  } else {
                    onChangeMeta(field.name)('')
                  }
                }}
                value={multiSelectValue(eleadOptions, field.name)}
                placeholder="Select..."
              />
            </React.Fragment>
          )}
          {field?.tag === TTag.PBS_MULTISELECT && (
            <React.Fragment>
              <Select
                label={field?.viewName}
                isMulti
                options={pbsDealStatusCacheOptions}
                onChange={selectedList => {
                  const list = selectedList
                    .map(option => option._id)
                    .filter(Boolean)
                  if (list.length) {
                    onChangeMeta(field.name)(list)
                  } else {
                    onChangeMeta(field.name)('')
                  }
                }}
                value={multiSelectValue(pbsDealStatusCacheOptions, field.name)}
                placeholder="Select..."
              />
            </React.Fragment>
          )}
          {field?.tag === TTag.ACTIVIX_FILTER && (
            <Activix
              field={field}
              onChange={onChangeMeta(field.name)}
              current={meta?.[field.name]}
            />
          )}
        </React.Fragment>
      ))}

      {inputLabel && (
        <Input
          label={inputLabel}
          value={name}
          className="mt-2 w-full block"
          onChange={e => onChange('name')(e.target.value)}
        />
      )}

      {(rule?.type === 'automation' || rule?.type === 'resurrection') &&
        !isReviewInvite && (
          <React.Fragment>
            <TextArea
              rows={5}
              label="Message"
              aria-label="With textarea"
              className="w-full mt-2 block"
              value={message}
              onChange={e => onChange('message')(e.target.value)}
              ref={textareaRef}
              error={errors?.message}
            />
            <p className="block text-xs font-normal leading-5 mt-2 text-gray-500">
              Available dynamic fields
            </p>
            <div className="flex flex-wrap gap-2 mt-1">
              {rule?.dynamicFields?.map((field, idx) => {
                return (
                  <Tag
                    status="transparent"
                    key={idx}
                    onClick={onAddMessage(field)}
                  >
                    {field}
                  </Tag>
                )
              })}
            </div>
          </React.Fragment>
        )}
      {(rule?.type === 'automation' || rule?.type === 'resurrection') && (
        <div className="mt-4 flex gap-2 items-center">
          <Toggle
            checked={isReviewInvite}
            onChange={_v => onChange('isReviewInvite')(_v)}
          ></Toggle>
          <Typography variant="regular" weight="medium">
            Use the location's review invite script ?
          </Typography>
        </div>
      )}
    </div>
  )
}

export default AutomationFormRules
