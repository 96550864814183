import { InventoryStatus } from '@/types/inventory'

export const inventoryStatusOptions = Object.values(InventoryStatus).map(
  status => ({
    _id: status,
    name: status.charAt(0).toUpperCase() + status.slice(1),
  }),
)

export const vendors = [
  { name: 'Homenet', _id: 'homenet' },
  { name: 'vAuto', _id: 'vauto' },
  { name: 'SM360', _id: 'sm360' },
  { name: 'Dealersync', _id: 'dealersync' },
  { name: 'Serti', _id: 'serti' },
  { name: 'CarsForSales', _id: 'carsforsales' },
  { name: 'DealerSpike', _id: 'dealerspike' },
  { name: 'Edealer', _id: 'edealer' },
  { name: 'Vincue', _id: 'vincue' },
  { name: 'AutoSweet', _id: 'autosweet' },
  { name: 'Dealers Cloud', _id: 'dealerscloud' },
  { name: 'Dealers Link', _id: 'dealerslink' },
  { name: 'PBS', _id: 'pbs' },
  { name: 'Magnetis', _id: 'magnetis' },
].sort((a, b) => a.name.localeCompare(b.name))

export const formDefaultState = {
  id: '',
  organization_id: '',
  vendor: '',
  status: InventoryStatus.INACTIVE,
  dealer_id: '',
  domain_url: [''],
}
