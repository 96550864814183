import React, { useCallback } from 'react'
import PageHeading from '@/sections/PageHeading'
import AiBotFilter from './Filter'
import { DataTable } from '@/components'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onFilterChange } from '@/store/slices/pages/ai-bot.slice'
import Pagination from '@/components/Pagination'
import { aiBotTableColumns } from '@/pages/AiBotCustomers/config'
import { useGetAiBotCustomersQuery } from '@/api/services/organization/ai-bot'
import StatusModal from '@/pages/AiBotCustomers/StatusModal'

const AiBotCustomers = () => {
  const dispatch = useAppDispatch()
  const { filter } = useAppSelector(state => state.aiBot)

  const [statusData, setStatusData] = React.useState<object>({})

  const {
    data: { data: aiBotCustomers = [], pageMeta: { totalItems = 0 } = {} } = {},
    isFetching: loading,
  } = useGetAiBotCustomersQuery({ ...filter })

  const onPageChange = (page: number) => {
    dispatch(
      onFilterChange({
        page,
      }),
    )
  }

  const onStatusView = useCallback((data: object) => {
    setStatusData(data)
  }, [])

  return (
    <div className="w-full flex flex-col gap-6">
      <PageHeading title="AI Bot Customers" />
      <AiBotFilter />
      <DataTable
        loading={loading}
        columns={aiBotTableColumns(onStatusView)}
        data={aiBotCustomers}
      />
      <Pagination
        currentPage={filter.page}
        totalCount={totalItems}
        onPageChange={onPageChange}
      />
      <StatusModal
        open={Object.keys(statusData).length > 0}
        onClose={() => setStatusData({})}
        data={statusData}
      />
    </div>
  )
}

export default AiBotCustomers
