export const endpoints = {
  auth: 'v1/auth/login',
  googleAuth: 'v1/auth/google/login',
  checkAuthStatus: 'v1/auth/ping',
  allTask: 'v1/tasks/all',
  stats: 'v0/stats/dashboard',
  notifications: 'v0/notifications',
  organizations: 'v0/organizations',
  acknowledged: 'v0/tasks/acknowledged',
  locations: 'v1/tasks/locations',
  crms: 'v1/tasks/crms',
  eventImagePath: 'v0/tasks/event/image',
  automationsRetry: 'v0/automations/retry',
  allAppointments: 'v1/appointments/all',
  allLeads: 'v1/leads/all',
  leadsStats: 'v1/leads/stats',
  allAIBotCustomers: 'v1/aibots/all',
  aiBotStats: 'v1/aibots/stats',
  singleOrganization: 'v0/organizations/one',
  automations: 'v0/automations',
  hasAccess: 'v1/workflows/:id/hasAccess',
  organizationTasks: 'v0/tasks/org',
  organizationAppointments: 'v0/appointments',
  organizationLeads: 'v0/leads',
  organizationsCredentials: 'v0/organizations/credentials/:id',
  organizationWorkflows: 'v1/matador/workflows/:id',
  deleteAutomation: 'v0/automations/delete',
  toggleAutomation: 'v0/automations/toggle',
  getLocationCredentials: 'v0/organizations/credentials/:id',
  getWorkflow: 'v1/matador/workflows/:id',
  uploadImage: 'v1/media/upload',
  addAutomation: 'v0/automations/add',
  editAutomation: 'v0/automations/edit',
  credentialsMutation: 'v0/organizations/credentials',
  revealPassword: 'v0/organizations/credentials/:id/reveal',
  crmSettings: 'v0/organizations/crmSettings',
  organizationSettings: 'v0/organizations/:organization/settings',
  accessControlList: 'v1/auth/access-control/list',
  accessControl: 'v1/auth/access-control',
  automationPingTest: 'v0/automations/pingTest',
  organizationWorkflow: 'v1/workflows/:id',
  organizationWorkflowAll: 'v1/workflows/:id/all',
  organizationWorkflowLocations: 'v1/workflows/:id/locations',
  toggleWorkflow: 'v1/workflows/:locationId/:workflowId/toggle',
  deleteWorkflow: 'v1/workflows/:locationId/:workflowId',
  workflowTemplates: 'v1/workflows/:id/templates',
  workflow: 'v1/workflows/one/:id',
  workflowUsers: 'v1/workflows/:organization/users',
  usersByPermissions: 'v0/organizations/:organization/usersByPermissions',
  organizationAssignUsers: 'v0/organizations/:organization/assign-users',
  getSampleResults: 'v1/workflows/:organization/repair-orders/sample',
  editWorkflow: 'v1/workflows/:locationId/:workflowId',
  createWorkflow: 'v1/workflows/:locationId',
  repairOrders: 'v1/repairorders',
  repairOrdersUsedWorkflowsCount: 'v1/repairorders/workflows-used',
  permissions: 'v1/auth/permissions',
  aiJobsQueueLength: 'v1/ai-jobs/queue-length',
  aiJobsRequest: 'v1/ai-jobs/request',
  aiJobsJob: 'v1/ai-jobs/job/:id',
  aiJobCancel: 'v1/ai-jobs/:id/cancel',
  aiJobSubmit: 'v1/ai-jobs/:id/submit',
  aiJobAppointmentCancel: 'v1/ai-jobs/:id/appointment/:appointment/cancel',
  aiJobAppointmentEdit: 'v1/ai-jobs/:id/appointment/:appointment/edit',
  lockedLocations: 'v0/locations/locked',
  lockLocation: 'v0/locations/:locationId/lock',
  unlockLocation: 'v0/locations/:locationId/unlock',
  superhumanInventorySearch: 'v1/ai-jobs/inventory-search',
  inventoryAdmin: 'v1/inventory/admin/all',
  inventoryAdminToggle: 'v1/inventory/admin/:id/toggle',
  inventoryAdminPriceColumn: 'v1/inventory/admin/:id/update-price-column',
  inventoryAdminCreate: 'v1/inventory/admin/create',
  inventoryAdminUpdate: 'v1/inventory/admin/:id/update',
  inventoryImportJobs: 'v1/inventory/admin/:id/import-jobs',
  inventorySync: 'v1/inventory/admin/:organization/sync',
  unusedFeeds: 'v1/inventory/unused-feeds',
  inventoryOrganizationAccess: 'v1/inventory/:organization/check-access',
  userSettings: 'v1/users/settings',
  inventoryDelete: 'v1/inventory/admin/:inventoryId/delete',
  inventoryOrganizationFiles: 'v1/inventory/files/:organizationId/:dealer',
  inventoryOrganizationFileUrl: 'v1/inventory/files/:vendor/download/:fileName',
  inventoryDealerFile: 'v1/inventory/:vendor/:dealer/file',
  inventoryEvent: 'v1/inventory/events',
  serviceAppointments: 'v1/service-appointments',
  errorCodesStats: 'v1/error-codes/stats',
  errorCodesList: 'v1/error-codes/all',
  errorCodesCount: 'v1/error-codes/count',
  salesMetrics: 'v1/metrics',
  organizationLocations: 'v0/organizations/:organization/locations',
  activeUserList: 'v1/users/active',
  events: 'v1/events',
} as const
