import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  onStateChange,
  onStepChange,
} from '@/store/slices/pages/successPortal/broadcasting.slice'
import {
  Button,
  DataTable,
  DatePicker,
  Drawer,
  DrawerSize,
  Option,
  Select,
  Toggle,
} from '@/components'

import { schedulerTableColumns } from '@/pages/SuccessPortal/Broadcasting/config'
import { timeOptions } from '@/constants/automations'

import { ReactComponent as CalendarIcon } from '@/assets/icons/Calendar.svg'
import moment from 'moment-timezone'
import {
  getWeekDaysOverlapHours,
  setDateWithTime,
  timeToMinutes,
} from '@/utils'
import { useUpdateSegmentMutation } from '@/api/services/success-portal/broadcasting'
import TermsModal from '@/pages/SuccessPortal/Broadcasting/SchedulerModal/TermsModal'
import toast from 'react-hot-toast'
import { useGetSingleOrganizationsQuery } from '@/api/services/organization'
import { IWorkingHours } from '@/types'

interface SchedulerTableTopItemProps {
  date?: string
  onDateChange: (val?: string) => void
  time?: string
  onTimeChange: (val?: string) => void
  excludeDates: boolean
  everyDay: boolean
  onToggleChange: (key: string, val: boolean) => void
  onSelectedWeekdaysChange: (val: string[]) => void
  selectedWeekdays: string[]
  weekdays: Option[]
  availableHours: { maxEnd: number; minStart: number }
}

const SchedulerTableTopItem = ({
  date,
  time,
  onDateChange,
  excludeDates,
  everyDay,
  onToggleChange,
  selectedWeekdays,
  onSelectedWeekdaysChange,
  onTimeChange,
  weekdays,
  availableHours,
}: SchedulerTableTopItemProps) => {
  const options = timeOptions.map(el => {
    const time = timeToMinutes(el._id)
    return {
      ...el,
      disabled:
        time >= availableHours.maxEnd || time <= availableHours.minStart,
    }
  })
  return (
    <tr>
      <td></td>
      <td className="p-4">
        <div className="flex flex-col gap-3">
          <Toggle
            checked={excludeDates}
            onChange={val => onToggleChange('excludeDates', val)}
            wrapperClass="flex-row-reverse w-full justify-between"
            label="Exclude Dates"
          />
          {excludeDates && (
            <div>
              <Select
                isMulti
                onChange={val =>
                  onSelectedWeekdaysChange(val.map(el => el._id))
                }
                value={weekdays.filter(el => selectedWeekdays.includes(el._id))}
                options={weekdays}
              />
            </div>
          )}
          <Toggle
            checked={everyDay}
            onChange={val => onToggleChange('everyDay', val)}
            wrapperClass="flex-row-reverse w-full justify-between"
            label="Every Day at the same time until the list runs out"
          />
          {everyDay && (
            <div className="flex gap-3">
              <DatePicker
                label="Date"
                value={date ? new Date(date) : undefined}
                onChange={val => {
                  if (!val) return
                  onDateChange(moment(val).format('D MMM YYYY'))
                }}
                CustomIcon={CalendarIcon}
                placeholder="D MMM YYYY"
                className="w-full"
                disabledWeekDays={selectedWeekdays}
                maxDate={null}
                minDate={new Date(moment().subtract(1, 'days').format())}
              />
              <Select
                label="Time"
                onChange={val => {
                  if (!val || !date) return
                  const time = setDateWithTime(date, val?._id)
                  onDateChange(time)
                  onTimeChange(val?._id)
                }}
                value={options.find(el => el._id === time)}
                options={options}
                timeIcon
                disabled={!date}
              />
            </div>
          )}
        </div>
      </td>
    </tr>
  )
}

const BroadcastingSchedulerModal = () => {
  const [showTerms, setShowTerms] = useState(false)
  const [weekdays, setWeekdays] = useState<Option[]>(
    moment.weekdays().map(day => {
      return {
        _id: day,
        name: day,
        disabled: false,
      }
    }),
  )
  const [error, setError] = useState(false)
  const { segments, uploadStep, organization } = useAppSelector(
    state => state.broadcasting,
  )
  const [data, setData] = useState(segments)
  const [excludeDates, setExcludeDates] = useState(false)
  const [selectedWeekdays, setSelectedWeekdays] = useState<string[]>([])
  const [everyDay, setEveryDay] = useState(false)
  const [date, setDate] = useState<string | undefined>(undefined)
  const [time, setTime] = useState<string | undefined>(undefined)
  const dispatch = useAppDispatch()

  const { data: singleOrganization } = useGetSingleOrganizationsQuery(
    { id: organization },
    { skip: !organization },
  )

  const [updateSegment] = useUpdateSegmentMutation()

  const onModalChange = useCallback(
    (val?: number) => {
      dispatch(onStepChange(val))
    },
    [dispatch],
  )

  const onDateChange = useCallback((id: number, val?: string) => {
    setData(prev =>
      prev.map(el => (el.segment_number === id ? { ...el, time: val } : el)),
    )
  }, [])

  const onToggleChange = useCallback((key: string, val: boolean) => {
    if (key === 'excludeDates') {
      setExcludeDates(val)
      if (val) {
        setData(prev => prev.map(el => ({ ...el, time: null })))
      }
    }
    if (key === 'everyDay') {
      setEveryDay(val)
    }
  }, [])

  useEffect(() => {
    let lastDay = date
    if (everyDay && date) {
      setData(prev =>
        prev.map((el, index) => {
          let currentDate = lastDay
          if (index > 0) {
            currentDate = moment(currentDate)
              .add(1, 'days')
              .format('D MMM YYYY')
          }
          if (
            excludeDates &&
            selectedWeekdays.includes(moment(currentDate).format('dddd'))
          ) {
            currentDate = moment(currentDate)
              .add(1, 'days')
              .format('D MMM YYYY')
          }
          lastDay = currentDate
          return { ...el, time: currentDate }
        }),
      )
    }
  }, [date, everyDay, excludeDates, selectedWeekdays])

  useEffect(() => {
    setData(prev =>
      prev.map(el => {
        return {
          ...el,
          time: el.time && time ? setDateWithTime(el.time, time) : null,
        }
      }),
    )
  }, [time])

  useEffect(() => {
    setData(segments)
  }, [segments])

  const hasError = data.some(el => !el.time)

  const onSubmit = () => {
    setError(false)
    if (hasError) {
      setError(true)
      return
    } else {
      Promise.all(
        data.map(el =>
          updateSegment({
            id: el.id,
            data: {
              time: el.time ? moment(el.time).toISOString() : null,
            },
          })
            .unwrap()
            .catch(() => {
              return null
            }),
        ),
      ).then(res => {
        if (res.some(el => !el)) {
          toast.error('Failed to update some segments')
          return
        } else {
          dispatch(
            onStateChange({
              segments: segments.map((item, index) => {
                return res[index] ? { ...item, ...res[index] } : item
              }),
            }),
          )
          setShowTerms(true)
          toast.success('Segments updated successfully')
        }
      })
    }
  }

  useEffect(() => {
    if (singleOrganization) {
      const workingHours = singleOrganization.workingHours
      const notWorkingDays = Object.keys(workingHours).filter(el => {
        return workingHours[el as keyof IWorkingHours].isOpen === false
      })
      setSelectedWeekdays(notWorkingDays)
      setWeekdays(prev => {
        return prev.map(el => {
          if (notWorkingDays.includes(el._id)) {
            return { ...el, disabled: true }
          }
          return el
        })
      })
    }
  }, [singleOrganization])

  const overlapMinMax = useMemo(() => {
    return singleOrganization?.workingHours
      ? getWeekDaysOverlapHours(
          singleOrganization?.workingHours,
          weekdays
            .filter(el => !selectedWeekdays.includes(el._id))
            .map(el => el._id),
        )
      : { minStart: 0, maxEnd: 0 }
  }, [selectedWeekdays, singleOrganization?.workingHours, weekdays])

  return (
    <Drawer
      open={uploadStep === 6}
      onClose={onModalChange}
      title="Scheduler 3/3"
      size={DrawerSize.XL}
    >
      <div className="flex flex-col gap-4 grow">
        <div>
          <DataTable
            className="!overflow-visible"
            tableTopRow={
              <SchedulerTableTopItem
                date={date}
                onDateChange={setDate}
                time={time}
                onTimeChange={setTime}
                excludeDates={excludeDates}
                everyDay={everyDay}
                onToggleChange={onToggleChange}
                selectedWeekdays={selectedWeekdays}
                onSelectedWeekdaysChange={setSelectedWeekdays}
                weekdays={weekdays}
                availableHours={overlapMinMax}
              />
            }
            data={data}
            columns={schedulerTableColumns({
              everyDay,
              onDateChange,
              availableHours: overlapMinMax,
              time,
            })}
          />
        </div>
        <div className="mt-auto flex gap-2 justify-end ">
          <Button
            status="secondary"
            size="large"
            onClick={() => onModalChange(5)}
          >
            Previous
          </Button>
          <Button
            status="primary"
            size="large"
            onClick={() => {
              onSubmit()
            }}
            disabled={hasError}
          >
            Next
          </Button>
        </div>
      </div>
      <TermsModal onClose={() => setShowTerms(false)} open={showTerms} />
    </Drawer>
  )
}

export default BroadcastingSchedulerModal
