import React, { useCallback } from 'react'
import PageHeading from '@/sections/PageHeading'
import AiBotFilter from '@/pages/AiBotCustomers/Filter'
import { DataTable } from '@/components'
import { aiBotTableColumns } from '@/pages/AiBotCustomers/config'
import Pagination from '@/components/Pagination'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onFilterChange } from '@/store/slices/pages/ai-bot.slice'
import { useParams } from 'react-router-dom'
import { useGetAiBotCustomersQuery } from '@/api/services/organization/ai-bot'
import StatusModal from '@/pages/AiBotCustomers/StatusModal'

const OrganizationAiBotCustomers = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const [statusData, setStatusData] = React.useState<object>({})
  const { filter } = useAppSelector(state => state.aiBot)
  const {
    data: { data = [], pageMeta: { totalItems = 0 } = {} } = {},
    isFetching,
  } = useGetAiBotCustomersQuery({ ...filter, organization: id })
  const onPageChange = (page: number) => {
    dispatch(
      onFilterChange({
        page,
      }),
    )
  }
  const onStatusView = useCallback((data: object) => {
    setStatusData(data)
  }, [])
  return (
    <div className="w-full">
      <PageHeading title="AI Bot Customers" />
      <div className="w-full flex flex-col gap-6">
        <AiBotFilter />
        <DataTable
          loading={isFetching}
          columns={aiBotTableColumns(onStatusView)}
          data={data}
        />
        <Pagination
          currentPage={filter.page}
          totalCount={totalItems || 0}
          onPageChange={onPageChange}
        />
        <StatusModal
          open={Object.keys(statusData).length > 0}
          onClose={() => setStatusData({})}
          data={statusData}
        />
      </div>
    </div>
  )
}

export default OrganizationAiBotCustomers
