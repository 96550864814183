import moment from 'moment-timezone'
import { IDateFilterType, IFrequency, IWorkingHours } from '@/types'

export const formatDateTime = (
  dateTime: Date | string | undefined,
  format: string,
): string | undefined => dateTime && moment(dateTime).format(format)

export const isDisableDate = (
  curDate: Date,
  disabledList: Date[] = [],
  maxDate?: Date,
  minDate?: Date,
): boolean => {
  if (maxDate) {
    if (moment(curDate).isSameOrAfter(maxDate)) {
      return true
    }
  }
  if (minDate) {
    if (moment(curDate).isSameOrBefore(minDate)) {
      return true
    }
  }
  return !!disabledList?.some(d => moment(d).isSame(curDate, 'day'))
}

export const timeToMinutes = (time: string) => {
  const [timePart, modifier] = time.split(' ')
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = timePart.split(':').map(Number)
  if (modifier === 'PM' && hours !== 12) hours += 12
  if (modifier === 'AM' && hours === 12) hours = 0
  return hours * 60 + minutes
}

export const getWeekDaysOverlapHours = (
  workingHours: IWorkingHours,
  selectedDays: string[],
) => {
  if (selectedDays.length < 1) {
    return { minStart: 0, maxEnd: 0 }
  }
  let minStart = 0
  let maxEnd = 24 * 60

  selectedDays.forEach(day => {
    const dayHours = workingHours[day as keyof IWorkingHours]
    if (dayHours.isOpen) {
      const startMinutes = timeToMinutes(dayHours.from)
      const endMinutes = timeToMinutes(dayHours.to)
      minStart = Math.max(minStart, startMinutes)
      maxEnd = Math.min(maxEnd, endMinutes)
    }
  })
  return { minStart, maxEnd }
}

export const extractAutomationSchedules = (
  freq: IFrequency,
  weekdays: string[],
) => {
  const days: Record<
    string,
    { hour: number | string; minute: number | string }
  > = {}
  if (freq?.increment === 'weekday') {
    freq.weekdays.map(day => {
      const dayFormat = weekdays.find(
        weekDay => weekDay.substring(0, 3).toLowerCase() === day,
      )
      if (dayFormat) {
        days[dayFormat] = { hour: freq.time.hour, minute: freq.time.minute }
      }
    })
  }
  return days
}

export const combineSchedules = (
  data: Record<string, { hour: number | string; minute: number | string }>[],
) => {
  const combinedDays: Record<
    string,
    { hour: number | string; minute: number | string }[]
  > = {}
  return data.reduce((cur, acc) => {
    const keys = Object.keys(acc)
    keys.forEach(key => {
      cur[key] = cur[key] ? [...cur[key], acc[key]] : [acc[key]]
    })
    return cur
  }, combinedDays)
}

interface IStartEndTimezoneProps {
  start?: string
  end?: string
}

export const calculateStartEndDateWithTimezone = ({
  start,
  end,
}: IStartEndTimezoneProps) => {
  const timezone = moment.tz.guess()

  const startDate = start ? moment(start).startOf('day') : ''
  const endDate = end ? moment(end).endOf('day') : ''

  return {
    startDate: startDate
      ? moment.tz(startDate, timezone).utc().toISOString()
      : '',
    endDate: endDate ? moment.tz(endDate, timezone).utc().toISOString() : '',
  }
}

export const getDatesByFilterType = (type: IDateFilterType) => {
  let startDate, endDate

  if (type === 'yesterday') {
    startDate = moment().subtract(1, 'days')
    endDate = moment().subtract(1, 'days')
  }
  if (type === 'monthToDate') {
    startDate = moment().startOf('month')
    endDate = moment()
  }
  if (type === 'lastMonth') {
    startDate = moment().subtract(1, 'month').startOf('month')
    endDate = moment().subtract(1, 'month').endOf('month')
  }

  return {
    startDate: startDate?.toISOString(),
    endDate: endDate?.toISOString(),
  }
}

export const generateDateRange = (start: string, end: string) => {
  const startDateObj = new Date(start)
  const endDateObj = new Date(end)
  const dateArray = []

  while (startDateObj <= endDateObj) {
    const dateStr = startDateObj.toISOString().split('T')[0]
    dateArray.push(dateStr)
    startDateObj.setDate(startDateObj.getDate() + 1)
  }

  return dateArray
}

// generate options for time for every 15 minutes like 12:00 AM, 12:15 AM, 12:30 AM, 12:45 AM
export const generateTimeOptions = () => {
  return Array.from({ length: 96 }, (_, i) => {
    const hours = Math.floor(i / 4)
    const minutes = i % 4
    const time = moment()
      .startOf('day')
      .add(hours, 'hours')
      .add(15 * minutes, 'minutes')
      .format('hh:mm A')
    return {
      _id: time,
      name: time,
    }
  })
}

export const setDateWithTime = (selectedDate: string, selectedTime: string) => {
  return moment(selectedDate)
    .hour(moment(selectedTime, 'hh:mm A').hour())
    .minute(moment(selectedTime, 'hh:mm A').minute())
    .second(0)
    .millisecond(0)
    .toISOString()
}

export const getOrdinalDay = (day: number) => {
  if (day >= 11 && day <= 13) {
    return `${day}th`
  }

  const suffixes = ['th', 'st', 'nd', 'rd']
  const mod10 = day % 10

  return `${day}${suffixes[mod10] || 'th'}`
}
