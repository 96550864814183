import React from 'react'
import { Checkbox as CheckboxBase, Field, Label } from '@headlessui/react'
import clsx from 'clsx'
import { Typography } from '@/components'

export interface CheckboxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange?: (checked: boolean) => void
  label?: string
}

export const Checkbox = ({
  checked,
  className,
  onChange,
  disabled,
  label,
  ...rest
}: CheckboxProps) => {
  return (
    <Field className="flex items-center gap-2">
      <CheckboxBase
        checked={checked}
        onChange={onChange}
        className={clsx(
          'cursor-pointer group shrink-0 size-4 rounded border border-black-10 bg-white data-[checked]:bg-blue-100 flex justify-center items-center transition-all data-[disabled]:bg-bg-gray data-[checked]:data-[disabled]:bg-blue-40 data-[disabled]:cursor-not-allowed outline-none',
          className,
        )}
        disabled={disabled}
        {...rest}
      >
        {({ checked, disabled }) => (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            className={clsx(
              'transition-opacity opacity-0',
              checked && 'opacity-100',
            )}
          >
            <path
              d="M10 3L4.5 8.5L2 6"
              stroke={disabled ? '#D0D5DD' : '#fff'}
              strokeWidth="1.6666"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </CheckboxBase>
      {label && (
        <Label className="cursor-pointer">
          <Typography variant="small" weight="medium" className="text-black-80">
            {label}
          </Typography>
        </Label>
      )}
    </Field>
  )
}
