import { createApi } from '@reduxjs/toolkit/query/react'
import { IAuditTrail, IAuditTrailFilter, IPaginatedRes } from '@/types'
import { endpoints } from '@/api/constants'
import { createBaseQuery, generateQueryParams } from '@/utils'

export const auditTrailApi = createApi({
  reducerPath: 'auditTrailApi',
  tagTypes: ['AuditTrail'],
  baseQuery: createBaseQuery(),
  endpoints: builder => ({
    getEvents: builder.query<IPaginatedRes<IAuditTrail>, IAuditTrailFilter>({
      query: query => {
        return {
          url: endpoints.events + generateQueryParams(query),
        }
      },
    }),
  }),
})

export const { useGetEventsQuery } = auditTrailApi
