import React, { useEffect } from 'react'
import AuditTrailFilter from '@/pages/SuccessPortal/AuditTrail/Filter'

import { DataTable } from '@/components'
import { auditTrailColumns } from '@/pages/SuccessPortal/AuditTrail/config'
import { useGetEventsQuery } from '@/api/services/success-portal/audit-trail'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import Pagination from '@/components/Pagination'
import { onFilterChange } from '@/store/slices/pages/successPortal/audit-trail.slice'

const AuditTrail = () => {
  const dispatch = useAppDispatch()
  const { filter } = useAppSelector(state => state.auditTrail)
  const {
    data: { data = [], pageMeta: { totalItems = 0 } = {} } = {},
    isFetching,
  } = useGetEventsQuery(filter)

  const onPageChange = (page: number) => {
    dispatch(
      onFilterChange({
        page,
      }),
    )
  }
  return (
    <div className="flex flex-col justify-center gap-6 max-w-screen-xl mx-auto w-full">
      <AuditTrailFilter />

      <DataTable data={data} columns={auditTrailColumns} loading={isFetching} />
      <Pagination
        currentPage={filter.page}
        totalCount={totalItems}
        onPageChange={onPageChange}
      />
    </div>
  )
}

export default AuditTrail
