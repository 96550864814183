import React from 'react'
import { useAppSelector } from '@/store/hooks'
import DataTable from '../../../components/Table'
import { errorTableColumns } from '@/pages/Analytics/config'
import { useGetErrorCodeCountQuery } from '@/api/services/error-codes'

const ErrorCodeTable = () => {
  // const {
  //   filter: { startDate, endDate },
  // } = useAppSelector(state => state.analytics)
  const {
    filter: { startDate, endDate },
  } = useAppSelector(state => state.errorTracker)

  const { data, isFetching } = useGetErrorCodeCountQuery({ startDate, endDate })

  return (
    <div className="w-full">
      <DataTable
        data={data || []}
        // @ts-ignore
        columns={errorTableColumns}
        loading={isFetching}
      />
    </div>
  )
}

export default ErrorCodeTable
