import React, { useCallback } from 'react'
import { Button, LoadingSkeleton } from '@/components'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { ITemplate, IWorkflowLocation } from '@/types'
import { ReactComponent as ArrowLeftIcon } from '@/assets/icons/Arrow Left.svg'
import Confirmation from '@/pages/V2Dashboard/WorkflowForm/Steps/SelectOptions/Confirmation'
import { Combinators } from '@/pages/V2Dashboard/WorkflowForm/Steps/SelectOptions/Combinator'
import { onStatusChange, onStepChange } from '@/store/slices/workflow.slice'
import { defaultCombinator } from '@/constants/automations'
import {
  useAddWorkflowMutation,
  useEditWorkflowMutation,
} from '@/api/services/workflow'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'

const SelectOptions = ({
  goBack,
}: {
  templates: ITemplate[]
  locations: IWorkflowLocation[]
  goBack: (val: number) => void
}) => {
  const dispatch = useAppDispatch()
  const {
    form: { name, message, actions, template, location, sendAfter, combinator },
    isRequired,
    isLoading,
  } = useAppSelector(state => state.workflow)
  const { workFlowId } = useParams()
  const [update] = useEditWorkflowMutation()
  const [add] = useAddWorkflowMutation()
  if (isLoading) {
    return <LoadingSkeleton width="100%" height="50vh" />
  }

  const onSave = useCallback(async () => {
    const request = workFlowId === 'new' ? add : update

    const noName = !name
    if (noName) {
      return
    }
    const { messageField, offsetField, combinatorField } = isRequired

    if (
      ((messageField && !message) ||
        !template ||
        !location ||
        (offsetField && !sendAfter) ||
        (combinatorField && !combinator)) &&
      template?.type !== 'appointmentConfirmation'
    ) {
      return
    }

    dispatch(onStepChange(3))
    dispatch(onStatusChange('pending'))
    try {
      if (name && workFlowId && location) {
        await request({
          workflowId: workFlowId,
          locationId: location,
          name,
          actions,
          message,
          sendAfter,
          combinator: combinator || defaultCombinator,
          crm: template.crm,
          type: template.type,
          workflowTemplate: template?.id,
          flags: template?.flags,
        }).then(res => {
          if (res.error) {
            dispatch(onStepChange(2))
            dispatch(onStatusChange('failed'))
            // @ts-ignore
            const message = res.error?.data?.message || res.error?.data
            toast.error(message)
          } else {
            dispatch(onStatusChange('completed'))
            toast.success(
              workFlowId === 'new'
                ? 'Workflow created successfully'
                : 'Workflow updated successfully',
            )
          }
        })
      }
    } catch (err: any) {
      dispatch(onStepChange(2))
      dispatch(onStatusChange('failed'))
    }
  }, [
    actions,
    add,
    combinator,
    dispatch,
    location,
    message,
    name,
    sendAfter,
    template,
    update,
    workFlowId,
  ])

  return (
    <div className="grow flex">
      <div className="w-full grow flex flex-col">
        <div className="flex justify-between mb-4">
          <div>
            <Button
              onClick={() => goBack(1)}
              size="small"
              status="text"
              IconLeft={ArrowLeftIcon}
            >
              Edit workflow selection
            </Button>
          </div>
        </div>
        {template?.type === 'appointmentConfirmation' ? (
          <Confirmation onSave={onSave} />
        ) : (
          <Combinators onSave={onSave} />
        )}
      </div>
    </div>
  )
}

export default SelectOptions
