import React from 'react'
import Modal from '@/components/Modal'
import { Button, Input } from '@/components'
import { useHookForm } from '@/hooks'
import { broadcastFormSchema } from '@/utils'
import { IBroadcastingFrom, TBroadcastTemplateField } from '@/types'
import Form from '@/components/Form'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  dataHeaders,
  onStateChange,
} from '@/store/slices/pages/successPortal/broadcasting.slice'

interface IAddModal {
  open: boolean
  onClose: () => void
}

const AddModal = ({ open, onClose }: IAddModal) => {
  const dispatch = useAppDispatch()
  const { uploadedData, mapColumns } = useAppSelector(
    state => state.broadcasting,
  )

  const headers = useAppSelector(dataHeaders)
  const {
    handler: {
      handleSubmit,
      register,
      trigger,
      formState: { errors },
      reset,
    },
  } = useHookForm<IBroadcastingFrom>(broadcastFormSchema)
  const onSubmit = (form: IBroadcastingFrom) => {
    if (Object.keys(errors).length) return
    dispatch(
      onStateChange({
        uploadedData: [
          ...uploadedData,
          headers.reduce(
            (acc, curr: string) => {
              const key = mapColumns.find(el => el.column === curr)?.key
              const value = form[key as TBroadcastTemplateField]
              acc[curr] = value?.toString() ?? ''
              return acc
            },
            {} as Record<string, string>,
          ),
        ],
      }),
    )
    onClose()
    reset()
  }
  return (
    <Modal open={open} onClose={onClose} title="Add Row">
      <div className="flex flex-col gap-6">
        <Form className="flex flex-col gap-5" onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Phone Number"
            placeholder="Please enter phone number"
            error={errors.phone_number?.message}
            {...register('phone_number', {
              onChange: () => trigger('phone_number'),
            })}
          />
          <Input
            label="First Name"
            placeholder="Please enter first name"
            error={errors.first_name?.message}
            {...register('first_name', {
              onChange: () => trigger('first_name'),
            })}
          />
          <Input
            label="Last Name"
            placeholder="Please enter first name"
            error={errors.last_name?.message}
            {...register('last_name', {
              onChange: () => trigger('last_name'),
            })}
          />
          <Input
            label="Email"
            placeholder="Please enter email"
            error={errors.email?.message}
            {...register('email', {
              onChange: () => trigger('email'),
            })}
          />
          <Input
            label="Vehicle Make"
            placeholder="Please enter vehicle make"
            error={errors.vehicle_make?.message}
            {...register('vehicle_make', {
              onChange: () => trigger('vehicle_make'),
            })}
          />
          <Input
            label="Vehicle Year"
            placeholder="Please enter vehicle year"
            error={errors.vehicle_year?.message}
            {...register('vehicle_year', {
              onChange: () => trigger('vehicle_year'),
            })}
          />
          <Input
            label="Vehicle Model"
            placeholder="Please enter vehicle model"
            error={errors.vehicle_model?.message}
            {...register('vehicle_model', {
              onChange: () => trigger('vehicle_model'),
            })}
          />
          <div className="flex gap-2 justify-end w-full">
            <Button
              status="secondary"
              size="small"
              type="button"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button status="primary" size="small" type="submit">
              Add
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

export default AddModal
