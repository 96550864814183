import React, { useCallback, useEffect, useState } from 'react'
import Modal from '@/components/Modal'
import { Typography } from '@/components'
import { onStepChange } from '@/store/slices/pages/successPortal/broadcasting.slice'
import { useAppDispatch } from '@/store/hooks'

interface IReplaceModal {
  open: boolean
  onClose: () => void
  isSuccess: boolean
}

const SubmitModal = ({ open, onClose, isSuccess }: IReplaceModal) => {
  const dispatch = useAppDispatch()
  const [progress, setProgress] = useState<number>(0)

  const onModalChange = useCallback(
    (val?: number) => {
      dispatch(onStepChange(val))
    },
    [dispatch],
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if (open) {
        setProgress(prev => {
          if (prev >= 90 && !isSuccess) {
            return 90
          }
          if (prev >= 100 || isSuccess) {
            clearInterval(interval)
            return 100
          }
          return prev + 10
        })
      }
    }, 400)
    return () => clearInterval(interval)
  }, [isSuccess, open])

  useEffect(() => {
    if (progress === 100) {
      onClose()
      onModalChange()
      setProgress(0)
    }
  }, [progress, onModalChange, onClose])

  return (
    <Modal showBackdrop={false} open={open} onClose={onClose} title="Submit">
      <div className="flex flex-col gap-3 items-center">
        <Typography variant="medium" weight="medium">
          Processing Data (0/0)
        </Typography>
        <div className="w-full rounded-[99px] bg-black-5 h-4 overflow-hidden relative">
          <span
            className="absolute top-0 left-0 bg-blue-100 h-full rounded-[99px] transition-all duration-300"
            style={{ width: `${progress}%` }}
          ></span>
        </div>
        <Typography variant="small" className="text-black-50">
          Do not close the browser window.
        </Typography>
      </div>
    </Modal>
  )
}

export default SubmitModal
