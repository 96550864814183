import { createSlice } from '@reduxjs/toolkit'
import { defaultPageMeta } from '@/constants'

import { IInventoryState } from '@/types/inventory'
import { formDefaultState } from '@/constants/inventory'

const initialState: IInventoryState = {
  loading: false,
  error: undefined,
  fetched: false,
  filter: {
    ...defaultPageMeta,
    pageSize: 100,
    status: '',
    organization: '',
    vendor: '',
  },
  form: formDefaultState,
  showForm: false,
  importJobs: '',
  showFiles: undefined,
  eventId: undefined,
  showPrice: undefined,
}

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    clearFilter: state => {
      state.filter = initialState.filter
    },
    onFilterChange: (state, action) => {
      state.filter = { ...state.filter, ...action.payload }
    },
    onFormChange: (state, action) => {
      state.form = { ...state.form, ...action.payload }
    },
    onFormToggle: (state, action) => {
      state.showForm = action.payload
    },
    onImportJobsChange: (state, action) => {
      state.importJobs = action.payload
    },
    onShowFilesChange: (state, action) => {
      state.showFiles = action.payload
    },
    onEventIdChange: (state, action) => {
      state.eventId = action.payload
    },
    onPriceToggle: (state, action) => {
      state.showPrice = action.payload
    },
  },
})

export const {
  clearFilter,
  onFilterChange,
  onFormChange,
  onFormToggle,
  onImportJobsChange,
  onShowFilesChange,
  onEventIdChange,
  onPriceToggle,
} = inventorySlice.actions
export default inventorySlice.reducer
