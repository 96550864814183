import { createApi } from '@reduxjs/toolkit/query/react'
import {
  IBroadcastSegmentResponse,
  IBroadcastUpdateRequest,
  IFileUploadRequest,
  ISegmentItem,
} from '@/types'
import { SUPPORT_TOOLING_API_BASE_URL } from '@/constants'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'

export const broadcastingApi = createApi({
  reducerPath: 'broadcastingApi',
  tagTypes: ['Broadcasting'],
  baseQuery: fetchBaseQuery({
    baseUrl: SUPPORT_TOOLING_API_BASE_URL,
  }),
  endpoints: builder => ({
    uploadFile: builder.mutation<IBroadcastSegmentResponse, IFileUploadRequest>(
      {
        query: params => {
          const body = new FormData()
          body.append('file', params.file)
          body.append('rows_per_segment', params.rows_per_segment)
          body.append('column_mapping', JSON.stringify(params.column_mapping))
          body.append('name', params.name)

          return {
            url: '/api/broadcast/upload/',
            method: 'POST',
            body,
          }
        },
      },
    ),
    getBroadcastingById: builder.query<
      IBroadcastSegmentResponse,
      { id: string }
    >({
      query: ({ id }) => {
        return {
          url: '/api/broadcast/segment/' + id,
        }
      },
    }),
    updateSegment: builder.mutation<
      ISegmentItem,
      { id: string; data: Partial<ISegmentItem> }
    >({
      query: ({ id, data }) => {
        return {
          url: `/api/broadcast/segment/${id}/update`,
          method: 'PATCH',
          body: data,
        }
      },
    }),
    updateBroadcast: builder.mutation<
      IBroadcastUpdateRequest,
      { id: string; data: Partial<IBroadcastUpdateRequest> }
    >({
      query: ({ id, data }) => {
        return {
          url: `/api/broadcast/${id}/update`,
          method: 'PATCH',
          body: data,
        }
      },
    }),
    processBroadcast: builder.mutation<{ message: string }, { id: string }>({
      query: ({ id }) => {
        return {
          url: `/api/broadcast/process`,
          method: 'POST',
          body: { dataset_id: id },
        }
      },
    }),
  }),
})

export const {
  useUploadFileMutation,
  useLazyGetBroadcastingByIdQuery,
  useUpdateSegmentMutation,
  useUpdateBroadcastMutation,
  useProcessBroadcastMutation,
} = broadcastingApi
