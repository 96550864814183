import React from 'react'
import { Typography } from '@/components'

import { useGetStatsQuery } from '@/api/services/statistic'
import Card from '@/components/Card'

const Stats = () => {
  const {
    data: {
      automation: {
        successRate = 0,
        completed = 0,
        failed = 0,
        pending = 0,
      } = {},
      appointment: { successRate: appointmentSuccessRate = 0 } = {},
    } = {},
  } = useGetStatsQuery(undefined)

  const errorRate = (failed / (completed + pending + failed)) * 100 || 0
  const formattedErrorRate =
    errorRate % 1 === 0 ? errorRate.toFixed(0) : errorRate.toFixed(1)

  return (
    <div className="grid grid-cols-5 gap-4 w-full">
      <Card className="!p-5 flex flex-col justify-between gap-2">
        <Typography variant="regular" weight="medium">
          Automation Tasks Completed
        </Typography>
        <Typography variant="H2" weight="semibold">
          {completed}
        </Typography>
      </Card>
      <Card className="!p-5 flex flex-col justify-between gap-2">
        <Typography variant="regular" weight="medium">
          Failed Automations
        </Typography>
        <Typography variant="H2" weight="semibold">
          {failed}
        </Typography>
      </Card>
      <Card className="!p-5 flex flex-col justify-between gap-2">
        <Typography variant="regular" weight="medium">
          Broadcast Success Rate
        </Typography>
        <div className="flex gap-2 items-center">
          <Typography variant="H2" weight="semibold">
            {successRate}%
          </Typography>
        </div>
      </Card>
      <Card className="!p-5 flex flex-col justify-between gap-2">
        <Typography variant="regular" weight="medium">
          Appointment Confirmations Success Rate
        </Typography>
        <div className="flex gap-2 items-center">
          <Typography variant="H2" weight="semibold">
            {appointmentSuccessRate}%
          </Typography>
        </div>
      </Card>
      <Card className="!p-5 flex flex-col justify-between gap-2">
        <Typography variant="regular" weight="medium">
          Broadcast Error Rate
        </Typography>
        <div className="flex gap-2 items-center">
          <Typography variant="H2" weight="semibold">
            {formattedErrorRate}%
          </Typography>
        </div>
      </Card>
    </div>
  )
}

export default Stats
