import React from 'react'
import { Typography } from '@/components'
import { ReactComponent as InfoIcon } from '@/assets/icons/Info Blue.svg'
import { ReactComponent as InfoCircle } from '@/assets/icons/Info.svg'
import { ReactComponent as ErrorIcon } from '@/assets/icons/Error Red.svg'
import { ReactComponent as CheckIcon } from '@/assets/icons/Check Green.svg'

import { ITaskParsedPayload } from '@/types'
const icons = {
  success: CheckIcon,
  error: ErrorIcon,
  info: InfoIcon,
  infoCircle: InfoCircle,
}

const Title = ({
  message,
  type,
  parsedPayload,
}: {
  message: string
  type: string
  parsedPayload?: ITaskParsedPayload[]
}) => {
  const isEmpty = type === 'MATADOR_BROADCAST' && !parsedPayload?.length
  const Icon = isEmpty
    ? icons.infoCircle
    : type === 'SUCCESS'
      ? icons.success
      : type === 'ERROR'
        ? icons.error
        : icons.info
  return (
    <div className="flex items-center gap-2">
      <Icon className={isEmpty ? 'w-6 h-6 text-blue-100' : ''} />
      <Typography variant="regular" weight="medium">
        {message}
      </Typography>
    </div>
  )
}

export default Title
