import React from 'react'
import { Tag, Typography } from '@/components'
import MessageSection from '@/pages/SuccessPortal/Broadcasting/ContentModal/MessageSection'

const BuildOwn = () => {
  return (
    <div className="flex flex-col gap-6 max-w-xl w-full">
      <Typography variant="small" className="text-black-50" weight="medium">
        BYOB = Build your own broadcast.
      </Typography>
      <MessageSection />
    </div>
  )
}

export default BuildOwn
