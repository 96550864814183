import React, { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onFilterChange } from '@/store/slices/pages/successPortal/audit-trail.slice'
import { Select } from '@/components'
import DatePicker from '@/components/DatePicker'
import { useGetOrganizationsQuery } from '@/api/services/organization-list'
import { useGetActiveUsersQuery } from '@/api/services/user'
import { auditTrailActionOptions } from '@/constants'

const AuditTrailFilter = () => {
  const dispatch = useAppDispatch()
  const {
    filter: { startDate, endDate, organizationIds, userIds, actions },
  } = useAppSelector(state => state.auditTrail)

  const { data: organizations = [] } = useGetOrganizationsQuery(undefined)
  const { data: users = [] } = useGetActiveUsersQuery(undefined)

  const onMultiSelectChange = (field: string, value: string[]) => {
    dispatch(onFilterChange({ [field]: value, page: 1 }))
  }

  const onDateChange = useCallback(
    (type: 'startDate' | 'endDate') => (date: Date | undefined) => {
      dispatch(
        onFilterChange({
          [type]: date,
          page: 1,
        }),
      )
    },
    [dispatch],
  )

  const userOptions = useMemo(() => {
    return users.map(user => ({
      _id: user._id,
      name: user.firstName + ' ' + user.lastName,
    }))
  }, [users])

  return (
    <div className="grid grid-cols-5 gap-3">
      <Select
        label="Organizations"
        isMulti
        isSearchable
        placeholder="Select Organizations"
        options={organizations}
        value={organizations.filter(org => organizationIds.includes(org._id))}
        onChange={val =>
          onMultiSelectChange(
            'organizationIds',
            val.map(org => org._id),
          )
        }
      />
      <Select
        label="Usres"
        isMulti
        isSearchable
        placeholder="Select Users"
        options={userOptions}
        value={userOptions.filter(user => userIds.includes(user._id))}
        onChange={val =>
          onMultiSelectChange(
            'userIds',
            val.map(user => user._id),
          )
        }
      />
      <Select
        label="Action Types"
        isMulti
        onChange={val => {
          onMultiSelectChange(
            'actions',
            val?.map(action => action._id),
          )
        }}
        value={auditTrailActionOptions.filter(action =>
          actions.includes(action._id),
        )}
        options={auditTrailActionOptions}
      />
      <DatePicker
        label="Start Date"
        value={new Date(startDate)}
        onChange={onDateChange('startDate')}
        placeholder="Select a date range"
      />
      <DatePicker
        label="End Date"
        value={new Date(endDate)}
        onChange={onDateChange('endDate')}
        placeholder="Select a date range"
      />
    </div>
  )
}

export default AuditTrailFilter
