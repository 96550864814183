import { ColumnDef } from '@tanstack/react-table'

import { ReactComponent as CalendarIcon } from '@/assets/icons/Calendar.svg'
import { Button, DatePicker, Option, Select } from '@/components'
import { timeOptions } from '@/constants/automations'
import { ISegmentItem } from '@/types'
import moment from 'moment-timezone'
import { setDateWithTime, timeToMinutes } from '@/utils'
import { useGetWorkflowsQuery } from '@/api/services/workflow'

export const mapColumnTableColumns = (
  options: Option[],
  onSelect: (key: string, value?: string) => void,
): ColumnDef<any, any>[] => {
  return [
    {
      accessorKey: 'templateField',
      header: () => <span>Template Fields</span>,
    },
    {
      accessorKey: 'column',
      header: () => <span>Columns in your File</span>,
      cell: ({ row, getValue }) => {
        const key = row.original.templateField
        const value = getValue()
        const selectedOption = options.find(option => option._id === value)
        return (
          <Select
            onChange={val => onSelect(key, val?._id)}
            value={selectedOption}
            options={options}
            placeholder="Select a column"
          />
        )
      },
    },
  ]
}

interface ICampaignTableColumnsProps {
  sameWorkflow: boolean
  sameLocation: boolean
  sameUser: boolean
  workflows: Option[]
  locations: Option[]
  users: Option[]
  onChange: (key: string, index: number, value?: string | string[]) => void
}

export const campaignTableColumns = ({
  sameWorkflow,
  sameLocation,
  sameUser,
  workflows,
  locations,
  users,
  onChange,
}: ICampaignTableColumnsProps): ColumnDef<ISegmentItem, any>[] => {
  return [
    {
      accessorKey: 'segment_number',
      header: () => <span>Actions</span>,
      cell: ({ getValue, row }) => {
        const number = getValue()
        return (
          <Button
            status="primary"
            size="small"
            onClick={() => {
              const url = row.original.signed_url
              window.open(url, '_blank')
            }}
          >
            Export file {number} to XLSX
          </Button>
        )
      },
    },

    {
      accessorKey: 'location_id',
      header: () => <span>Location</span>,
      cell: ({ getValue, row }) => {
        const number = row.original.segment_number
        const value = getValue()
        const selectedValue = locations.find(option => option._id === value)
        return (
          <Select
            disabled={sameLocation}
            onChange={val => onChange('location_id', number, val?._id)}
            value={selectedValue}
            placeholder="Select location"
            options={locations}
          />
        )
      },
    },
    {
      accessorKey: 'assignees',
      header: () => <span>Assign campaign to</span>,
      cell: ({ getValue, row }) => {
        const number = row.original.segment_number
        const value = getValue()
        const selectedValues = users.filter(option =>
          value.includes(option._id),
        )
        return (
          <Select
            disabled={sameUser}
            isMulti
            onChange={val =>
              onChange('assignees', number, val?.map(v => v._id) || [])
            }
            value={selectedValues}
            placeholder="Select users"
            options={users}
          />
        )
      },
    },
    {
      accessorKey: 'workflow_id',
      header: () => <span>Workflow</span>,
      cell: ({ getValue, row }) => {
        const number = row.original.segment_number
        const locationId = row.original.location_id
        const value = getValue()
        const { data: { data: locationWorkflows = [] } = {} } =
          useGetWorkflowsQuery(
            {
              id: locationId,
            },
            {
              skip: !locationId,
            },
          )
        const options = locationWorkflows.map(option => ({
          _id: option._id,
          name: option.name,
        }))

        const selectedValue = options.find(option => option._id === value)

        return (
          <Select
            disabled={sameWorkflow}
            onChange={val => onChange('workflow_id', number, val?._id)}
            value={selectedValue}
            placeholder="Select workflow"
            options={options}
            isClearable
          />
        )
      },
    },
  ]
}

interface ISchedulerTableColumnsProps {
  everyDay: boolean
  onDateChange: (index: number, value?: string) => void
  availableHours: { maxEnd: number; minStart: number }
  time?: string
}

export const schedulerTableColumns = ({
  everyDay,
  onDateChange,
  availableHours,
  time,
}: ISchedulerTableColumnsProps): ColumnDef<ISegmentItem, any>[] => {
  return [
    {
      accessorKey: 'segment_number',
      header: () => <span>Actions</span>,
      cell: ({ getValue, row }) => {
        const number = getValue()
        return (
          <Button
            className="w-full"
            status="primary"
            size="small"
            onClick={() => {
              const url = row.original.signed_url
              window.open(url, '_blank')
            }}
          >
            Export file {number} to XLSX
          </Button>
        )
      },
    },
    {
      accessorKey: 'time',
      header: () => <span>Date</span>,
      cell: ({ getValue, row }) => {
        const date = getValue()
        console.log('date', date)
        const timeSection = date ? date.split('T')?.[1] : ''
        let timeOption = ''
        if (timeSection) {
          timeOption = moment(date).format('hh:mm A')
        }
        const id = row.original.segment_number
        const options = timeOptions.map(el => {
          const time = timeToMinutes(el._id)
          return {
            ...el,
            disabled:
              time >= availableHours.maxEnd || time <= availableHours.minStart,
          }
        })
        return (
          <div className="flex gap-2 w-full">
            <DatePicker
              label="Date"
              disabled={everyDay}
              value={date}
              onChange={val => {
                if (!val) return
                onDateChange(id, moment(val).format('D MMM YYYY'))
              }}
              className="w-full"
              CustomIcon={CalendarIcon}
              maxDate={null}
              minDate={new Date(moment().subtract(1, 'days').format())}
            />
            <Select
              label="Time"
              disabled={everyDay || !date}
              onChange={val => {
                if (!val || !date) return
                const time = setDateWithTime(date, val?._id)
                onDateChange(id, time)
              }}
              value={options.find(el => el._id === timeOption)}
              options={options}
              timeIcon
            />
          </div>
        )
      },
    },
  ]
}
