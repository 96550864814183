import { IGlobalState, IPageMeta } from '@/types/global'

export enum InventoryStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
}

export type InventoryType =
  | 'homenet'
  | 'vauto'
  | 'sm360'
  | 'serti'
  | 'dealersync'
  | 'carsforsales'
  | 'dealerspike'
  | 'edealer'
  | 'vincue'
  | 'autosweet'
  | 'dealerscloud'
  | 'dealerslink'
  | 'magnetis'

export interface IInventory {
  id: string
  organization: {
    _id: string
    name: string
  }
  status: InventoryStatus
  dealer_id: string
  vendor: InventoryType
  created_at?: Date
  updated_at?: Date
  synced_at?: Date
  modifiedAt?: Date
  domain_url: string
  touse_new_price_col: string
  touse_used_price_col: string
}

export interface IInventoryFilter extends IPageMeta {
  organization: string
  status: InventoryStatus | string
  vendor: InventoryType | string
}

export interface IInventoryForm {
  id?: string
  organization_id: string
  vendor: string
  status: InventoryStatus
  dealer_id: string
  domain_url: string[]
}

export interface IInventoryFormPayload
  extends Omit<IInventoryForm, 'domain_url'> {
  domain_url: string
}

export interface IInventoryState extends IGlobalState {
  filter: IInventoryFilter
  form: IInventoryForm
  showForm: boolean
  importJobs?: string
  showFiles?: IInventory
  eventId?: string
  showPrice?: IInventory
}

export interface IUnusedFeed {
  dealer: string
  modifiedAt: Date
}

export type IUnusedFeedResponseType = Record<InventoryType, IUnusedFeed[]>

export interface IUnusedFeedState extends IGlobalState {
  unusedFeeds?: IUnusedFeedResponseType
}

export type IImportJobStatus = 'pending' | 'success' | 'failed'

export interface IImportJob {
  id: string
  mappingId: string
  status: IImportJobStatus
  message: string
  created_at?: Date
  updated_at?: Date
}

export interface IInventoryEvent {
  user: {
    _id: string
    email: string
    firstName: string
    lastName: string
  }
  payload: {
    id: string
    organization: {
      _id: string
      name: string
    }
    status: InventoryStatus
    dealer_id: string
    vendor: string
    created_at?: Date
    updated_at?: Date
  }
  createdAt: Date
}

export interface IInventoryFile {
  name: string
  modifiedAt: Date
}

export interface IHomenetInventory {
  id: string
  type: string
  stock: string
  vin: string
  year: string
  make: string
  model: string
  body: string
  trim: string
  modelnumber: string
  doors: string
  exteriorcolor: string
  interiorcolor: string
  enginecylinders: string
  enginedisplacement: string
  transmission: string
  miles: string
  sellingprice: string
  msrp: string
  bookvalue: string
  invoice: string
  certified: string
  dateinstock: string
  description: string
  options: string
  'categorized options': string
  'dealer name': string
  'dealer address': string
  'dealer city': string
  'dealer state': string
  'dealer zip': string
  'dealer phone': string
  'dealer fax': string
  'dealer email': string
  'comment 1': string
  'comment 2': string
  'comment 3': string
  'comment 4': string
  'comment 5': string
  style_description: string
  ext_color_generic: string
  ext_color_code: string
  int_color_generic: string
  int_color_code: string
  int_upholstery: string
  engine_block_type: string
  engine_aspiration_type: string
  engine_description: string
  transmission_speed: string
  transmission_description: string
  drivetrain: string
  fuel_type: string
  citympg: string
  highwaympg: string
  epaclassification: string
  wheelbase_code: string
  internet_price: string
  misc_price1: string
  misc_price2: string
  misc_price3: string
  factory_codes: string
  marketclass: string
  passengercapacity: string
  extcolorhexcode: string
  intcolorhexcode: string
  enginedisplacementcubicinches: string
  imagelist: string
  organizationid: string
}
export interface IVAutoInventory {
  id: string
  dealerid: string
  dealername: string
  dealeraddress: string
  dealercity: string
  dealerstate: string
  dealerzip: string
  dealeremail: string
  dealerphone: string
  type: string
  certified: string
  disp: string
  stock: string
  vin: string
  year: string
  make: string
  model: string
  trim: string
  miles: string
  sellingprice: string
  dateinstock: string
  interiorcolor: string
  extcolorgeneric: string
  exteriorcolor: string
  body: string
  bodytype: string
  doors: string
  wheelbasecode: string
  enginedescription: string
  engineaspirationtype: string
  enginecylinders: string
  enginedisplacement: string
  fueltype: string
  transmission: string
  transmissionspeed: string
  transmissiontype: string
  drivetraindesc: string
  drivetrain: string
  drivetrainwheelct: string
  description: string
  options: string
  imagelist: string
  msrp: string
  highwaympg: string
  citympg: string
  recommendsimilar: string
  organizationid: string
  record_date: string
  vdp_url: string
  listing_features: string
  vehicle_fuel_type: string
  portal_deal_ratings: string
  portal_urls: string
  vehicle_color_exterior: string
}
export interface ISM360Inventory {
  id: string
  dealerid?: string
  remotedatemodified?: string
  remotedateentered?: string
  status?: string
  isdemo?: string
  vehicletype?: string
  warranty?: string
  passengercount?: string
  specialmentions?: string
  inservicedate?: string
  stockurl?: string
  mainphoto?: string
  regularprice: string
  videoen?: string
  videofr?: string
  stockstatus?: string
  clean_carfax?: string
  one_owner?: string
  vin: string
  organizationid: string
  type: string
  stock: string
  year: string
  make: string
  model: string
  body: string
  trim: string
  doors: string
  exteriorcolor: string
  interiorcolor: string
  enginecylinders: string
  enginedisplacement: string
  transmission: string
  miles: string
  sellingprice: string
  msrp: string
  certified: string
  description: string
  drivetrain: string
  imagelist: string
  record_date?: string
  vdp_url?: string
  listing_features?: string
  vehicle_fuel_type?: string
  portal_deal_ratings?: string
  portal_urls?: string
  vehicle_color_exterior?: string
}

export interface IDealersyncInventory {
  id: string
  dealerid: string
  vin: string
  type: string
  dealeraddress: string
  stock: string
  year: string
  make: string
  model: string
  body: string
  doors: string
  trim: string
  extcolorgeneric: string
  interiorcolor: string
  enginedescription: string
  fueltype: string
  drivetrain: string
  transmission: string
  miles: string
  sellingprice: string
  certified: string
  options: string
  description: string
  imagelist: string
  dateinstock: string
  enginedisplacement: string
  enginecylinders: string
  recommendsimilar: string
  organizationid: string
  record_date: string
  clean_carfax: string
  one_owner: string
  vdp_url?: string
  listing_features?: string
  vehicle_fuel_type?: string
  portal_deal_ratings?: string
  portal_urls?: string
  vehicle_color_exterior?: string
}

export interface ICarsForSalesInventory {
  id: string
  vin: string
  type: string
  stock: string
  make: string
  model: string
  year: string
  trim: string
  body: string
  miles: string
  enginedescription: string
  enginecylinders: string
  fueltype: string
  transmission: string
  sellingprice: string
  extcolorgeneric: string
  interiorcolor: string
  options: string
  description: string
  imagelist: string
  dealerid: string
  certified: string
  enginedisplacement: string
  recommendsimilar: string
  organizationid: string

  record_date: string
  clean_carfax: string
  one_owner: string
  vdp_url?: string
  listing_features?: string
  vehicle_fuel_type?: string
  portal_deal_ratings?: string
  portal_urls?: string
  vehicle_color_exterior?: string
}

export interface IDealerSpikeInventory {
  id: string
  dealerid: string
  dealername: string
  dealeraddress: string
  dealercity: string
  dealerstate: string
  dealerzip: string
  dealercountry: string
  dealercontactname: string
  dealeremail: string
  dealerphone: string
  vin: string
  stock: string
  year: string
  make: string
  model: string
  trim: string
  type: string
  miles: string
  modelcode: string
  enginedescription: string
  transmission: string
  drivetrain: string
  doors: string
  body: string
  dateinstock: string
  stockgeindays: string
  sellingprice: string
  msrp: string
  exteriorcolor: string
  interiorcolor: string
  extcolorgeneric: string
  intcolorgeneric: string
  certified: string
  dealercertified: string
  stockurl: string
  features: string
  location: string
  description: string
  showroomtitle: string
  imagelist: string
  options: string
  carfaxhighlights: string
  citympg: string
  highwaympg: string
  vehicletype: string
  subtype: string
  weight: string
  enginedisplacement: string
  enginetype: string
  horsepower: string
  height: string
  length: string
  width: string
  fueltype: string
  drive: string
  starter: string
  wheelbase: string
  groundclearance: string
  seatheight: string
  enginecylinders: string
  marketclass: string
  recommendsimilar: string
  organizationid: string
  record_date: string
  clean_carfax: string
  one_owner: string
  vdp_url: string
  listing_features: string
  vehicle_fuel_type: string
  portal_deal_ratings: string
  portal_urls: string
  vehicle_color_exterior: string
}

export type VendorInventory =
  | { vendor: 'homenet'; item: IHomenetInventory }
  | { vendor: 'vauto'; item: IVAutoInventory }
  | { vendor: 'sm360'; item: ISM360Inventory }
  | { vendor: 'dealersync'; item: IDealersyncInventory }
  | { vendor: 'carsforsales'; item: ICarsForSalesInventory }
  | { vendor: 'serti'; item: IDealersyncInventory }
  | { vendor: 'dealerspike'; item: IDealerSpikeInventory }
  | { vendor: 'edealer'; item: IEDealerInventory }
  | { vendor: 'vincue'; item: IVincueInventory }
  | { vendor: 'autosweet'; item: IAutoSweetInventory }
  | { vendor: 'dealerscloud'; item: IDealerCloudInventory }
  | { vendor: 'dealerslink'; item: IDealersLinkInventory }
  | { vendor: 'pbs'; item: IPbsInventory }
  | { vendor: 'magnetis'; item: IMagnetisInventory }

export type IInventoryType =
  | IHomenetInventory
  | IVAutoInventory
  | ISM360Inventory
  | IDealersyncInventory
  | ICarsForSalesInventory
  | IDealerSpikeInventory
  | IEDealerInventory
  | IVincueInventory
  | IAutoSweetInventory
  | IDealersLinkInventory
  | IPbsInventory
  | IMagnetisInventory

export interface IUnionInventory {
  // common
  id: string
  vin: string
  organizationid: string
  type: string
  stock: string
  year: string
  make: string
  model: string
  body: string
  trim: string
  doors?: string // missing in carsforsales
  exteriorcolor?: string
  interiorcolor: string
  enginecylinders: string
  enginedisplacement: string
  transmission: string
  miles: string
  sellingprice: string
  msrp?: string
  certified: string
  description: string
  drivetrain?: string // missing in carsforsales
  imagelist: string
  // missing in sm360
  dateinstock?: string // missing in carsforsales
  options?: string
  citympg?: string
  highwaympg?: string

  // differences in keys
  dealername: string // dealer name
  dealeraddress: string // dealer address
  dealercity: string // dealer city
  dealerstate: string // dealer state
  dealerzip: string // dealer zip
  dealeremail?: string // dealer email
  dealerphone: string // dealer phone
  wheelbasecode: string // wheelbase_code
  enginedescription: string // engine_description
  engineaspirationtype: string // engine_aspiration_type
  fueltype: string // fuel_type
  transmissionspeed: string // transmission_speed
  transmissiontype: string // transmission_description
  extcolorgeneric: string // ext_color_generic

  // homenet specific
  modelnumber?: string // dealerscloud specific
  bookvalue?: string
  invoice?: string
  'categorized options'?: string
  'dealer fax'?: string
  'comment 1'?: string
  'comment 2'?: string
  'comment 3'?: string
  'comment 4'?: string
  'comment 5'?: string
  style_description?: string
  ext_color_code?: string
  int_color_generic?: string
  int_color_code?: string
  int_upholstery?: string
  engine_block_type?: string
  epaclassification?: string
  internet_price?: string
  misc_price1?: string
  misc_price2?: string
  misc_price3?: string
  factory_codes?: string
  marketclass?: string // dealerspike specific / vincue specific
  passengercapacity?: string
  extcolorhexcode?: string
  intcolorhexcode?: string
  enginedisplacementcubicinches?: string

  // vauto specific
  disp?: string
  bodytype?: string
  drivetraindesc?: string
  drivetrainwheelct?: string
  recommendsimilar?: string
  record_date?: string

  vdp_url?: string
  listing_features?: string
  vehicle_fuel_type?: string
  portal_deal_ratings?: string
  portal_urls?: string
  vehicle_color_exterior?: string

  // sm360 specific
  dealerid?: string
  remotedatemodified?: string
  remotedateentered?: string
  status?: string
  isdemo?: string
  vehicletype?: string // dealerspike specific
  warranty?: string
  passengercount?: string
  specialmentions?: string
  inservicedate?: string
  stockurl?: string // dealerspike specific / vincue specific
  mainphoto?: string
  regularprice: string
  videoen?: string
  videofr?: string
  stockstatus?: string
  clean_carfax?: string
  one_owner?: string

  // dealerspike specific
  dealercountry?: string
  dealercontactname?: string
  modelcode?: string
  stockgeindays?: string
  intcolorgeneric?: string
  dealercertified?: string
  features?: string
  location?: string
  showroomtitle?: string
  carfaxhighlights?: string
  subtype?: string
  weight?: string
  enginetype?: string
  horsepower?: string
  height?: string
  length?: string
  width?: string
  drive?: string
  starter?: string
  wheelbase?: string
  groundclearance?: string
  seatheight?: string

  // edealer specific
  modeldescription?: string
  seats?: string
  intransit?: string
  demo?: string
  videoembedcode?: string
  suggestedprice?: string
  costprice?: string
  imagelastmodified?: string
  exteriorcolorcode?: string
  interiorcolorcode?: string
  optioncodes?: string
  trimoptions?: string
  // vincue specific
  comparetoprice?: string
  phototimestamp?: string
  lotprice?: string
  carfaxoneowner?: string
  cabtype?: string
  mfrmodel?: string
  tag?: string
  // autosweet specific
  exportdealerid: string
  series: string // dealerslink specific
  certificationtype: string
  certificationid: string
  listprice: string
  reserved1: string
  daysinstock: string
  manufacturermodelnu: string
  transmissiondescription: string
  solddate: string
  technicalspecification: string
  reserved2: string
  cost: string

  // dealerscloud specific
  intupholstery?: string
  engineblocktype?: string
  internetprice?: string
  factorycodes?: string

  // dealerslink specific
  accountid?: string
  descriptionwithhtml?: string
  vehicleurl?: string
  accessories?: string
  salestatus?: string
  customerprice?: string
  rebate?: string
  latitude?: string
  longitude?: string
  title?: string
  tagline?: string
  imageslastmodified?: string
  vehicleclass?: string
  internetplusacc?: string
  seriesdetail?: string
  imagessecure?: string
  wholesale?: string
  modelseries?: string
  customprice1?: string
  customprice2?: string
  packagecode?: string
  incentives?: string

  // pbs specific
  orderdoorkeycode?: string
  styleref?: string
  pdi?: string
  apiid?: string
  floorplancode?: string
  freight?: string
  videourl?: string
  internetnotes?: string
  isinactive?: string
  insuranceexpirydate?: string
  holdcontactref?: string
  floorplanamount?: string
  exteriorcolorbasecolor?: string
  lastservicemileage?: string
  holdholdfrom?: string
  datereceived?: string
  criticalmemo?: string
  fleetnumber?: string
  configuration?: string
  isconditionallysold?: string
  lotdescription?: string
  holduserref?: string
  retail?: string
  licensenumber?: string
  interiorcolorbasecolor?: string
  inventory?: string
  holdholduntil?: string
  shortvin?: string
  appraisedvalue?: string
  lastsaledate?: string
  seatingcapacity?: string
  holdback?: string
  lotpack?: string
  orderestimatedcost?: string
  salesdivision?: string
  showonweb?: string
  basemsr?: string
  lot?: string
  lastupdate?: string
  vehicleid?: string
  orderprice?: string
  lastservicedate?: string
  additionaldrivers?: string
  orderlocationstatusdate?: string
  totalcost?: string
  holdvehicleref?: string
  primaryimageref?: string
  displaytrim?: string
  ownerref?: string
  air?: string
  bluebookvalue?: string
  trin?: string

  // magnetis specific
  createdate?: string
  modifieddate?: string
  decodedtrim?: string
  decodedtrimen?: string
  decodedstylename?: string
  decodedstylenameen?: string
  marketingtrim?: string
  marketingtrimen?: string
  passengers?: string
  bodyen?: string
  transmissionen?: string
  fueltypeen?: string
  driveen?: string
  retailprice?: string
  invoiceprice?: string
  kms?: string
  descriptionen?: string
  exteriorcoloren?: string
  exteriorcolormfg?: string
  exteriorcolormfgen?: string
  interiorcoloren?: string
  interiorcolormfg?: string
  interiorcolormfgen?: string
  warrantydescription?: string
  warrantydescriptionen?: string
  enginesizeen?: string
  oemwarrantykm?: string
  oemwarrantydate?: string
  extendedwarrantykm?: string
  extendedwarrantydate?: string
  previoususe?: string
  previousowner?: string
  isclearance?: string
  carfaxavailable?: string
  carfaxurl?: string
  carfaxurlen?: string
  optionsen?: string
  tags?: string
  youtubevideo?: string
  youtubevideoen?: string
  iframe360url?: string
  mediacollection?: string
  chromemodelnumber?: string
  chromestyleid?: string
  syncid?: string
  chromefactorycodes?: string
  instockdate?: string
  vdpurlfr?: string
}

export interface IEDealerInventory {
  id: string
  dealerid?: string
  vin: string
  stock: string
  year: string
  make: string
  model: string
  modeldescription: string
  trim: string
  vehicletype?: string
  type: string
  miles: string
  fueltype: string
  drivetrain: string
  extcolorgeneric: string
  interiorcolor: string
  doors: string
  seats: string
  certified: string
  intransit: string
  demo: string
  transmission: string
  enginedescription: string
  description: string
  videoembedcode: string
  suggestedprice: string
  sellingprice: string
  costprice: string
  options: string
  imagelist: string
  imagelastmodified: string
  dateinstock: string
  dealername: string
  dealeremail: string
  dealeraddress: string
  dealercity: string
  dealerzip: string
  msrp: string
  body: string
  exteriorcolorcode: string
  interiorcolorcode: string
  optioncodes: string
  trimoptions: string
  enginedisplacement: string
  enginecylinders: string
  recommendsimilar: string
  organizationid: string
  record_date: string
  clean_carfax: string
  one_owner: string
  vdp_url: string
  listing_features: string
  vehicle_fuel_type: string
  portal_deal_ratings: string
  portal_urls: string
  vehicle_color_exterior: string
}
export interface IVincueInventory {
  id: string
  dealerid?: string
  vin: string
  stock: string
  year: string
  make: string
  model: string
  trim: string
  drivetrain: string
  transmission: string
  interiorcolor: string
  extcolorgeneric: string
  enginecylinders: string
  sellingprice: string
  msrp: string
  comparetoprice: string // new
  miles: string
  dateinstock: string
  imagelist: string
  phototimestamp: string // new
  body: string
  enginedisplacement: string
  features: string
  options: string
  description: string
  stockurl: string // new
  lotprice: string // new
  certified: string
  carfaxoneowner: string // new
  doors: string
  citympg: string
  highwaympg: string
  fueltype: string
  cabtype: string // new
  costprice: string
  mfrmodel: string // new
  tag: string // new
  marketclass: string // new
  enginedescription: string
  recommendsimilar: string
  organizationid: string
  type?: string
  record_date: string
  clean_carfax: string
  one_owner: string
  vdp_url: string
  listing_features: string
  vehicle_fuel_type: string
  portal_deal_ratings: string
  portal_urls: string
  vehicle_color_exterior: string
}

export interface IAutoSweetInventory {
  id: string
  dealerid: string
  exportdealerid: string
  stock: string
  vin: string
  year: string
  make: string
  model: string
  series: string
  miles: string
  extcolorgeneric: string
  interiorcolor: string
  type: string
  certified: string
  certificationtype: string
  certificationid: string
  listprice: string
  sellingprice: string
  reserved1: string
  msrp: string
  body: string
  description: string
  dateinstock: string
  daysinstock: string
  manufacturermodelnu: string
  transmissiondescription: string
  enginedescription: string
  drivetrain: string
  solddate: string
  features: string
  options: string
  technicalspecification: string
  citympg: string
  imagelist: string
  reserved2: string
  stockurl: string
  enginecylinders: string
  doors: string
  enginedisplacement: string
  fueltype: string
  transmission: string
  highwaympg: string
  trim: string
  cost: string
  marketclass: string
  recommendsimilar: string
  organizationid: string
  record_date: string
  clean_carfax: string
  one_owner: string
  vdp_url: string
  listing_features: string
  vehicle_fuel_type: string
  portal_deal_ratings: string
  portal_urls: string
  vehicle_color_exterior: string
}

export interface IDealerCloudInventory {
  id: string
  type: string
  stock: string
  vin: string
  year: string
  make: string
  model: string
  body: string
  trim: string
  modelnumber: string // new
  doors: string
  exteriorcolor: string
  interiorcolor: string
  enginecylinders: string
  enginedisplacement: string
  transmission: string
  miles: string
  sellingprice: string
  msrp: string
  bookvalue: string
  certified: string
  dateinstock: string
  description: string
  options: string
  categorizedoptions: string
  dealername: string
  dealeraddress: string
  dealercity: string
  dealerstate: string
  dealerzip: string
  dealerphone: string
  dealerfax: string
  dealeremail: string
  styledescription: string
  extcolorgeneric: string
  extcolorcode: string
  intcolorgeneric: string
  intcolorcode: string
  intupholstery: string
  engineblocktype: string
  engineaspirationtype: string
  enginedescription: string
  transmissionspeed: string
  transmissiondescription: string
  drivetrain: string
  fueltype: string
  citympg: string
  highwaympg: string
  epaclassification: string
  wheelbasecode: string
  internetprice: string
  factorycodes: string
  marketclass: string
  passengercapacity: string
  extcolorhexcode: string
  intcolorhexcode: string
  enginedisplacementcubicinches: string
  imagelist: string
  stockurl: string
  recommendsimilar: string
  organizationid: string
  record_date: string
  clean_carfax: string
  one_owner: string
  vdp_url: string
  listing_features: string
  vehicle_fuel_type: string
  portal_deal_ratings: string
  portal_urls: string
  vehicle_color_exterior: string
}

export interface IDealersLinkInventory {
  id: string
  accountid: string
  vin: string
  stock: string
  year: string
  make: string
  model: string
  series: string
  trim: string
  body: string
  msrp: string
  miles: string
  extcolorgeneric: string
  intcolorgeneric: string
  seats: string
  enginedescription: string
  drivetrain: string
  transmission: string
  fueltype: string
  type: string
  dateinstock: string
  descriptionwithhtml: string
  options: string
  imagelist: string
  vehicleurl: string
  sellingprice: string
  videourl: string
  modelcode: string
  cost: string
  dealercity: string
  vehicletype: string
  certified: string
  citympg: string
  highwaympg: string
  stockurl: string
  accessories: string
  salestatus: string
  customerprice: string
  rebate: string
  dealeraddress: string
  latitude: string
  longitude: string
  title: string
  tagline: string
  enginecylinders: string
  imageslastmodified: string
  vehicleclass: string
  internetplusacc: string
  dealerstate: string
  dealerzip: string
  dealerphone: string
  seriesdetail: string
  enginedisplacement: string
  imagessecure: string
  locationname: string
  colorcode: string
  wholesale: string
  description: string
  modelseries: string
  customprice1: string
  customprice2: string
  packagecode: string
  incentives: string
  dealername: string
  recommendsimilar: string
  organizationid: string
  record_date: string
  clean_carfax: string
  one_owner: string
  vdp_url: string
  listing_features: string
  vehicle_fuel_type: string
  portal_deal_ratings: string
  portal_urls: string
  vehicle_color_exterior: string
}

export interface IPbsInventory {
  id: string
  orderdoorkeycode: string
  styleref: string
  type: string
  pdi: string
  apiid: string
  category: string
  body: string
  floorplancode: string
  freight: string
  inservicedate: string
  vehicletype: string
  sellingprice: string
  dealerid: string
  videourl: string
  internetnotes: string
  isinactive: string
  insuranceexpirydate: string
  holdcontactref: string
  floorplanamount: string
  exteriorcolorbasecolor: string
  vin: string
  lastservicemileage: string
  holdholdfrom: string
  datereceived: string
  interiorcolorcode: string
  criticalmemo: string
  enginecylinders: string
  fleetnumber: string
  make: string
  configuration: string
  intcolorgeneric: string
  isconditionallysold: string
  lotdescription: string
  holduserref: string
  retail: string
  licensenumber: string
  interiorcolorbasecolor: string
  inventory: string
  holdholduntil: string
  enginedescription: string
  shortvin: string
  appraisedvalue: string
  exteriorcolorcode: string
  description: string
  lastsaledate: string
  seatingcapacity: string
  holdback: string
  lotpack: string
  orderestimatedcost: string
  fueltype: string
  year: string
  model: string
  salesdivision: string
  drivetrain: string
  showonweb: string
  certified: string
  basemsr: string
  lot: string
  trim: string
  lastupdate: string
  vehicleid: string
  options: string
  extcolorgeneric: string
  notes: string
  orderprice: string
  lastservicedate: string
  additionaldrivers: string
  orderlocationstatusdate: string
  totalcost: string
  modelnumber: string
  weight: string
  holdvehicleref: string
  transmission: string
  primaryimageref: string
  displaytrim: string
  ownerref: string
  air: string
  miles: string
  internetprice: string
  stock: string
  bluebookvalue: string
  trin: string
  dealeraddress: string
  enginedisplacement: string
  recommendsimilar: string
  organizationid: string
  record_date: string
  clean_carfax: string
  one_owner: string
  vdp_url: string
  listing_features: string
  vehicle_fuel_type: string
  portal_deal_ratings: string
  portal_urls: string
  vehicle_color_exterior: string
}

export interface IMagnetisInventory {
  id: string
  type: string
  createdate: string
  modifieddate: string
  make: string
  model: string
  year: string
  vin: string
  stock: string
  trim: string
  decodedtrim: string
  decodedtrimen: string
  decodedstylename: string
  decodedstylenameen: string
  marketingtrim: string
  marketingtrimen: string
  doors: string
  passengers: string
  body: string
  bodyen: string
  transmission: string
  transmissionen: string
  fueltype: string
  fueltypeen: string
  drivetrain: string
  driveen: string
  price: string
  retailprice: string
  invoiceprice: string
  msrp: string
  kms: string
  description: string
  descriptionen: string
  exteriorcolor: string
  exteriorcoloren: string
  exteriorcolormfg: string
  exteriorcolormfgen: string
  interiorcolor: string
  interiorcoloren: string
  interiorcolormfg: string
  interiorcolormfgen: string
  warranty: string
  warrantydescription: string
  warrantydescriptionen: string
  enginedescription: string
  enginesizeen: string
  enginecylinders: string
  enginedisplacement: string
  oemwarrantykm: string
  oemwarrantydate: string
  extendedwarrantykm: string
  extendedwarrantydate: string
  previoususe: string
  previousowner: string
  certified: string
  isclearance: string
  carfaxavailable: string
  carfaxurl: string
  carfaxurlen: string
  mainphoto: string
  imagelist: string
  options: string
  optionsen: string
  reserved: string
  tags: string
  youtubevideo: string
  youtubevideoen: string
  iframe360url: string
  mediacollection: string
  colorcode: string
  chromemodelnumber: string
  chromestyleid: string
  syncid: string
  chromefactorycodes: string
  instockdate: string
  dealeraddress: string
  vdpurlfr: string
  stockurl: string
  sellingprice: string
  dealername: string
  recommendsimilar: string
  organizationid: string
  record_date: string
  clean_carfax: string
  one_owner: string
  vdp_url: string
  listing_features: string
  vehicle_fuel_type: string
  portal_deal_ratings: string
  portal_urls: string
  vehicle_color_exterior: string
}
