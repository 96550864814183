import { createSelector, createSlice } from '@reduxjs/toolkit'

import { IBroadcastingState } from '@/types'
import { RootState } from '@/store'
import { mapColumns } from '@/constants/broadcasting'

const initialState: IBroadcastingState = {
  organization: '',
  updateCustomer: false,
  receivedDays: '',
  exclude_archived: false,
  exclude_dnc: false,
  file: undefined,
  uploadStep: undefined,
  data: [],
  uploadedData: [],
  mapColumns,
  name: '',
  rows_per_segment: '',
  message: '',
  verifyDataFilter: {
    showReplaceModal: false,
  },
  broadcasting_id: '',
  segments: [],
  findForm: {
    column: '',
    findValue: '',
  },
  errors: {},
}

const broadcastSlice = createSlice({
  name: 'broadcastSlice',
  initialState,
  reducers: {
    clearAll: state => {
      state.segments = []
      state.uploadedData = []
      state.data = []
      state.name = ''
      state.rows_per_segment = ''
      state.message = ''
      state.broadcasting_id = ''
      state.uploadStep = undefined
      state.mapColumns = mapColumns
      state.verifyDataFilter = {
        showReplaceModal: false,
      }
      state.file = undefined
      state.exclude_archived = false
      state.exclude_dnc = false
      state.organization = ''
      state.receivedDays = ''
      state.updateCustomer = false
    },
    onStateChange: (
      state,
      action: { type: string; payload: Partial<IBroadcastingState> },
    ) => {
      const fields = Object.keys(action.payload)
      fields.forEach(field => {
        // @ts-ignore
        state[field] = action.payload[field]
      })
    },
    onStepChange: (state, action) => {
      state.uploadStep = action.payload
    },
    onMapColumnsChange: (state, action) => {
      const { key, value } = action.payload
      state.mapColumns = state.mapColumns.map(el => {
        if (el.templateField === key) {
          return {
            ...el,
            column: value,
          }
        }
        return el
      })
    },
    onVerifyDataFilterChange: (state, action) => {
      state.verifyDataFilter = {
        ...state.verifyDataFilter,
        ...action.payload,
      }
    },
  },
})

export const dataHeaders = createSelector(
  (state: RootState) => state.broadcasting,
  state => {
    if (state.uploadedData.length) {
      return Object.keys(state.uploadedData[0])
    }
    return []
  },
)

export const {
  clearAll,
  onVerifyDataFilterChange,
  onStateChange,
  onStepChange,
  onMapColumnsChange,
} = broadcastSlice.actions
export default broadcastSlice.reducer
