import React from 'react'
import { AutomationStatus } from '@/types'
import { Button, IconButton, Typography } from '@/components'

import { ReactComponent as ErrorIcon } from '@/assets/icons/Error.svg'
import { ReactComponent as CloseIcon } from '@/assets/icons/Close.svg'
interface DeliverabilityScoreCardProps {
  show?: boolean
  score?: number
  onClose: () => void
}

const DeliverabilityScoreCard = ({
  show,
  score,
  onClose,
}: DeliverabilityScoreCardProps) => {
  return (
    <div className="rounded-2xl border border-bg-stroke shadow-modal p-8 flex flex-col gap-6 w-[672px] absolute -bottom-4 translate-y-full">
      <div className="flex flex-col gap-3">
        <div className="flex gap-2 relative">
          <IconButton
            wrapperClassName="bg-green-50 text-green-100"
            Icon={ErrorIcon}
            status={AutomationStatus.FAILED}
            size={32}
          />
          <Typography variant="H3" weight="semibold">
            Deliverability Score:
          </Typography>
          <Typography variant="H3" weight="semibold" className="text-green-100">
            {show ? score : 'N/A'}
          </Typography>
          <div
            className="absolute top-0 right-0 cursor-pointer"
            onClick={onClose}
          >
            <CloseIcon className="w-6 h-6 " />
          </div>
        </div>
        <Typography variant="small" className="text-black-50">
          This Text message is likely a carrier violation and will have a low
          delivery rate.
        </Typography>
      </div>
      <div className="border-t border-t-bg-stroke pt-4">
        <ul className="list-disc pl-6">
          <li>
            <Typography variant="regular" weight="medium">
              Your message is too long, consider shortening it to less than 160
              characters
            </Typography>
          </li>
        </ul>
      </div>
      <div className="flex gap-2 justify-end">
        <Button status="secondary" size="large">
          Review Copy
        </Button>
        <Button status="primary" size="large">
          Send Anyway
        </Button>
      </div>
    </div>
  )
}

export default DeliverabilityScoreCard
