import React, { useCallback, useState } from 'react'
import { Button } from '@/components'
import ReplaceModal from '@/pages/SuccessPortal/Broadcasting/FileUpload/ReplaceModal'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onVerifyDataFilterChange } from '@/store/slices/pages/successPortal/broadcasting.slice'
import AddModal from '@/pages/SuccessPortal/Broadcasting/FileUpload/AddModal'
import { ReactComponent as SearchIcon } from '@/assets/icons/Search.svg'
import { ReactComponent as AddIcon } from '@/assets/icons/Plus.svg'

const VerifyDataFilter = () => {
  const dispatch = useAppDispatch()
  const {
    verifyDataFilter: { showReplaceModal },
  } = useAppSelector(state => state.broadcasting)
  const [showAdd, setShowAdd] = useState(false)

  const onModalChange = useCallback(
    (val: boolean) => {
      dispatch(onVerifyDataFilterChange({ showReplaceModal: val }))
    },
    [dispatch],
  )

  const onAdd = useCallback(() => {
    setShowAdd(true)
  }, [])
  return (
    <>
      <ReplaceModal
        open={showReplaceModal}
        onClose={() => onModalChange(false)}
      />
      <AddModal open={showAdd} onClose={() => setShowAdd(false)} />

      <div className="flex items-center gap-4">
        <Button
          status="secondary"
          size="small"
          onClick={() => onModalChange(true)}
          IconLeft={SearchIcon}
        >
          Find and Replace
        </Button>
        <Button
          status="secondary"
          size="small"
          onClick={onAdd}
          IconLeft={AddIcon}
        >
          Add
        </Button>
      </div>
    </>
  )
}

export default VerifyDataFilter
