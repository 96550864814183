import ReactSelect, {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  MultiValueProps,
  Props,
  OptionProps,
  GroupBase,
} from 'react-select'
import clsx from 'clsx'

import { ReactComponent as ChevronDownIcon } from '@/assets/icons/Chevron Down.svg'
import { ReactComponent as CloseIcon } from '@/assets/icons/Close.svg'
import { ReactComponent as TimeIcon } from '@/assets/icons/Clock.svg'
import { Checkbox, Typography } from '@/components'
import React, { ReactNode } from 'react'

export type Option<T extends string = string> = {
  _id: T
  name: string
  disabled?: boolean
}

interface SelectProps<OptionType = Option, IsMulti extends boolean = false>
  extends Props<OptionType, IsMulti, GroupBase<OptionType>> {
  variant?: 'light' | 'dark' | 'header'
  disabled?: boolean
  error?: string
  label?: ReactNode
  timeIcon?: boolean
}

const DropdownIndicator = <
  OptionType extends Option<string>,
  IsMulti extends boolean,
>(
  props: DropdownIndicatorProps<OptionType, IsMulti, GroupBase<OptionType>>,
) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon />
    </components.DropdownIndicator>
  )
}

const CustomDropdownIndicator = <
  OptionType extends Option<string>,
  IsMulti extends boolean,
>(
  props: DropdownIndicatorProps<OptionType, IsMulti, GroupBase<OptionType>>,
) => {
  return (
    <components.DropdownIndicator {...props}>
      <TimeIcon />
    </components.DropdownIndicator>
  )
}

const ClearIndicator = <
  OptionType extends Option<string>,
  IsMulti extends boolean,
>(
  props: ClearIndicatorProps<OptionType, IsMulti, GroupBase<OptionType>>,
) => {
  return (
    <components.ClearIndicator {...props}>
      <CloseIcon />
    </components.ClearIndicator>
  )
}

const CustomMultiValue = <
  OptionType extends Option<string>,
  IsMulti extends boolean,
>(
  props: MultiValueProps<OptionType, IsMulti, GroupBase<OptionType>>,
) => {
  const { index, getValue } = props
  const values = getValue()

  const concatenated = values.map((option: any) => option.name).join(', ')

  return index === 0 ? (
    <div {...props.innerProps} className="truncate w-full">
      <span>{concatenated}</span>
    </div>
  ) : null
}

const CustomMultiOption = <
  OptionType extends Option<string>,
  IsMulti extends boolean,
>(
  props: OptionProps<OptionType, IsMulti, GroupBase<OptionType>>,
) => {
  const { data, isSelected, isFocused, isDisabled } = props

  return (
    <div
      {...props.innerRef}
      {...props.innerProps}
      className={clsx(
        'flex items-center px-3 py-2 rounded-lg cursor-pointer transition-colors',
        isFocused ? 'bg-blue-10' : '',
        isDisabled ? 'opacity-50 cursor-not-allowed' : '',
      )}
    >
      <span className="flex-1">{data.name}</span>
      <Checkbox checked={isSelected} />
    </div>
  )
}

const CustomOption = <
  OptionType extends Option<string>,
  IsMulti extends boolean,
>(
  props: OptionProps<OptionType, IsMulti, GroupBase<OptionType>>,
) => {
  const { data, isFocused, isDisabled, className } = props

  return (
    <div
      {...props.innerRef}
      {...props.innerProps}
      className={clsx(
        'flex items-center px-3 py-2 rounded-lg cursor-pointer transition-colors',
        isFocused ? 'bg-blue-10' : '',
        isDisabled ? 'opacity-50 cursor-not-allowed' : '',
        className,
      )}
    >
      <span className="flex-1">{data.name}</span>
    </div>
  )
}

const containerStyles = {
  base: 'w-full',
  light: '',
  header: 'bg-[#080224]',
  dark: 'bg-[#080224]',
}

const controlStyles = {
  base: 'gap-3 group border rounded-xl  cursor-pointer transition-all duration-300  w-full pl-4 py-2 pr-3  h-10',
  light: 'bg-white border-[#E7EAF0] hover:border-blue-40',
  dark: 'border-[#302E47] bg-[#242042] hover:border-blue-40 hover:border-opacity-100 text-white-100',
  header:
    'bg-[#080224] border-white-100 border-opacity-50 hover:border-opacity-100 text-white-100',
  error: 'border-red-100 hover:border-red-100',
  focus: '!border-blue-80',
  disabled: 'bg-gray-80 cursor-not-allowed',
}
const placeholderStyles = {
  light: {
    base: 'text-black-50 group-hover:text-black-100 transition-all duration-200',
    focus: '!text-black-100',
  },
  header: {
    base: 'text-white-100 text-opacity-50 group-hover:text-white-100 transition-all duration-200',
    focus: 'text-opacity-100',
  },
  dark: {
    base: 'text-[#A3A6BE] group-hover:text-white-100 transition-all duration-200',
    focus: 'text-white-100',
  },
  error: 'text-red-100 hover:text-red-100  group-hover:text-red-100',
}
const selectInputStyles = {
  light: '',
  header: 'text-white-100',
  dark: 'text-[#A3A6BE]',
  base: 'h-[18px] text-sm',
  error: 'text-red-100',
}
const valueContainerStyles = 'gap-1 text-sm flex !flex-nowrap font-medium'
const singleValueStyles = 'text-sm'
const multiValueStyles = 'bg-blue-10 rounded-lg items-center py-1 px-3 gap-2'
const multiValueLabelStyles = 'text-sm'
const multiValueRemoveStyles = 'hover:bg-red-50 hover:text-red-100'
const indicatorsContainerStyles = 'gap-1'
const clearIndicatorStyles = 'hidden'
const indicatorSeparatorStyles = 'hidden'
const dropdownIndicatorStyles = {
  light: 'text-black-100 shrink-0 hover:bg-transparent p-0',
  header: 'text-white-100 shrink-0 hover:bg-transparent p-0',
  dark: 'text-[#A4A5A6] group-hover:text-white-100 shrink-0 hover:bg-transparent p-0',
  error: 'text-red-100',
}

const menuStyles =
  'p-1 mt-1 border border-blue-80 bg-white-100 rounded-xl shadow-select'
const groupHeadingStyles = 'ml-3 mt- mb-1 text-gray-500 text-sm'
const optionStyles = {
  base: 'hover:cursor-pointer px-3 py-2 rounded-lg hover:bg-blue-10',
  selected: 'hover:bg-blue-10 bg-blue-10',
}

export const Select = <
  OptionType extends Option,
  IsMulti extends boolean = false,
>(
  props: SelectProps<OptionType, IsMulti>,
) => {
  const {
    isMulti = false,
    options = [],
    variant = 'light',
    disabled,
    label,
    error,
    className,
    timeIcon,
    isClearable,
    ...rest
  } = props

  return (
    <div className={clsx('flex flex-col gap-1 w-full', className)}>
      {label && (
        <Typography className="text-black-80" variant="small" weight="medium">
          {label}
        </Typography>
      )}
      <ReactSelect
        isClearable={isClearable}
        isMulti={isMulti as IsMulti}
        options={options}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={false}
        unstyled
        styles={{
          input: base => ({
            ...base,
            'input:focus': {
              boxShadow: 'none',
            },
          }),
          control: base => ({
            ...base,
            transition: 'none',
          }),
          menuList: base => ({
            ...base,
            maxHeight: '500px',
            overflowY: 'auto',
            zIndex: 100,
          }),
        }}
        components={{
          DropdownIndicator: timeIcon
            ? CustomDropdownIndicator
            : DropdownIndicator,
          ClearIndicator,
          MultiValue: CustomMultiValue,
          Option: isMulti ? CustomMultiOption : CustomOption,
        }}
        classNames={{
          container: () => clsx(containerStyles.base, containerStyles[variant]),
          control: ({ isFocused, isDisabled }) =>
            clsx(
              isFocused && !error && controlStyles.focus,
              controlStyles.base,
              isDisabled && controlStyles.disabled,
              controlStyles[variant],
              error && controlStyles.error,
            ),
          placeholder: ({ isFocused }) =>
            clsx(
              isFocused && !error && placeholderStyles[variant].focus,
              placeholderStyles[variant].base,
              error && placeholderStyles.error,
            ),
          input: () =>
            clsx(selectInputStyles.base, selectInputStyles[variant], {
              [selectInputStyles.error]: error,
            }),
          valueContainer: () => valueContainerStyles,
          singleValue: () => singleValueStyles,
          multiValue: () => multiValueStyles,
          multiValueLabel: () => multiValueLabelStyles,
          multiValueRemove: () => multiValueRemoveStyles,
          indicatorsContainer: () => indicatorsContainerStyles,
          clearIndicator: () => clearIndicatorStyles,
          indicatorSeparator: () => indicatorSeparatorStyles,
          dropdownIndicator: () =>
            clsx(dropdownIndicatorStyles[variant], {
              [dropdownIndicatorStyles.error]: error,
            }),
          menu: () => menuStyles,
          groupHeading: () => groupHeadingStyles,
          option: ({ isSelected }) =>
            clsx(isSelected && optionStyles.selected, optionStyles.base),
        }}
        getOptionLabel={option => option.name}
        getOptionValue={option => option._id.toString()}
        isDisabled={props.isDisabled || disabled}
        isOptionDisabled={option => !!option.disabled}
        {...rest}
      />
    </div>
  )
}
export default Select
