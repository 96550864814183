import { memo } from 'react'
import { ADMIN_BOTTOM_MENU, ADMIN_MAIN_MENU, ORGANIZATION } from '@/constants'
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom'
import { Typography } from '@/components'
import { generatePath } from '@/utils'
import clsx from 'clsx'

interface ISubmenuSectionProps {
  onClick?: (key: string) => void
}

const SubmenuSection = ({ onClick }: ISubmenuSectionProps) => {
  const resolvedPath = useResolvedPath(ORGANIZATION)

  const match = useMatch({
    path: resolvedPath.pathname,
    end: false,
  })

  const currentMenuItem = [...ADMIN_MAIN_MENU, ...ADMIN_BOTTOM_MENU].find(
    menu =>
      menu.path === location.pathname ||
      match?.pattern?.path === menu.path ||
      menu.subMenus?.some(subMenu => subMenu.path === location.pathname),
  )
  return (
    <div className="flex items-center gap-6 w-full overflow-auto">
      {currentMenuItem?.subMenus?.map(menu => {
        return (
          <NavLink
            className={({ isActive }) =>
              clsx(
                'block relative text-[#77808D] pb-4 after:h-[3px] after:absolute after:w-0 after:bottom-0 left-0 after:transition-all after:bg-blue-80 shrink-0',
                {
                  'text-black-100 after:w-full after:duration-300': isActive,
                },
              )
            }
            end
            to={
              match?.params ? generatePath(menu.path, match.params) : menu.path
            }
            key={menu.key}
            onClick={event => {
              if (menu.isClickable) {
                event.preventDefault()
                onClick?.(menu.key)
              }
            }}
          >
            <Typography
              variant="regular"
              weight="medium"
              as="span"
              className="block flex gap-2 items-center"
            >
              {menu.title}
              {menu.titleExtra && menu.titleExtra}
            </Typography>
          </NavLink>
        )
      })}
    </div>
  )
}

export default memo(SubmenuSection)
