import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Drawer, DrawerSize, Typography } from '@/components'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  onStateChange,
  onStepChange,
} from '@/store/slices/pages/successPortal/broadcasting.slice'
import SubmitModal from '@/pages/SuccessPortal/Broadcasting/FileUpload/SubmitModal'
import VerifyDataFilter from '@/pages/SuccessPortal/Broadcasting/FileUpload/VerifyDataModal/Filter'
import VerifyDataTable from '@/pages/SuccessPortal/Broadcasting/FileUpload/VerifyDataModal/Table'
import { useUploadFileMutation } from '@/api/services/success-portal/broadcasting'
import { convertToCSV } from '@/utils/csv'
import WarningModal from '@/pages/SuccessPortal/Broadcasting/FileUpload/WarningModal'
import toast from 'react-hot-toast'
import { IInvalidDataCount } from '@/types'

const VerifyDataModal = () => {
  const dispatch = useAppDispatch()
  const { uploadStep, uploadedData, mapColumns, name, rows_per_segment } =
    useAppSelector(state => state.broadcasting)
  const [showSubmitModal, setShowSubmitModal] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [uploadFile, { isLoading, isSuccess, error }] = useUploadFileMutation()

  const onModalChange = useCallback(
    (val?: number) => {
      dispatch(onStepChange(val))
    },
    [dispatch],
  )

  useEffect(() => {
    if (isLoading) {
      setShowSubmitModal(true)
    }
  }, [isLoading])

  const handleUpload = useCallback(() => {
    const csv = convertToCSV(uploadedData)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const columns = mapColumns
      .filter(el => el.column)
      .map(el => `"${el.key}":"${el.column}"`)
      .join(',')

    uploadFile({
      file: new File([blob], name + '.csv'),
      rows_per_segment,
      column_mapping: `{${columns}}`,
      name,
    })
      .unwrap()
      .then(res => {
        if (res) {
          const info = { ...res }
          dispatch(
            onStateChange({
              broadcasting_id: info.dataset_upload,
              segments: info.segments,
            }),
          )
          localStorage.setItem('broadcasting_id', res.dataset_upload)
        }
      })
      .catch(() => {
        toast.error('Failed to upload file')
      })
  }, [name, uploadedData, dispatch, mapColumns, rows_per_segment, uploadFile])

  const invalidDataCount = useMemo(() => {
    const result: IInvalidDataCount = {
      count: 0,
      first_name: 0,
      last_name: 0,
      phone_number: 0,
      vehicle_make: 0,
      vehicle_model: 0,
      vehicle_year: 0,
      email: 0,
    }

    return uploadedData.reduce((acc, cur) => {
      let alreadyCalculated = false
      mapColumns.forEach(({ column, key }) => {
        if (!column || !cur[column]) {
          acc[key]++
          if (key === 'phone_number') {
            if (!alreadyCalculated) {
              acc.count++
              alreadyCalculated = true
            }
          }
        } else {
          if (key === 'phone_number') {
            if (
              !cur[column]
                .toString()
                .replace(/\D/g, '')
                .match(/^\d{10}$/)
            ) {
              acc.phone_number++
              if (!alreadyCalculated) {
                acc.count++
                alreadyCalculated = true
              }
            }
          }
        }
      })
      return acc
    }, result)
  }, [mapColumns, uploadedData])

  return (
    <>
      <Drawer
        open={uploadStep === 2}
        onClose={onModalChange}
        title={
          <div className="flex items-center gap-2">
            Verify Data
            <Typography
              variant="small"
              className="text-black-50"
              weight="semibold"
            >
              {uploadedData.length} data row(s)
            </Typography>
            {invalidDataCount.count > 0 && (
              <Typography variant="small" className="text-red-100">
                <span className="font-semibold">{invalidDataCount.count}</span>{' '}
                invalid data row(s)
              </Typography>
            )}
          </div>
        }
        size={DrawerSize.XL}
      >
        <div className="flex flex-col gap-4 grow">
          <VerifyDataFilter />
          <VerifyDataTable invalidDataCount={invalidDataCount} />
          <div className="mt-auto flex gap-2 justify-end">
            <Button
              status="secondary"
              size="large"
              onClick={() => onModalChange(1)}
            >
              Previous
            </Button>
            <Button
              status="primary"
              size="large"
              onClick={() => {
                if (invalidDataCount.count > 0) {
                  setShowWarningModal(true)
                } else {
                  setShowSubmitModal(true)
                  handleUpload()
                }
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </Drawer>
      <SubmitModal
        open={showSubmitModal}
        onClose={() => setShowSubmitModal(false)}
        isSuccess={isSuccess}
      />
      <WarningModal
        open={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        onConfirm={() => {
          handleUpload()
          setShowWarningModal(false)
        }}
        invalidDataCount={invalidDataCount.count}
      />
    </>
  )
}

export default VerifyDataModal
