import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { parseJsonLogic } from 'react-querybuilder/parseJsonLogic'
import {
  ActionProps,
  ActionWithRulesAndAddersProps,
  ActionWithRulesProps,
  CombinatorSelectorProps,
  formatQuery,
  OperatorSelectorProps,
  QueryBuilder as QueryBuilderBase,
  ValueEditorProps,
  ValueSelectorProps,
} from 'react-querybuilder'
import 'react-querybuilder/dist/query-builder.scss'

import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onFormChange } from '@/store/slices/workflow.slice'
import { defaultCombinator, initialQuery } from '@/constants/automations'
import Card from '@/components/Card'
import { Button, Input, Select, Typography } from '@/components'

import { ReactComponent as PlusIcon } from '@/assets/icons/Plus.svg'
import { ReactComponent as CloseIcon } from '@/assets/icons/Close.svg'

interface CustomCombinatorProps {
  options: { label: string; name: string; value: string }[]
  value?: string
  onChange: (value?: string) => void
}

const RemoveButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      className="outline-none border-none p-2 bg-red-100 text-white-100 rounded-md"
      onClick={onClick}
    >
      <CloseIcon />
    </button>
  )
}
const CustomSelect = ({ options, value, onChange }: CustomCombinatorProps) => {
  const customOptions =
    options?.map(option => ({
      name: option.label,
      _id: option.name,
    })) || []
  const customValue = customOptions.find(item => item._id === value)
  return (
    <Select
      options={customOptions}
      value={customValue}
      onChange={newValue => {
        onChange(newValue?._id)
      }}
    />
  )
}

const CustomValueEditor = ({
  value,
  options,
  onChange,
  type,
}: CustomCombinatorProps & { type: string }) => {
  if (type === 'select') {
    return <CustomSelect value={value} options={options} onChange={onChange} />
  }
  return (
    <Input
      value={value}
      onChange={e => {
        onChange(e.target.value)
      }}
    />
  )
}

const QueryBuilder = () => {
  const dispatch = useAppDispatch()
  const { workFlowId } = useParams()
  const isNewWorkflow = workFlowId === 'new'
  const { form } = useAppSelector(state => state.workflow)
  const [query, setQuery] = React.useState(
    parseJsonLogic(
      isNewWorkflow
        ? (form?.template?.combinator ?? defaultCombinator)
        : (form.combinator ?? defaultCombinator),
    ) || initialQuery,
  )

  const onQueryChange = useCallback(
    (q: any) => {
      setQuery(q)
      dispatch(onFormChange({ combinator: formatQuery(q, 'jsonlogic') }))
    },
    [dispatch],
  )

  const elements = useMemo(
    () => ({
      valueEditor: (props: ValueEditorProps) => {
        if (props.operator === 'null' || props.operator === 'notNull') {
          return null
        }
        return (
          <CustomValueEditor
            // @ts-ignore
            type={props.fieldData.valueEditorType}
            value={props.value}
            // @ts-ignore
            options={props.fieldData.values}
            onChange={props.handleOnChange}
          />
        )
      },
      combinatorSelector: (props: CombinatorSelectorProps) => (
        <CustomSelect
          value={props.value}
          // @ts-ignore
          options={props.options}
          onChange={props.handleOnChange}
        />
      ),
      operatorSelector: (props: OperatorSelectorProps) => (
        <CustomSelect
          value={props.value}
          // @ts-ignore
          options={props.fieldData.operators}
          onChange={props.handleOnChange}
        />
      ),
      valueSelector: (props: ValueSelectorProps) => {
        return (
          <CustomSelect
            value={props.value}
            // @ts-ignore
            options={props.options}
            onChange={props.handleOnChange}
          />
        )
      },
      addRuleAction: (props: ActionWithRulesAndAddersProps) => {
        return (
          <Button
            status="primary"
            size="small"
            IconLeft={PlusIcon}
            onClick={props.handleOnClick}
          >
            Rule
          </Button>
        )
      },
      addGroupAction: (props: ActionWithRulesAndAddersProps) => {
        return (
          <Button
            status="dark"
            size="small"
            IconLeft={PlusIcon}
            onClick={props.handleOnClick}
          >
            Group
          </Button>
        )
      },
      removeGroupAction: (props: ActionWithRulesProps) => {
        return <RemoveButton onClick={props.handleOnClick} />
      },
      removeRuleAction: (props: ActionProps) => {
        return <RemoveButton onClick={props.handleOnClick} />
      },
    }),
    [],
  )

  return (
    <div className="combinator">
      <Typography
        variant="regular"
        weight="medium"
        className="text-black-80 mb-4"
      >
        Add filters and criteria on which you want to base communications for
        this workflow.
      </Typography>
      <Card>
        <QueryBuilderBase
          // @ts-ignore
          query={query}
          onQueryChange={onQueryChange}
          // @ts-ignore
          fields={form?.template?.actions}
          controlClassnames={{
            queryBuilder: 'rounded-lg border-none',
            ruleGroup: 'rounded-lg bg-white-100 border-none rule-group',
          }}
          controlElements={elements}
        />
      </Card>
    </div>
  )
}

export default QueryBuilder
