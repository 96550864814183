import React, { useEffect, useMemo, useState } from 'react'
import {
  defaultWeekdays,
  generateMonthDayOptions,
  hourOptions,
  minuteOptions,
} from '@/constants/automations'
import { useGetOrganizationAutomationsQuery } from '@/api/services/organization/organization-automation'
import { useParams } from 'react-router-dom'
import { IFrequency, IRule, ISingOrganizationType } from '@/types'
import {
  combineSchedules,
  extractAutomationSchedules,
  getWeekDaysOverlapHours,
} from '@/utils'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onFormChange } from '@/store/slices/pages/organization/organization-automations.slice'
import moment from 'moment-timezone'
import { Button, Checkbox, Radio, Select, Typography } from '@/components'
import Card from '@/components/Card'

interface FrequencyItemProps {
  frequency: IFrequency
  onChange: (index: number) => (field: string, value: any) => void
  organization?: ISingOrganizationType
  index: number
  onDelete?: () => void
  rule?: IRule
}

const monthDayOptions = generateMonthDayOptions()

const FrequencyItem = ({
  frequency,
  onChange,
  organization,
  onDelete,
  index,
  rule,
}: FrequencyItemProps) => {
  const {
    form: { edit, automationId, frequencies },
  } = useAppSelector(state => state.organizationAutomations)
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const [weekdays, setWeekdays] =
    useState<Record<string, boolean>>(defaultWeekdays)
  const { data: automations = [] } = useGetOrganizationAutomationsQuery({ id })
  const overlapMinMax = useMemo(() => {
    const isDay = frequency?.increment === 'day'
    return organization?.workingHours
      ? getWeekDaysOverlapHours(
          organization?.workingHours,
          isDay
            ? moment.weekdays()
            : Object.keys(weekdays).filter(day => weekdays[day]),
        )
      : { minStart: 0, maxEnd: 0 }
  }, [frequency?.increment, organization?.workingHours, weekdays])

  useEffect(() => {
    const hour = 60 * Number(frequency?.time.hour)
    const minute =
      Number(frequency?.time.minute) + 60 * Number(frequency?.time.hour)
    if (
      !(hour >= overlapMinMax.minStart && hour <= overlapMinMax.maxEnd) ||
      !(minute >= overlapMinMax.minStart && minute <= overlapMinMax.maxEnd)
    ) {
      dispatch(
        onFormChange({
          hour: '',
          minute: '',
        }),
      )
    }
  }, [
    dispatch,
    frequency?.time.hour,
    frequency?.time.minute,
    overlapMinMax,
    weekdays,
  ])

  const scheduledTimes = useMemo(() => {
    const filteredAutomations = edit
      ? automations.filter(el => el._id !== automationId)
      : automations
    const weekdayList = moment.weekdays()
    const selectedDays = weekdayList.filter(day => weekdays[day])
    const currentFrequency = filteredAutomations.map(element => {
      const { frequencies } = element
      if (!frequencies) return {}
      const mappedFreq = frequencies.map(freq => {
        return extractAutomationSchedules(freq, selectedDays)
      })
      return mappedFreq.reduce((acc, cur) => {
        const keys = Object.keys(cur)
        keys.forEach(key => {
          acc[key] = acc[key] ? { ...acc[key], ...cur[key] } : { ...cur[key] }
        })
        return acc
      }, {})
    })
    return combineSchedules(currentFrequency)
  }, [automations, automationId, edit, weekdays])

  const disabledHours = useMemo(() => {
    return hourOptions.map(hour => {
      const time = Number(hour._id) * 60
      return {
        ...hour,
        disabled: !(
          time >= overlapMinMax.minStart && time <= overlapMinMax.maxEnd
        ),
      }
    })
  }, [overlapMinMax])

  const disabledMinutes = useMemo(() => {
    return minuteOptions.map(minute => {
      const time = Number(minute._id) + 60 * Number(frequency.time.hour)
      const scheduledMinutes = Object.values(scheduledTimes).reduce<
        (number | string)[]
      >((cur, acc) => {
        const filteredByTime = acc.filter(
          val => Number(val.hour) === Number(frequency.time.hour),
        )
        filteredByTime.forEach(el => cur.push(el.minute))
        return cur
      }, [])
      return {
        ...minute,
        disabled:
          !(time >= overlapMinMax.minStart && time <= overlapMinMax.maxEnd) ||
          scheduledMinutes.includes(Number(minute._id)),
      }
    })
  }, [
    frequency.time.hour,
    overlapMinMax.maxEnd,
    overlapMinMax.minStart,
    scheduledTimes,
  ])

  const disabledDays = useMemo(() => {
    const days: string[] = []
    frequencies
      .filter((el, idx) => index !== idx)
      .map(freq => {
        days.push(...freq.weekdays)
      })
    return [...new Set(days)]
  }, [frequencies, index])

  useEffect(() => {
    const _weekdays: Record<string, boolean> = {}
    moment.weekdays().forEach(day => {
      // @ts-ignore
      _weekdays[day] = frequency?.weekdays?.includes(
        day.substring(0, 3).toLowerCase(),
      )
    })
    setWeekdays(_weekdays)
  }, [frequency?.weekdays])

  const isAllChecked = useMemo(() => {
    return moment.weekdays().every(day => weekdays[day])
  }, [weekdays])

  return (
    <Card className="!shadow-none">
      <div className="mb-6">
        <Typography variant="medium" className="mb-4">
          Setup the broadcast launch sequence
        </Typography>
        <div className="w-full">
          <div className="flex flex-col gap-6">
            <div className="flex  gap-12 items-start">
              <Radio
                className="flex gap-12 shrink-0 w-full"
                options={[
                  {
                    value: 'weekday',
                    label: 'Daily',
                  },
                  {
                    value: 'day',
                    label: 'Once per month',
                  },
                ]}
                value={frequency.increment}
                onChange={val => onChange(index)('increment', val)}
              />
            </div>
            {frequency.increment === 'weekday' && (
              <div className="overflow-x-auto flex flex-col gap-3 whitespace-nowrap h-full">
                <Checkbox
                  label={isAllChecked ? 'Unselect All' : 'Select All'}
                  checked={isAllChecked}
                  onChange={() => {
                    dispatch(
                      onFormChange({
                        frequencies: frequencies.map((freq, idx) => {
                          if (index === idx) {
                            return {
                              ...freq,
                              weekdays: isAllChecked
                                ? []
                                : moment
                                    .weekdays()
                                    .map(day =>
                                      day.substring(0, 3).toLowerCase(),
                                    ),
                            }
                          }
                          return freq
                        }),
                      }),
                    )
                  }}
                />
                <div className="flex gap-3 lg:flex-row flex-col">
                  {moment.weekdays().map(day => (
                    <Checkbox
                      key={day}
                      label={day.substring(0, 3)}
                      checked={weekdays[day]}
                      onChange={() => {
                        dispatch(
                          onFormChange({
                            frequencies: frequencies.map((freq, idx) => {
                              if (index === idx) {
                                const _day = day.substring(0, 3).toLowerCase()
                                return {
                                  ...freq,
                                  weekdays: freq.weekdays.includes(_day)
                                    ? freq.weekdays.filter(d => d !== _day)
                                    : [...freq.weekdays, _day],
                                }
                              }
                              return freq
                            }),
                          }),
                        )
                      }}
                      disabled={disabledDays.includes(
                        day.substring(0, 3).toLowerCase(),
                      )}
                    />
                  ))}
                </div>
              </div>
            )}
            {frequency.increment === 'day' && (
              <div className="w-full">
                <Select
                  label="Select day of the month"
                  onChange={val => {
                    onChange(index)('days', val?._id ? [Number(val._id)] : [])
                  }}
                  value={monthDayOptions.find(
                    day => Number(day._id) === frequency.days?.[0],
                  )}
                  options={monthDayOptions}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className=" gap-1">
          <Typography
            variant="regular"
            weight="medium"
            className="flex flex-col "
          >
            {rule?.type === 'weeklyreport'
              ? 'Weekly report '
              : 'Broadcast time '}
            schedule
          </Typography>
          <Typography variant="regular" className="text-black-50">
            (current timezone: {organization?.timezone})
          </Typography>
        </div>
        <div className="flex w-full gap-4">
          <Select
            placeholder="Select hour"
            disabled={!frequency.increment}
            label="Hour"
            options={disabledHours}
            className="block w-full"
            value={disabledHours.find(
              hour => Number(hour._id) === frequency?.time.hour,
            )}
            onChange={_v => onChange(index)('hour', _v?._id)}
          />
          <Select
            placeholder="Select minute"
            disabled={!frequency?.time.hour}
            label="Minute"
            options={disabledMinutes}
            className="block w-full"
            value={disabledMinutes.find(
              hour => Number(hour._id) === frequency?.time.minute,
            )}
            onChange={_v => onChange(index)('minute', _v?._id)}
          />
        </div>
      </div>
      <div className="flex justify-end mt-6">
        <Button status="secondary" size="small" onClick={onDelete}>
          Delete
        </Button>
      </div>
    </Card>
  )
}

export default FrequencyItem
