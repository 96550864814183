import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import {
  IAvailableJobCount,
  IRenderableUser,
  IResponse,
  ISuperhumanJob,
  ISuperhumanJobAppointment,
  ISuperhumanJobPayload,
  ISuperhumanState,
} from '@/types'
import { InventoryType } from '@/types/inventory'
import {
  approveJob,
  cancelJob,
  cancelJobAppointment,
  editJobAppointment,
  getInventoryAccess,
  getRequestJob,
  getSuperhumanJob,
  getSuperhumanJobsCount,
} from '@/api/services/superhuman'
import { renderUserFullName } from '@/utils'
import { RootState } from '@/store'

const initialState: ISuperhumanState = {
  loading: false,
  job: undefined,
  jobCount: 0,
  delayJobCount: 0,
  totalJobCount: 0,
  error: undefined,
  fetched: false,
  available: false,
  jobId: undefined,
  jobVendor: undefined,
  usersToTag: [],
  tagMessage: '',
  message: '',
  appointment: null,
}

export const fetchSuperhumanJobCount = createAsyncThunk<
  IAvailableJobCount,
  undefined,
  { rejectValue: { message: string } }
>('job-count/list', async (_, thunkApi) => {
  try {
    const res = await getSuperhumanJobsCount()
    return res.data
  } catch (error) {
    return thunkApi.rejectWithValue({
      message: 'Failed to fetch superhuman job count',
    })
  }
})

export const fetchSuperhumanJob = createAsyncThunk<
  ISuperhumanJob,
  { id: string },
  { rejectValue: { message: string } }
>('superhumanJob/get', async ({ id }, thunkApi) => {
  try {
    const res = await getSuperhumanJob({ id })
    return res.data
  } catch (error) {
    return thunkApi.rejectWithValue({
      message: 'Failed to request superhuman job',
    })
  }
})

export const requestJob = createAsyncThunk<
  { jobId: string },
  undefined,
  { rejectValue: { message: string } }
>('request/job', async (_, thunkApi) => {
  try {
    const res = await getRequestJob()
    return res.data
  } catch (error) {
    return thunkApi.rejectWithValue({
      message: 'Failed to request superhuman job',
    })
  }
})

export const cancelAppointmentRequest = createAsyncThunk<
  { jobId: string },
  { id: string; appointment: string },
  { rejectValue: { message: string } }
>('cancel/appointment', async (data, thunkApi) => {
  try {
    const res = await cancelJobAppointment(data)
    return res.data
  } catch (error) {
    return thunkApi.rejectWithValue({
      message: 'Failed to cancel appointment',
    })
  }
})

export const editAppointmentRequest = createAsyncThunk<
  { message: string; appointment: ISuperhumanJobAppointment },
  { id: string; appointment: string; startDate: Date },
  { rejectValue: { message: string } }
>('edit/appointment', async (data, thunkApi) => {
  try {
    const res = await editJobAppointment(data)
    return res.data
  } catch (error) {
    return thunkApi.rejectWithValue({
      message: 'Failed to edit appointment',
    })
  }
})

export const checkInventoryAccess = createAsyncThunk<
  IResponse<InventoryType | undefined>,
  { organization: string },
  { rejectValue: { message: string } }
>('access/job', async (data, thunkApi) => {
  try {
    const res = await getInventoryAccess(data)
    return res.data
  } catch (error) {
    return thunkApi.rejectWithValue({
      message: 'Failed to fetch organization access',
    })
  }
})
export const cancelJobRequest = createAsyncThunk<
  IResponse<{ message: string }>,
  { id: string },
  { rejectValue: { message: string } }
>('cancel/job', async (data, thunkApi) => {
  try {
    const res = await cancelJob(data)
    return res.data
  } catch (error) {
    return thunkApi.rejectWithValue({
      message: 'Failed to cancel job',
    })
  }
})
export const submitJobRequest = createAsyncThunk<
  IResponse<{ message: string }>,
  Partial<ISuperhumanJobPayload>,
  { rejectValue: { message: string } }
>('submit/job', async (data, thunkApi) => {
  try {
    const res = await approveJob(data)
    return res.data
  } catch (error) {
    return thunkApi.rejectWithValue({
      message: 'Failed to submit job',
    })
  }
})

const unusedFeedSlice = createSlice({
  name: 'superhuman',
  initialState,
  reducers: {
    onAvailabilityChange: (state, action) => {
      state.available = action.payload
    },
    untagUser: (state: ISuperhumanState, action) => {
      const { usersToTag } = state
      const id = action.payload
      state.usersToTag = usersToTag.filter(u => u !== id)
    },
    tagUser: (state: ISuperhumanState, action) => {
      const { usersToTag } = state
      const id = action.payload
      state.usersToTag = [...usersToTag, id]
    },
    addUserToList: (state: ISuperhumanState, action) => {
      const peopleToTag = state.job?.people_to_tag || []
      const id = action.payload
      if (state.job) {
        state.job.people_to_tag = [...new Set([...peopleToTag, id])]
      }
    },
    onTagMessageChange: (state: ISuperhumanState, action) => {
      state.tagMessage = action.payload
    },
    onMessageChange: (state: ISuperhumanState, action) => {
      state.message = action.payload
    },
    setAppointment: (state: ISuperhumanState, action) => {
      state.appointment = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(
      fetchSuperhumanJobCount.fulfilled,
      (state: ISuperhumanState, action) => {
        state.jobCount = action.payload.queueLength
        state.delayJobCount = action.payload.lateQueueLength
        state.totalJobCount = action.payload.totalLength
      },
    )
    builder.addCase(
      fetchSuperhumanJobCount.rejected,
      (state: ISuperhumanState, action) => {
        state.error = action.payload?.message
      },
    )
    builder.addCase(fetchSuperhumanJob.pending, (state: ISuperhumanState) => {
      state.loading = true
      state.appointment = null
      state.message = ''
      state.usersToTag = []
    })
    builder.addCase(
      fetchSuperhumanJob.fulfilled,
      (state: ISuperhumanState, action) => {
        state.job = action.payload
        state.usersToTag = action.payload.people_to_tag
        state.loading = false
        state.message = action.payload?.message || ''
        state.appointment = action.payload?.ai_recommended_appointment || null
      },
    )
    builder.addCase(
      fetchSuperhumanJob.rejected,
      (state: ISuperhumanState, action) => {
        state.error = action.payload?.message
        state.loading = false
      },
    )
    builder.addCase(requestJob.fulfilled, (state: ISuperhumanState, action) => {
      state.jobId = action.payload.jobId
    })
    builder.addCase(requestJob.rejected, (state: ISuperhumanState, action) => {
      state.error = action.payload?.message
    })
    builder.addCase(
      checkInventoryAccess.fulfilled,
      (state: ISuperhumanState, action) => {
        state.jobVendor = action.payload.data
      },
    )
    builder.addCase(
      checkInventoryAccess.rejected,
      (state: ISuperhumanState, action) => {
        state.error = action.error?.message
      },
    )
    builder.addCase(
      editAppointmentRequest.fulfilled,
      (state: ISuperhumanState, action) => {
        if (state.job) {
          state.job.appointments = state.job.appointments.map(appointment => {
            if (appointment._id === action.payload.appointment._id) {
              return action.payload.appointment
            }
            return appointment
          })
        }
      },
    )
    builder.addCase(
      editAppointmentRequest.rejected,
      (state: ISuperhumanState, action) => {
        state.error = action.payload?.message
      },
    )
    builder.addCase(cancelJobRequest.fulfilled, (state: ISuperhumanState) => {
      state.job = undefined
      state.jobId = undefined
    })
    builder.addCase(
      cancelJobRequest.rejected,
      (state: ISuperhumanState, action) => {
        state.error = action.error?.message
      },
    )
    builder.addCase(submitJobRequest.fulfilled, (state: ISuperhumanState) => {
      state.job = undefined
      state.jobId = undefined
    })
    builder.addCase(
      submitJobRequest.rejected,
      (state: ISuperhumanState, action) => {
        state.error = action.error?.message
      },
    )
  },
})

export const jobAvailableUsers = createSelector(
  (state: RootState) => state.superhuman,
  state => {
    return (
      state?.job?.availableUsers
        .map(user => {
          return {
            fullName: renderUserFullName(user.firstName, user.lastName),
            _id: user._id,
          }
        })
        .sort((a, b) => a.fullName.localeCompare(b.fullName)) || []
    )
  },
)

export const jobAssignedUsers = createSelector(
  (state: RootState) => state.superhuman,
  state => {
    return (
      state?.job?.assignedUsers
        .filter(
          user => !!state?.job?.availableUsers.find(u => u._id === user._id),
        )
        .map(user => {
          return {
            fullName: renderUserFullName(user.firstName, user.lastName),
            _id: user._id,
          }
        })
        .sort((a, b) => a.fullName.localeCompare(b.fullName)) || []
    )
  },
)

export const jobPeopleToTag = createSelector(
  (state: RootState) => state.superhuman,
  state => {
    return (
      state?.job?.people_to_tag
        .reduce<IRenderableUser[]>((acc, id) => {
          const user = state?.job?.availableUsers.find(u => u._id === id)
          if (user) {
            acc.push({
              fullName: renderUserFullName(user.firstName, user.lastName),
              _id: user._id,
            })
          }
          return acc
        }, [])
        .sort((a, b) => a.fullName.localeCompare(b.fullName)) || []
    )
  },
)

export const jobUsersToTag = createSelector(
  (state: RootState) => state.superhuman,
  state => {
    return (
      state.usersToTag
        .reduce<IRenderableUser[]>((acc, id) => {
          const user = state?.job?.availableUsers.find(u => u._id === id)
          if (user) {
            acc.push({
              fullName: renderUserFullName(user.firstName, user.lastName),
              _id: user._id,
            })
          }
          return acc
        }, [])
        .sort((a, b) => a.fullName.localeCompare(b.fullName)) || []
    )
  },
)

export const {
  onAvailabilityChange,
  tagUser,
  addUserToList,
  untagUser,
  onMessageChange,
  onTagMessageChange,
  setAppointment,
} = unusedFeedSlice.actions
export default unusedFeedSlice.reducer
