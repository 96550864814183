import React from 'react'
import moment from 'moment-timezone'
import { DATE_FORMAT } from '@/constants'
import { ColumnDef } from '@tanstack/react-table'
import { IAIBotCustomer } from '@/types'
import OrganizationLink from '@/sections/OrganizationLink'
import { Button } from '@/components'

export const aiBotTableColumns = (
  onStatusView: (data: object) => void,
): ColumnDef<IAIBotCustomer, any>[] => {
  return [
    {
      accessorKey: 'organization.name',
      header: () => <span>Organization</span>,
      cell: info => {
        const data = info.row.original.organization
        return <OrganizationLink id={data._id} name={data.name} maxChars={50} />
      },
    },
    {
      accessorKey: 'phoneNumber',
      header: () => <span>Phone Number</span>,
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'createdAt',
      header: () => <span>Date</span>,
      cell: info => <span>{moment(info.getValue()).format(DATE_FORMAT)}</span>,
    },
    {
      accessorKey: 'statusData',
      header: () => '',
      cell: ({ getValue }) => {
        const value = getValue()
        if (!value) return null
        if (Object.values(value).length === 0) return null
        return (
          <div className="w-full flex justify-end">
            <Button
              status="primary"
              size="small"
              onClick={() => onStatusView(value)}
            >
              Status Info
            </Button>
          </div>
        )
      },
    },
  ]
}
