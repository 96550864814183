import { TErrorCodes } from '@/types'

export const errorCodes: Record<TErrorCodes, string> = {
  '1001': '1001 - Invalid credentials',
  '1005': '1005 - RPA Driver error',
  '1010': '1010 - Unknown error',
  '1030': '1030 - Empty VinSolutions report',
  '2400': '2400 - Franchise filter options not available',
  '2425': '2425 - Franchise name does not match',
}

export const errorCodeColors: Record<TErrorCodes, string> = {
  '1001': '#3B48E6',
  '1005': '#E59700',
  '1010': '#D92D20',
  '1030': '#37BD65',
  '2400': '#7E22CE',
  '2425': '#FF6800',
}
